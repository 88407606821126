import React, { useEffect } from 'react';
import { Form, Input, Row, Col, Spin, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    createCustomerSiteRequest,
    getCustomerSiteRequest,
    updateCustomerSiteRequest,
} from '../../core/account/accountActions';

const UpdateSite = ({ id, onActionComplete }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { customerSite, isFetchingCustomerSite } = useSelector(state => state.account);

    const handleSubmit = () => {
        form.validateFields().then(values => {
            if (id) {
                dispatch(updateCustomerSiteRequest({ ...values, id }));
            } else {
                dispatch(createCustomerSiteRequest({ data: values, onActionComplete }));
            }
        });
    };

    useEffect(() => {
        if (id) {
            dispatch(getCustomerSiteRequest(id));
        }
    }, []);

    useEffect(() => {
        if (id && customerSite && !isFetchingCustomerSite) {
            form.setFieldsValue({
                name: customerSite?.ADR_NAME,
                addressLine1: customerSite?.ADR_LINE1,
                addressLine2: customerSite?.ADR_LINE2,
                town: customerSite?.ADR_TOWN,
                county: customerSite?.ADR_COUNTY,
                postcode: customerSite?.ADR_POSTCODE,
                phone: customerSite?.ADR_TELEPHONE,
                notes: customerSite?.ADR_DIRECTIONS,
            });
        }
    }, [customerSite]);

    return (
        <Row>
            <Col span={24}>
                {!isFetchingCustomerSite || !id ? (
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="vertical">
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Site Name/Reference" name="name" rules={[{ required: true }]}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Address Line 1" name="addressLine1" rules={[{ required: true }]}>
                                    <Input size="large" disabled={id} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Address Line 2" name="addressLine2">
                                    <Input size="large" disabled={id && customerSite?.ADR_LINE2} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Town/City" name="town" rules={[{ required: true }]}>
                                    <Input size="large" disabled={id} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="County" name="county">
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col span={11}>
                                <Form.Item label="Postcode" name="postcode" rules={[{ required: true, pattern: /^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]? ?[0-9][a-zA-Z]{2}$/, message: 'Please enter a valid UK postcode' }]}>
                                    <Input size="large" disabled={id} />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="Phone number" name="phone">
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Notes/Directions" name="notes">
                                    <Input.TextArea size="large" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Button htmlType="submit" type="primary" size="large">{id ? 'Update' : 'Create'}</Button>
                    </Form>
                ) : <Spin />}
            </Col>
        </Row>
    );
};

UpdateSite.propTypes = {
    id: PropTypes.any,
    onActionComplete: PropTypes.func.isRequired,
};

UpdateSite.defaultProps = {
    id: null,
};

export default UpdateSite;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    Form,
    DatePicker,
    Spin,
} from 'antd';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import './style.scss';
import { setOffhireReminderRequest } from '../../core/order/orderActions';
import { getCustomerIdFromCookie, getWordpressUserIdFromCookie } from '../../core/utils/api';

const OffHireReminder = ({ actionId, forceModalToShow, useWebId, useOrderId, modalOnHide }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [webId, setWebId] = useState(null);
    const [reminderDate, setReminderDate] = useState(null);
    // const [currentReminderDate, setCurrentReminderDate] = useState(moment().format('YYYY-MM-DD'));
    const { isFetching } = useSelector(state => state.order);
    const dispatch = useDispatch();
    // console.log('currentReminderDate', actionId, currentReminderDate);
    const showModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let dataOrderId = e.target.closest(actionId).getAttribute('data-order-id');
        let dataWebId = e.target.closest(actionId).getAttribute('data-web-id');
        // let value = e.target.closest(actionId).getAttribute('data-value');
        // fix for radio buttons
        if (dataOrderId === null) {
            dataOrderId = e.target.closest(actionId).querySelector('[data-order-id]').getAttribute('data-order-id');
            dataWebId = e.target.closest(actionId).querySelector('[data-web-id]').getAttribute('data-order-id');
            // value = e.target.closest(actionId).querySelector('[data-webId]').getAttribute('data-value');
        }
        setOrderId(dataOrderId);
        setWebId(dataWebId);
        // setCurrentReminderDate(value);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        const customerId = getCustomerIdFromCookie();
        const wpUserId = getWordpressUserIdFromCookie();
        dispatch(setOffhireReminderRequest({
            orderId,
            webId,
            type: 'hire',
            endDate: reminderDate,
            customerId,
            wpUserId,
        }));
        // setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = (values) => {
        console.log('Success:', values, orderId);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onChange = (date, dateString) => {
        setReminderDate(dateString);
    };

    // Add event listener to the button
    useEffect(() => {
        const elements = document.querySelector(actionId);
        if (elements) {
            elements.addEventListener('click', showModal, false);
        }
        return function cleanup() {
            if (elements) {
                elements.removeEventListener('click', showModal, false);
            }
        };
    }, []);

    useEffect(() => {
        if (!isFetching) {
            setIsModalOpen(false);
        }
    }, [isFetching]);

    useEffect(() => {
        if (forceModalToShow) {
            setIsModalOpen(true);
            setOrderId(useOrderId);
            setWebId(useWebId);
        }
    }, [forceModalToShow]);

    // eslint-disable-next-line arrow-body-style
    const disabledDate = (current) => {
        // Can not select days before today and today
        return (current && current < moment().endOf('day')) || current.day() === 0 || current.day() === 6;
    };

    return (
        <div className="off-hire-reminder-component">
            <Modal
                title="Set Off-Hire Reminder"
                afterClose={modalOnHide}
                width={400}
                okText="Proceed"
                cancelText="Cancel"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}>
                <Spin spinning={isFetching}>
                    <Form
                        name="hire-reminder-form"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 12 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off">
                        <Form.Item
                            label="Date"
                            name="reminder_date"
                            rules={[{ required: true, message: 'Select date' }]}>
                            <DatePicker
                                disabledDate={disabledDate}
                                onChange={onChange} />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );
};

OffHireReminder.propTypes = {
    actionId: PropTypes.string,
    forceModalToShow: PropTypes.bool,
    useWebId: PropTypes.string,
    useOrderId: PropTypes.string,
    modalOnHide: PropTypes.func,
};

OffHireReminder.defaultProps = {
    actionId: '.off-hire-reminder-action',
    forceModalToShow: false,
    useWebId: null,
    useOrderId: null,
    modalOnHide: () => {},
};

export default OffHireReminder;

import React from 'react';
import PropTypes from 'prop-types';
import Map from '../Map';

const {
    API_URL,
} = require('../../core/constants').default;

const TransportMap = ({ driverCoordinates, destinationCoordinates }) => {

    const markers = [
        {
            id: 1,
            position: { ...driverCoordinates },
            icon: {
                anchor: {
                    x: 16.75,
                    y: 40.09,
                },
                url: `${API_URL}/img/vehicle-marker.svg`,
            },
            locationName: 'Driver',
        },
    ];

    if (destinationCoordinates) {
        markers.push({
            id: 2,
            position: { ...destinationCoordinates },
            icon: {
                anchor: {
                    x: 16.75,
                    y: 40.09,
                },
                url: `${API_URL}/img/destination-marker.svg`,
            },
            locationName: 'Destination',
        });
    }

    return (
        <Map
            width="100%"
            zoom={8}
            markers={markers}
            center={{ ...driverCoordinates }} />
    );
};

TransportMap.propTypes = {
    driverCoordinates: PropTypes.object.isRequired,
    destinationCoordinates: PropTypes.array,
};

TransportMap.defaultProps = {
    destinationCoordinates: null,
};

export default TransportMap;

import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    DELETE_TOKEN_REQUEST: null,
    DELETE_TOKEN_SUCCESS: null,

    SAVE_TOKEN_REQUEST: null,
    SAVE_TOKEN_SUCCESS: null,

    LOGIN_REQUEST: null,
    LOGIN_SUCCESS: null,
    LOGIN_FAILURE: null,

    REGISTER_REQUEST: null,
    REGISTER_SUCCESS: null,
    REGISTER_FAILURE: null,

    LOGOUT_REQUEST: null,
    LOGOUT_SUCCESS: null,
    LOGOUT_FAILURE: null,

    SAVE_USER_DATA_REQUEST: null,

    FORGOT_PASSWORD_REQUEST: null,
    FORGOT_PASSWORD_SUCCESS: null,
    FORGOT_PASSWORD_FAILURE: null,

    RESET_PASSWORD_REQUEST: null,
    RESET_PASSWORD_SUCCESS: null,
    RESET_PASSWORD_FAILURE: null,

    GET_HIRE_DURATION_PREFERENCES_REQUEST: null,
    GET_HIRE_DURATION_PREFERENCES_SUCCESS: null,
    GET_HIRE_DURATION_PREFERENCES_FAILURE: null,

    UPDATE_HIRE_DURATION_PREFERENCES_REQUEST: null,
    UPDATE_HIRE_DURATION_PREFERENCES_SUCCESS: null,
    UPDATE_HIRE_DURATION_PREFERENCES_FAILURE: null,

    GET_PREFERENCES_NOTIFICATIONS_REQUEST: null,
    GET_PREFERENCES_NOTIFICATIONS_SUCCESS: null,
    GET_PREFERENCES_NOTIFICATIONS_FAILURE: null,

    UPDATE_PREFERENCES_NOTIFICATIONS_REQUEST: null,
    UPDATE_PREFERENCES_NOTIFICATIONS_SUCCESS: null,
    UPDATE_PREFERENCES_NOTIFICATIONS_FAILURE: null,

    GET_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST: null,
    GET_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS: null,
    GET_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE: null,

    UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST: null,
    UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS: null,
    UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE: null,
});

export const {
    deleteTokenRequest,
    deleteTokenSuccess,

    saveTokenRequest,
    saveTokenSuccess,

    loginRequest,
    loginSuccess,
    loginFailure,

    registerRequest,
    registerSuccess,
    registerFailure,

    logoutRequest,
    logoutSuccess,
    logoutFailure,

    saveUserDataRequest,

    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetPasswordRequest,
    resetPasswordSuccess,
    resetPasswordFailure,

} = createActions(
    constants.DELETE_TOKEN_REQUEST,
    constants.DELETE_TOKEN_SUCCESS,

    constants.SAVE_TOKEN_REQUEST,
    constants.SAVE_TOKEN_SUCCESS,

    constants.LOGIN_REQUEST,
    constants.LOGIN_SUCCESS,
    constants.LOGIN_FAILURE,

    constants.REGISTER_REQUEST,
    constants.REGISTER_SUCCESS,
    constants.REGISTER_FAILURE,

    constants.LOGOUT_REQUEST,
    constants.LOGOUT_SUCCESS,
    constants.LOGOUT_FAILURE,

    constants.SAVE_USER_DATA_REQUEST,

    constants.RESET_PASSWORD_REQUEST,
    constants.RESET_PASSWORD_SUCCESS,
    constants.RESET_PASSWORD_FAILURE,

    constants.FORGOT_PASSWORD_REQUEST,
    constants.FORGOT_PASSWORD_SUCCESS,
    constants.FORGOT_PASSWORD_FAILURE,
);

export const {
    getHireDurationPreferencesRequest,
    getHireDurationPreferencesSuccess,
    getHireDurationPreferencesFailure,
} = createActions(
    constants.GET_HIRE_DURATION_PREFERENCES_REQUEST,
    constants.GET_HIRE_DURATION_PREFERENCES_SUCCESS,
    constants.GET_HIRE_DURATION_PREFERENCES_FAILURE,
);

export const {
    updateHireDurationPreferencesRequest,
    updateHireDurationPreferencesSuccess,
    updateHireDurationPreferencesFailure,
} = createActions(
    constants.UPDATE_HIRE_DURATION_PREFERENCES_REQUEST,
    constants.UPDATE_HIRE_DURATION_PREFERENCES_SUCCESS,
    constants.UPDATE_HIRE_DURATION_PREFERENCES_FAILURE,
);

export const {
    getPreferencesNotificationsRequest,
    getPreferencesNotificationsSuccess,
    getPreferencesNotificationsFailure,
} = createActions(
    constants.GET_PREFERENCES_NOTIFICATIONS_REQUEST,
    constants.GET_PREFERENCES_NOTIFICATIONS_SUCCESS,
    constants.GET_PREFERENCES_NOTIFICATIONS_FAILURE,
);

export const {
    updatePreferencesNotificationsRequest,
    updatePreferencesNotificationsSuccess,
    updatePreferencesNotificationsFailure,
} = createActions(
    constants.UPDATE_PREFERENCES_NOTIFICATIONS_REQUEST,
    constants.UPDATE_PREFERENCES_NOTIFICATIONS_SUCCESS,
    constants.UPDATE_PREFERENCES_NOTIFICATIONS_FAILURE,
);

export const {
    getCompanyPreferencesNotificationsRequest,
    getCompanyPreferencesNotificationsSuccess,
    getCompanyPreferencesNotificationsFailure,
} = createActions(
    constants.GET_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST,
    constants.GET_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS,
    constants.GET_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE,
);

export const {
    updateCompanyPreferencesNotificationsRequest,
    updateCompanyPreferencesNotificationsSuccess,
    updateCompanyPreferencesNotificationsFailure,
} = createActions(
    constants.UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST,
    constants.UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS,
    constants.UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE,
);

import { Button, Form, Input, message, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScorchTable from 'scorch-react-table';
import { Link } from 'react-router-dom';
import { getInvoicePdfRequest, getInvoicesRequest } from '../../core/invoice/invoiceActions';
import { raiseInvoiceQueryRequest } from '../../core/invoiceQuery/invoiceQueryActions';
import Main from '../../components/layout/Main';

import './style.scss';

const Invoices = () => {
    const [showActionModal, setShowActionModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(undefined);

    const [statusFilter, setStatusFilter] = useState(null);
    const [searchFilter, setSearchFilter] = useState(null);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [sortByKey, setSortByKey] = useState(null);
    const [sortByDirection, setSortByDirection] = useState(null);

    const [actionForm] = Form.useForm();
    const dispatch = useDispatch();
    const { invoices, isFetchingInvoices } = useSelector(state => state.invoice);

    const selectAction = (action, record) => {
        switch (action) {
        case 'query-invoice':
            setShowActionModal(true);
            setSelectedInvoice(record);
            break;

        case 'download-pdf':
            dispatch(getInvoicePdfRequest(record.ivc_invoice_number));
            break;

        case 'pay-now':
            document.location = `/account/payments?invoice_id=${record.user_invoice_number}`;
            break;

        default: break;
        }
    };

    const getPaidStatus = (record) => {
        if (record.invoice_type === 'CREDIT') {
            return 'Credit';
        } if (record.payment_status !== 'Paid') {
            return (
                <div style={{ color: record.is_overdue ? '#da0812' : 'black' }}>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>{record.payment_status}</p>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                        {`(${record.due_date_distance} ${record.is_overdue ? 'days overdue' : 'days remaining'})`}
                    </p>
                </div>
            );
        }
        return (<p style={{ marginBottom: 0, marginTop: 0, color: 'green' }}>{record.payment_status}</p>);
    };

    const columns = [
        {
            title: 'INVOICE NUMBER',
            dataIndex: 'user_invoice_number',
            key: 'user_invoice_number',
            width: '70px',
            render: (val, record) => <button className="invoice-number" type="button" onClick={() => dispatch(getInvoicePdfRequest(record.ivc_invoice_number))}>{val}</button>,
        },
        {
            title: 'INVOICE TYPE',
            dataIndex: 'invoice_type',
            key: 'invoice_type',
            width: '70px',
        },
        {
            title: 'CONTRACT NO.',
            dataIndex: 'user_contract_number',
            key: 'user_contract_number',
            width: '80px',
            render: (val, record) => (<Link to={`/account/hire-contracts/${record.user_contract_number}`}>{val}</Link>),
        },
        {
            title: 'PO NO.',
            dataIndex: 'po_number',
            key: 'po_number',
            width: '100px',
            render: (val, record) => (<Link to={`/account/hire-contracts/${record.user_contract_number}`}>{val}</Link>),
        },
        {
            title: 'INVOICE TOTAL',
            dataIndex: 'invoice_amount',
            key: 'invoice_amount',
            width: '100px',
            sorter: true,
            render: val => `£${val}`,
        },
        {
            title: 'DATE RAISED',
            dataIndex: 'ivc_invoice_date',
            key: 'ivc_invoice_date',
            width: '100px',
            sorter: true,
        },
        {
            title: 'STATUS',
            dataIndex: 'payment_status',
            key: 'payment_status',
            width: '100px',
            align: 'center',
            render: (_, record) => getPaidStatus(record),
        },
        {
            title: 'ACTIONS',
            width: '100px',
            render: (_, record) => (
                <Select
                    size="small"
                    className="action-select"
                    dropdownMatchSelectWidth={false}
                    placement="bottomRight"
                    onChange={(value) => selectAction(value, record)}
                    value="Action"
                    defaultValue="Action">
                    <Select.Option value="download-pdf">Download</Select.Option>
                    {record.payment_status !== 'Paid' && record.invoice_type !== 'CREDIT' && (
                        <>
                            <Select.Option value="query-invoice">Query Invoice</Select.Option>
                            <Select.Option value="pay-now">Pay Now</Select.Option>
                        </>
                    )}
                </Select>
            ),
        },
    ];

    const filters = [
        {
            label: 'Search',
            name: 'search',
            placeholder: 'Search term',
            type: 'string',
        },
        {
            label: 'Status',
            name: 'status',
            type: 'select',
            defaultValue: 'Status',
            options: [
                {
                    label: 'All',
                    value: 'A',
                },
                {
                    label: 'Paid',
                    value: 'P',
                },
                {
                    label: 'Overdue',
                    value: 'O',
                },
            ],
        },
    ];

    const submitRaiseInvoiceQuery = () => {
        const { queryType, queryNotes } = actionForm.getFieldsValue();
        const data = { invoiceId: selectedInvoice.ivc_invoice_number, queryType, queryNotes };
        dispatch(raiseInvoiceQueryRequest(data));
    };

    const submitModal = () => {
        submitRaiseInvoiceQuery();
        setShowActionModal(false);
        actionForm.resetFields();
        setSelectedInvoice(undefined);
    };

    const closeModal = () => {
        setShowActionModal(false);
        actionForm.resetFields();
        setSelectedInvoice(undefined);
    };

    const handleSort = (pagination, e) => {
        dispatch(getInvoicesRequest({
            page: pagination.current,
            pageSize: pagination.pageSize,
            sortBy: e.columnKey || null,
            sortDirection: e.order || null,
            search: searchFilter,
            status: statusFilter,
        }));
        setResultsPerPage(pagination.pageSize || resultsPerPage);
        setSortByKey(e?.columnKey);
        setSortByDirection(e?.order);
    };

    const handleFilter = (items) => {
        dispatch(getInvoicesRequest({
            page: 1,
            pageSize: resultsPerPage,
            sortBy: sortByKey,
            sortDirection: sortByDirection,
            search: items.search,
            status: items.status,
        }));
        setStatusFilter(items.status);
        setSearchFilter(items.search);
    };

    const handleResetFilters = () => {
        dispatch(getInvoicesRequest({
            page: 1,
            pageSize: resultsPerPage,
            sortBy: sortByKey,
            sortDirection: sortByDirection,
        }));
        setSearchFilter(null);
        setStatusFilter(null);
    };

    useEffect(() => {
        dispatch(getInvoicesRequest({ page: null }));
    }, []);

    return (
        <Main title="Invoices">
            <div className="invoices-container">
                <Button href="/account/payments/?option=statement" style={{ marginBottom: '20px' }} type="primary">Pay Invoices Online</Button>
                <ScorchTable
                    columns={columns}
                    dataSource={invoices && invoices.data}
                    pagination={{
                        current: invoices?.current_page,
                        pageSize: resultsPerPage,
                        total: invoices?.total,
                        size: 'large',
                        position: ['bottomCenter'],
                    }}
                    onSortChange={(pagination, e) => handleSort(pagination, e)}
                    filters={filters}
                    filtersSize="small"
                    onFilter={(e) => handleFilter(e)}
                    onResetFilters={handleResetFilters}
                    loading={isFetchingInvoices} />
                <Modal
                    centered
                    title={`QUERY INVOICE ${selectedInvoice?.ivc_invoice_number}`}
                    visible={showActionModal}
                    okText="Proceed"
                    onOk={() => actionForm.validateFields().then(submitModal).catch(() => message.error('Please fill out the required fields'))}
                    onCancel={closeModal}>
                    <Form form={actionForm}>
                        <Form.Item labelCol={{ span: 6 }} labelAlign="right" label="Query Type" name="queryType" rules={[{ required: true }]}>
                            <Select>
                                <Select.Option value={10}>Hire Rate</Select.Option>
                                <Select.Option value={20}>On/Off Hire Date</Select.Option>
                                <Select.Option value={30}>Delivery/Collection Charge</Select.Option>
                                <Select.Option value={40}>Damage/Loss Charge</Select.Option>
                                <Select.Option value={50}>PO Number Query</Select.Option>
                                <Select.Option value={60}>Hire Items on Invoice</Select.Option>
                                <Select.Option value={70}>Other, specify below</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item labelCol={{ span: 6 }} labelAlign="right" label="Notes" name="queryNotes" rules={[{ required: true }]}>
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </Main>
    );
};

export default Invoices;

import React, { useEffect } from 'react';
import TinySlider from 'tiny-slider-react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { getHomepageWidgetRequest } from '../../core/kpi/kpiActions';
import Donut from '../../components/Widgets/Donut';
import DonutClock from '../../components/Widgets/DonutClock';
import 'tiny-slider/dist/tiny-slider.css';
import './homePageWidgetBlock.scss';

const settings = {
    controls: false,
    lazyload: true,
    nav: false,
    mouseDrag: true,
    loop: false,
    responsive: {
        640: {
            edgePadding: 20,
            gutter: 20,
            items: 2,
        },
        700: {
            gutter: 30,
            items: 4,
        },
        900: {
            items: 5,
        },
    },
};

const HomePageWidgetBlock = () => {
    const dispatch = useDispatch();
    const { homePageWidget, isFetching } = useSelector(state => state.kpi);
    useEffect(() => {
        dispatch(getHomepageWidgetRequest());
    }, []);
    let containerStyle = {};
    if (homePageWidget?.background_image?.url) {
        containerStyle = {
            backgroundImage: `url(${homePageWidget?.background_image?.url})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPositionX: '50%',
            backgroundPositionY: 'center',
            paddingTop: '5rem',
            paddingBottom: '2rem',
        };
    }

    const widgets = homePageWidget?.widget_block_widgets_active?.map((widget) => {
        const isClockWidget = widget.widgets_id === 1;
        if (isClockWidget) {
            return (
                <div className="widget-box">
                    <DonutClock
                        value={Math.trunc(widget?.widget_data_point?.cached_value)}
                        after={widget?.data_suffix}
                        label={widget?.title}
                        labelKey={widget?.position} />
                </div>
            );
        }
        return (
            <div className="widget-box">
                <Donut
                    value={widget?.widget_data_point?.cached_value}
                    after={widget?.data_suffix}
                    label={widget?.title}
                    labelKey={widget?.position} />
            </div>
        );
    });
    const infoLines = homePageWidget?.widget_block_widgets_active?.map((widget) => <li><sup>{widget.position}</sup>{widget.info_line}</li>);

    const description = homePageWidget?.description?.replace(/<(?!p\s*\/?)[^>]+>/g, '');

    return (
        <div className="home-page-widget-block ">
            <div className="ui icon message">
                <div className="content" style={{ width: '100%' }}>
                    <Spin spinning={isFetching}>
                        <div className="home-page-widget-block" style={containerStyle}>
                            <div className="inside-container">
                                <div className="header-text-block">
                                    <div className="header-title">{homePageWidget?.title}</div>
                                    {/* eslint-disable-next-line react/no-danger */}
                                    <div className="text" dangerouslySetInnerHTML={{ __html: description }} />
                                </div>
                                <TinySlider settings={settings}>
                                    {widgets ?? []}
                                </TinySlider>
                                <ul className="widget-info-line">
                                    {infoLines}
                                </ul>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default HomePageWidgetBlock;

import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth } from '../utils/api';

import {
    getSettingsSuccess,
    getSettingsFailure,
} from './settingActions';

const {
    GET_SETTINGS_REQUEST,
} = require('./settingActions').constants;

function* getSettings() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/setting',
        });

        yield put(getSettingsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getSettingsFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* settingSaga() {
    yield all([
        takeEvery(GET_SETTINGS_REQUEST, getSettings),
    ]);
}

import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    getOrderRequestsSuccess,
    getOrderRequestsFailure,

    getOrderRequestItemsSuccess,
    getOrderRequestItemsFailure,

    getOrderRequestDetailsSuccess,
    getOrderRequestDetailsFailure,

    changeOrderRequestStatusSuccess,
    changeOrderRequestStatusFailure,
    getOrderRequestsRequest,

    getOrderRequestCountSuccess,
    getOrderRequestCountFailure,
} from './orderRequestActions';

import { setShowOrderActionsModal, setOrderActionPending } from '../order/orderActions';

const {
    GET_ORDER_REQUESTS_REQUEST,
    GET_ORDER_REQUEST_ITEMS_REQUEST,
    GET_ORDER_REQUEST_DETAILS_REQUEST,
    CHANGE_ORDER_REQUEST_STATUS_REQUEST,
    GET_ORDER_REQUEST_COUNT_REQUEST,
} = require('./orderRequestActions').constants;

function* getOrderRequests() {
    const customerId = getCustomerIdFromCookie();
    const params = {};
    if (customerId !== '') { params.customerId = customerId; }

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/order-request/list',
            params,
        });

        yield put(getOrderRequestsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getOrderRequestsFailure(e.response ? e.response.data.message : e));
    }
}

function* getOrderRequestItems({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/order-request/${payload}/items`,
        });

        yield put(getOrderRequestItemsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getOrderRequestItemsFailure(e.response ? e.response.data.message : e));
    }
}

function* getOrderRequestDetails({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/order-request/${payload}/single`,
        });

        yield put(getOrderRequestDetailsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getOrderRequestDetailsFailure(e.response ? e.response.data.message : e));
    }
}

function* changeStatus({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `/order-request/${payload.orderId}/change-approval-status`,
            body: {
                approve: payload.approve,
                purchaseOrderMediaId: payload.purchaseOrderMediaId,
                declineReason: payload.declineReason,
                poNumber: payload.poNumber,
            },
        });

        yield put(changeOrderRequestStatusSuccess(response));
        yield put(getOrderRequestsRequest());
        yield put(setOrderActionPending(false));
        yield put(setShowOrderActionsModal(false));
        message.success('The order status has been changed successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(changeOrderRequestStatusFailure(e.response ? e.response.data.message : e));
        yield put(setShowOrderActionsModal(false));
        yield put(setOrderActionPending(false));
    }
}

function* getOrderRequestCount() {
    const customerId = getCustomerIdFromCookie();
    const params = {};
    if (customerId !== '') { params.customerId = customerId; }

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/order-request/count',
            params,
        });

        yield put(getOrderRequestCountSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getOrderRequestCountFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* orderRequestSaga() {
    yield all([
        takeEvery(GET_ORDER_REQUESTS_REQUEST, getOrderRequests),
        takeEvery(GET_ORDER_REQUEST_ITEMS_REQUEST, getOrderRequestItems),
        takeEvery(GET_ORDER_REQUEST_DETAILS_REQUEST, getOrderRequestDetails),
        takeEvery(CHANGE_ORDER_REQUEST_STATUS_REQUEST, changeStatus),
        takeEvery(GET_ORDER_REQUEST_COUNT_REQUEST, getOrderRequestCount),
    ]);
}

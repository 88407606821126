import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApi } from '../utils/api';

import {
    getTrackingDataSuccess,
    getTrackingDataFailure,
} from './transportActions';

const {
    GET_TRACKING_DATA_REQUEST,
} = require('./transportActions').constants;

function* getTrackingData({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'GET',
            url: `transport/${payload}/tracking`,
        });

        yield put(getTrackingDataSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getTrackingDataFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* transportSaga() {
    yield all([
        takeEvery(GET_TRACKING_DATA_REQUEST, getTrackingData),
    ]);
}

import React, { useState, useEffect } from 'react';
import {
    useStripe,
    useElements, CardElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { Button, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { sendPaymentEmailRequest } from '../../core/payments/paymentsActions';
import './style.scss';

const PaymentForm = ({ clientSecret, cleanPaymentSelections, paymentOption, selectedInvoices, specificAmount }) => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const { paymentRequestRunning } = useSelector(state => state.payments);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const card = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card,
        });

        if (error) {
            message.error('Payment failed, please try again');
            setIsLoading(false);
        } else {
            stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod.id,
            }).then((res) => {
                if (res.paymentIntent.status === 'succeeded') {
                    dispatch(sendPaymentEmailRequest({ paymentOption, selectedInvoices, specificAmount }));
                    // setTimeout(() => setDisplayPaymentPage(false), 500);
                } else {
                    message.error('Payment failed, please try again');
                }
            }).catch(() => {
                message.error('Payment failed, please try again');
            });
        }
    };

    useEffect(() => {
        if (isLoading === true && paymentRequestRunning === false) {
            message.success('Payment successful');
            setIsLoading(false);
            cleanPaymentSelections();
        }
    }, [paymentRequestRunning]);

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: '#303238',
                fontSize: '16px',
                backgroundColor: '#ffffff',
                fontFamily: 'sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#CFD7DF',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                    color: '#303238',
                },
            },
        },
        hidePostalCode: true,
    };

    return (
        <Spin spinning={paymentRequestRunning}>
            <form id="payment-form" onSubmit={handleSubmit}>
                <h2 className="card-heading">Card details</h2>
                <div className="card-element-container">
                    <CardElement style={CARD_ELEMENT_OPTIONS} />
                </div>
                <Button type="primary" htmlType="submit" disabled={isLoading || !stripe || !elements} id="submit">
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner" /> : 'Pay now'}
                    </span>
                </Button>
                <Button type="secondary" style={{ marginLeft: 10 }} onClick={() => cleanPaymentSelections()}>
                    Cancel
                </Button>
            </form>
        </Spin>
    );
};

PaymentForm.propTypes = {
    clientSecret: PropTypes.string.isRequired,
    paymentOption: PropTypes.string.isRequired,
    specificAmount: PropTypes.number.isRequired,
    selectedInvoices: PropTypes.array,
    cleanPaymentSelections: PropTypes.func.isRequired,
};

PaymentForm.defaultProps = {
    selectedInvoices: [],
};

export default PaymentForm;

import { Record } from 'immutable';

const {
    GET_HIRE_CONTRACTS_REQUEST,
    GET_HIRE_CONTRACTS_SUCCESS,
    GET_HIRE_CONTRACTS_FAILURE,

    GET_HIRE_ITEMS_REQUEST,
    GET_HIRE_ITEMS_SUCCESS,
    GET_HIRE_ITEMS_FAILURE,

    GET_HIRE_CONTRACT_REQUEST,
    GET_HIRE_CONTRACT_SUCCESS,
    GET_HIRE_CONTRACT_FAILURE,

    ORDER_AGAIN_REQUEST,
    ORDER_AGAIN_SUCCESS,
    ORDER_AGAIN_FAILURE,

    UPDATE_ORDER_REQUEST,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_FAILURE,

    SET_OFFHIRE_REMINDER_REQUEST,
    SET_OFFHIRE_REMINDER_SUCCESS,
    SET_OFFHIRE_REMINDER_FAILURE,

    SEND_EXCHANGE_REQUEST_REQUEST,
    SEND_EXCHANGE_REQUEST_SUCCESS,
    SEND_EXCHANGE_REQUEST_FAILURE,

    GET_ITEMS_FOR_OFFHIRE_REQUEST,
    GET_ITEMS_FOR_OFFHIRE_SUCCESS,
    GET_ITEMS_FOR_OFFHIRE_FAILURE,

    GET_EXCHANGABLE_ITEMS_REQUEST,
    GET_EXCHANGABLE_ITEMS_SUCCESS,
    GET_EXCHANGABLE_ITEMS_FAILURE,

    CREATE_OFFHIRE_REQUEST,
    CREATE_OFFHIRE_SUCCESS,
    CREATE_OFFHIRE_FAILURE,

    DOWNLOAD_ON_HIRE_REPORT_REQUEST,
    DOWNLOAD_ON_HIRE_REPORT_SUCCESS,
    DOWNLOAD_ON_HIRE_REPORT_FAILURE,

    SET_SHOW_ORDER_ACTIONS_MODAL,
    SET_ORDER_ACTION_PENDING,

} = require('./orderActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    hireContracts: {},
    isFetchingHireContracts: false,
    hireItems: [],
    isFetchingHireItems: false,
    itemsForOffhire: [],
    exchangeableItems: [],
    isFetchingItemsForOffhire: false,
    hireContract: {},
    isFetchingHireContract: false,
    initialisingDownload: false,
    orderActionPending: false,
    showOrderActionsModal: false,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function orderReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case DOWNLOAD_ON_HIRE_REPORT_REQUEST:
        return state.set('initialisingDownload', true)
            .set('error', null);

    case GET_HIRE_CONTRACTS_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingHireContracts', true)
            .set('hireContracts', {})
            .set('error', null);

    case GET_HIRE_ITEMS_REQUEST:
        return state.set('isFetching', true).set('isFetchingHireItems', true)
            .set('error', null);

    case GET_HIRE_CONTRACT_REQUEST:
        return state.set('isFetching', true).set('isFetchingHireContract', true)
            .set('error', null);

    case ORDER_AGAIN_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case UPDATE_ORDER_REQUEST:
        return state.set('isFetching', true)
            .set('orderActionPending', true)
            .set('error', null);

    case SET_OFFHIRE_REMINDER_REQUEST:
        return state.set('isFetching', true)
            .set('orderActionPending', true)
            .set('error', null);

    case SEND_EXCHANGE_REQUEST_REQUEST:
        return state.set('isFetching', true)
            .set('orderActionPending', true)
            .set('error', null);

    case GET_ITEMS_FOR_OFFHIRE_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingItemsForOffhire', true);

    case GET_EXCHANGABLE_ITEMS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case CREATE_OFFHIRE_REQUEST:
        return state.set('isFetching', true)
            .set('orderActionPending', true)
            .set('error', null);

    case DOWNLOAD_ON_HIRE_REPORT_SUCCESS:
        return state.set('initialisingDownload', false);

    case GET_HIRE_CONTRACTS_SUCCESS:
        return state.set('isFetching', false).set('isFetchingHireContracts', false)
            .set('hireContracts', payload);

    case GET_HIRE_ITEMS_SUCCESS:
        return state.set('isFetching', false).set('isFetchingHireItems', false)
            .set('hireItems', payload);

    case GET_HIRE_CONTRACT_SUCCESS:
        return state.set('isFetching', false).set('isFetchingHireContract', false)
            .set('hireContract', payload)
            .set('hireItems', payload.hireItems);

    case ORDER_AGAIN_SUCCESS:
        return state.set('isFetching', false);

    case UPDATE_ORDER_SUCCESS:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false);

    case SET_OFFHIRE_REMINDER_SUCCESS:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false);

    case SEND_EXCHANGE_REQUEST_SUCCESS:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false);

    case GET_ITEMS_FOR_OFFHIRE_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingItemsForOffhire', false)
            .set('itemsForOffhire', payload);

    case GET_EXCHANGABLE_ITEMS_SUCCESS:
        return state.set('isFetching', false)
            .set('exchangeableItems', payload);

    case CREATE_OFFHIRE_SUCCESS:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false);

    case DOWNLOAD_ON_HIRE_REPORT_FAILURE:
        return state.set('initialisingDownload', false)
            .set('error', payload);

    case GET_HIRE_CONTRACTS_FAILURE:
        return state.set('isFetching', false).set('isFetchingHireContracts', false)
            .set('error', payload);

    case GET_HIRE_ITEMS_FAILURE:
        return state.set('isFetching', false).set('isFetchingHireItems', false)
            .set('error', payload);

    case GET_HIRE_CONTRACT_FAILURE:
        return state.set('isFetching', false).set('isFetchingHireContract', false)
            .set('error', payload);

    case ORDER_AGAIN_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case UPDATE_ORDER_FAILURE:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false)
            .set('error', payload);

    case SET_OFFHIRE_REMINDER_FAILURE:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false)
            .set('error', payload);

    case SEND_EXCHANGE_REQUEST_FAILURE:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false)
            .set('error', payload);

    case GET_ITEMS_FOR_OFFHIRE_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingItemsForOffhire', false);

    case GET_EXCHANGABLE_ITEMS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case CREATE_OFFHIRE_FAILURE:
        return state.set('isFetching', false)
            .set('orderActionPending', false)
            .set('showOrderActionsModal', false)
            .set('error', payload);

    case SET_SHOW_ORDER_ACTIONS_MODAL:
        return state.set('showOrderActionsModal', payload);

    case SET_ORDER_ACTION_PENDING:
        return state.set('orderActionPending', payload);

    default:
        return state;

    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import OrderRequestItems from '../../../components/OrderRequestItems';

const ExpandedRow = ({ record, selectAction }) => (
    <>
        <OrderRequestItems />
        <div className="buttons-container">
            <div>
                <Link to={`/account/order-requests/${record.webId}/`}>
                    <Button className="view-more-button">View full details</Button>
                </Link>
            </div>
            <div>
                <button onClick={() => selectAction('approve-request', record)} className="action-button" type="button">APPROVE</button>
                <button onClick={() => selectAction('decline-request', record)} className="action-button" type="button">DECLINE</button>
                <button onClick={() => selectAction('edit-request', record)} className="action-button" type="button">EDIT</button>
            </div>
        </div>
    </>
);

ExpandedRow.propTypes = {
    record: PropTypes.object.isRequired,
    selectAction: PropTypes.func.isRequired,
};

export default ExpandedRow;

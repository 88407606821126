import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_TRACKING_DATA_REQUEST: null,
    GET_TRACKING_DATA_SUCCESS: null,
    GET_TRACKING_DATA_FAILURE: null,
});

export const {
    getTrackingDataRequest,
    getTrackingDataSuccess,
    getTrackingDataFailure,
} = createActions(
    constants.GET_TRACKING_DATA_REQUEST,
    constants.GET_TRACKING_DATA_SUCCESS,
    constants.GET_TRACKING_DATA_FAILURE,
);

import { Record } from 'immutable';

const {
    GET_HIRE_RATES_REQUEST,
    GET_HIRE_RATES_SUCCESS,
    GET_HIRE_RATES_FAILURE,
} = require('./productActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function productReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case GET_HIRE_RATES_REQUEST:
        return state.set('isFetching', true);

    case GET_HIRE_RATES_SUCCESS:
        return state.set('isFetching', false)
            .set('error', null);

    case GET_HIRE_RATES_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}

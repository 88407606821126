import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, InputNumber, Button, Spin, message } from 'antd';
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Main from '../../components/layout/Main';
import AccountBalanceBar from '../../components/AccountBalanceBar';
import UnpaidInvoicesTable from '../../components/UnpaidInvoicesTable';
import PaymentForm from '../../components/PaymentForm';

import { getAccountBalanceRequest } from '../../core/account/accountActions';
import { getUnpaidInvoicesRequest, getOverdueBalanceRequest } from '../../core/payments/paymentsActions';
import { fetchApiAuth, getCustomerIdFromCookie } from '../../core/utils/api';
import './style.scss';

const {
    STRIPE_PUBLIC_KEY,
} = require('../../core/constants').default;

const Payments = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();

    const { accountBalance } = useSelector(state => state.account);
    const { unpaidInvoices, overdueBalance } = useSelector(state => state.payments);

    const [paymentOption, setPaymentOption] = useState(null);
    const [specificAmount, setSpecificAmount] = useState(0);
    const [selectedInvoices, setSelectedInvoices] = useState([]);
    const [displayPaymentPage, setDisplayPaymentPage] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [amountStatus, setAmountStatus] = useState(null);

    const cleanPaymentSelections = () => {
        setSpecificAmount(0);
        setSelectedInvoices([]);
        setPaymentOption(null);
        setDisplayPaymentPage(false);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        const queryInvoiceId = queryParams.get('invoice_id');

        if (queryInvoiceId) {
            const defaultSelectedInvoice = unpaidInvoices.find((i) => i.user_invoice_number === +queryInvoiceId);
            if (defaultSelectedInvoice) {
                setSelectedInvoices([defaultSelectedInvoice]);
                setPaymentOption('specific-invoices');
            }
        }
    }, [search]);

    const numericAccountBalance = +(accountBalance.replace(/,/g, ''));
    const [numericOverdueBalance, setNumericOverdueBalance] = useState(0);

    const selectedInvoicesTotal = selectedInvoices.reduce((total, invoice) => total + parseFloat(invoice.invoice_amount), 0);
    const selectedInvoicesTotalString = selectedInvoicesTotal.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    useEffect(() => {
        dispatch(getAccountBalanceRequest());
        dispatch(getUnpaidInvoicesRequest());
        dispatch(getOverdueBalanceRequest());
    }, []);

    useEffect(() => {
        if (overdueBalance) {
            setNumericOverdueBalance(+(overdueBalance.replace(/,/g, '')));
        }
    }, [overdueBalance]);

    const handlePaymentOptionChange = (e) => {
        setPaymentOption(e.target.value);
    };

    const handleSpecificAmountChange = (value) => {
        const aBalance = parseFloat(accountBalance.replace(',', ''));
        console.log(':::::::::::', value, aBalance);
        if (value) {
            if (value > aBalance) {
                setAmountStatus('error');
            } else {
                setSpecificAmount(+(value.toFixed(2)));
                setAmountStatus(null);
            }
        } else {
            setSpecificAmount(0);
            setAmountStatus(null);
        }
    };

    const getTotalToPay = () => {
        switch (paymentOption) {
        case 'account':
            return numericAccountBalance;
        case 'overdue':
            return numericOverdueBalance;
        case 'specific-amount':
            return specificAmount;
        case 'specific-invoices':
            return selectedInvoicesTotal;
        default:
            return 0;
        }
    };

    const totalToPay = getTotalToPay() || 0;
    const totalToPayString = totalToPay.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const getInvoiceIdsForParams = (returnString = true) => {
        let invoiceIds = returnString ? '' : [];
        if (paymentOption === 'specific-invoices') {
            selectedInvoices.forEach((invoice) => {
                if (returnString) {
                    invoiceIds += `&invoiceIds[]=${invoice.ivc_invoice_number}`;
                } else {
                    invoiceIds.push(invoice.ivc_invoice_number);
                }
            });
        }
        return invoiceIds;
    };

    const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

    const [clientSecret, setClientSecret] = useState('');

    const options = {
        clientSecret,
    };

    const proceedToPayment = () => {
        if (amountStatus === 'error') {
            message.error('The specific amount must be less than or equal to the account balance.');
            return;
        }
        setIsPageLoading(true);
        fetchApiAuth({
            method: 'POST',
            // url: `/payments/payment-intent?companyId=${getCustomerIdFromCookie()}&paymentType=${paymentOption}&amount=${specificAmount}${getInvoiceIdsForParams()}`,
            url: '/payments/payment-intent',
            body: {
                companyId: getCustomerIdFromCookie(),
                paymentType: paymentOption,
                amount: specificAmount,
                invoiceIds: getInvoiceIdsForParams(false),
            },
        }).then((res) => {
            setClientSecret(res.data.clientSecret);
            setIsPageLoading(false);
            setDisplayPaymentPage(true);
        });
    };

    if (displayPaymentPage) {
        return (
            <Main title="Account Payments">
                <div>
                    <p className="payment-total">Payment total: {totalToPay > 0 && <span className="payment-amount">£{totalToPayString}</span>}</p>
                </div>
                {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                        <PaymentForm
                            cleanPaymentSelections={cleanPaymentSelections}
                            // setPaymentOption={setPaymentOption}
                            paymentOption={paymentOption}
                            specificAmount={specificAmount}
                            selectedInvoices={getInvoiceIdsForParams(false)}
                            // setDisplayPaymentPage={setDisplayPaymentPage}
                            clientSecret={clientSecret} />
                    </Elements>
                )}
            </Main>
        );
    }

    return (
        <Main title="Account Payments">
            <div className="account-balance-container">
                <h4 className="payments-subheading">YOUR ACCOUNT BALANCE</h4>
                <AccountBalanceBar
                    overdueBalanceString={overdueBalance}
                    accountBalanceString={accountBalance}
                    accountBalance={numericAccountBalance}
                    overdueBalance={numericOverdueBalance} />
            </div>
            <Spin spinning={isPageLoading}>
                <div className="payment-options-container">
                    <h4 className="payments-subheading">Payment Options</h4>
                    <div className="payment-options">
                        <Radio.Group
                            className="payment-options-radio-group"
                            name="payment-option"
                            value={paymentOption}
                            onChange={handlePaymentOptionChange}>
                            <Radio value="account">Account balance - <span className="payment-amount">£{accountBalance}</span></Radio>
                            <Radio value="overdue">Overdue balance - <span className="payment-amount">£{overdueBalance}</span></Radio>
                            <Radio value="specific-amount">
                                Specific amount {specificAmount > 0 && (<><span>- </span><span className="payment-amount">£{specificAmount}</span></>)}
                            </Radio>
                            {paymentOption === 'specific-amount' && (
                                <InputNumber
                                    status={amountStatus}
                                    style={{ width: '100%' }}
                                    min={0}
                                    defaultValue={specificAmount}
                                    onChange={handleSpecificAmountChange}
                                    step={1}
                                    addonBefore="£" />
                            )}
                            <Radio value="specific-invoices">
                                Specific invoices {selectedInvoicesTotal > 0 && (<><span>- </span><span className="payment-amount">£{selectedInvoicesTotalString}</span></>)}
                            </Radio>
                            {paymentOption === 'specific-invoices' && <UnpaidInvoicesTable unpaidInvoices={unpaidInvoices} selectedInvoices={selectedInvoices} onChange={setSelectedInvoices} />}
                        </Radio.Group>
                    </div>
                </div>
                <div className="payment-note">
                    <p>PLEASE NOTE - Payments will be verified and allocated to your account within 24 hours on working days. Please note that this process does not include weekends or bank holidays. Once the payment is verified, it will be reflected in your online account.</p>
                </div>
                <div className="payment-total-container">
                    <p className="payment-total">Total to pay: {totalToPay > 0 && <span className="payment-amount">£{totalToPayString}</span>}</p>
                </div>
                <div className="payment-button-container">
                    <Button type="primary" disabled={totalToPay === 0} onClick={() => proceedToPayment()}>ENTER CARD DETAILS</Button>
                </div>
            </Spin>
        </Main>
    );
};

export default Payments;

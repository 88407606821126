import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Button, Modal } from 'antd';

import ScorchTable from 'scorch-react-table';
import { useSelector, useDispatch } from 'react-redux';
import ContactForm from '../../components/ContactForm';
import { getCustomerContactsRequest, deleteCustomerContactRequest } from '../../core/account/accountActions';

import './style.scss';
import Main from '../../components/layout/Main';

const DeleteModal = ({ record, visible, onCancel, onOk }) => (
    <Modal
        centered
        title="DELETE CONTACT"
        open={visible}
        okText="Confirm"
        onOk={onOk}
        onCancel={onCancel}>
        <p>Are you sure you want to delete this contact?</p>
        <p>{`${record.firstName} ${record.lastName}`}</p>
    </Modal>
);

const Contacts = () => {
    const { customerContacts, isFetchingCustomerContacts } = useSelector(state => state.account);
    const [showContactForm, setShowContactForm] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(undefined);
    const dispatch = useDispatch();

    const initialSortData = {
        column: undefined,
        order: undefined,
        field: undefined,
        columnKey: undefined,
    };

    const initialPaginationData = {
        current: 1,
        pageSize: 20,
        total: customerContacts.length,
        position: ['bottomCenter'],
        size: 'large',
    };

    const [sortMetaData, setSortMetaData] = useState(initialSortData);
    const [paginationMetaData, setPaginationMetaData] = useState(initialPaginationData);

    const handleActionSelect = (value, record) => {
        switch (value) {
        case 'edit-contact':
            setSelectedRecord(record);
            setShowContactForm(true);
            break;
        case 'delete-contact':
            setSelectedRecord(record);
            setShowDeleteModal(true);
            break;
        default:
            break;
        }
    };

    const columns = [
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
            width: '70px',
            sorter: true,
            render: (_, record) => `${record.firstName} ${record.lastName ?? ''}`,
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: 'email',
            width: '70px',
        },
        {
            title: 'PHONE NUMBER',
            dataIndex: 'phoneNumber',
            key: 'phone_number',
            width: '70px',
        },
        {
            title: 'MOBILE NUMBER',
            dataIndex: 'mobileNumber',
            key: 'mobile_number',
            width: '70px',
        },
        {
            title: 'ROLE',
            dataIndex: 'jobTitle',
            key: 'job_title',
            width: '70px',
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            key: 'actions',
            width: '70px',
            render: (_, record) => (
                <Select
                    size="small"
                    className="action-select"
                    popupMatchSelectWidth={false}
                    placement="bottomRight"
                    onChange={(value) => handleActionSelect(value, record)}
                    value="Action"
                    defaultValue="Action">
                    <Select.Option value="edit-contact">Edit Contact</Select.Option>
                    <Select.Option value="delete-contact">Delete</Select.Option>
                </Select>
            ),

        },
    ];

    const sortContacts = (contacts) => {
        const sortedCustomerContacts = [...contacts].sort((a, b) => {
            let firstValue;
            let secondValue;
            if (!sortMetaData.columnKey || !sortMetaData.order) {
                firstValue = a.id;
                secondValue = b.id;
            } else if (sortMetaData.columnKey === 'name') {
                firstValue = `${a.firstName} ${a.lastName}`;
                secondValue = `${b.firstName} ${b.lastName}`;
            } else {
                firstValue = a[sortMetaData.columnKey];
                secondValue = b[sortMetaData.columnKey];
            }
            if (sortMetaData.order === 'ascend') {
                return firstValue > secondValue ? 1 : -1;
            }
            return firstValue < secondValue ? 1 : -1;
        });
        return sortedCustomerContacts;
    };

    const handleSort = (pagination, sort) => {
        setPaginationMetaData(pagination);
        setSortMetaData(sort);
    };

    useEffect(() => {
        dispatch(getCustomerContactsRequest());
    }, []);

    return (
        <Main title="Contacts">
            {(showContactForm)
                ? (
                    <ContactForm record={selectedRecord}
                        onSubmit={() => {
                            setSelectedRecord(undefined);
                            setShowContactForm(false);
                        }}
                        goBack={() => {
                            setShowContactForm(false);
                            setSelectedRecord(undefined);
                        }} />
                )
                : (
                    <div>
                        <Button onClick={() => setShowContactForm(true)} style={{ marginBottom: '20px' }} type="primary">
                            Add New Contact
                        </Button>
                        <ScorchTable
                            columns={columns}
                            dataSource={sortContacts(customerContacts)}
                            pagination={paginationMetaData}
                            onSortChange={(pagination, sort) => handleSort(pagination, sort)}
                            filters={[]}
                            filtersSize="small"
                            onFilter={() => {}}
                            onResetFilters={() => {}}
                            loading={isFetchingCustomerContacts} />
                        <DeleteModal
                            record={selectedRecord}
                            visible={showDeleteModal}
                            onCancel={() => {
                                setShowDeleteModal(false);
                                setSelectedRecord(undefined);
                            }}
                            onOk={() => {
                                setSelectedRecord(undefined);
                                setShowDeleteModal(false);
                                dispatch(deleteCustomerContactRequest({ id: selectedRecord.id }));
                            }} />
                    </div>
                )}
        </Main>
    );
};

DeleteModal.propTypes = {
    record: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
};

DeleteModal.defaultProps = {
    record: {},
    visible: false,
};

export default Contacts;

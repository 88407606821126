/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { once } from 'underscore';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';
import Map from '../Map';
import {
    getActiveLocationDetailsRequest,
    getActiveSiteLocationsRequest,
    getAccountBalanceRequest,
} from '../../core/account/accountActions';
import './style.scss';

const MapComponent = () => {
    const [itemsAwaitingDetails, setItemsAwaitingDetails] = useState({});
    const [openWindows, setOpenWindows] = useState([]);

    const dispatch = useDispatch();

    const {
        activeSiteLocations,
        isFetchingActiveSiteLocations,
        activeLocationDetailItems,
        isFetchingActiveLocationDetails,
        accountBalance,
    } = useSelector(state => state.account);

    useEffect(() => {
        dispatch(getActiveSiteLocationsRequest());
    }, []);

    const getInfoWindowContent = (location, locationDetails = false) => {

        let content = '<p>loading..</p>';

        if (locationDetails) {
            content = `
                <p>Live Contracts: <span class="value">${locationDetails.liveContracts}</span></p>
                <p>Current weekly open hire: <span class="value">£${locationDetails.weeklyOpenHire}</span></p>
                <p>Total invoiced hire: <span class="value">£${locationDetails.invoices}</span></p>
                <p>Pending off hires: <span class="value">${locationDetails.pendingOffHires}</span></p>
                <a href="/account/hire-contracts/?site=${location.id}" class="secondary-button">View Full Details</a>
            `;
        }

        const body = `
        <div class="marker-info-window" id="info-window-${location.id}">
            <h3>${location.name}</h3>
            <div class="iw-divider"></div>
            <div style="margin-bottom: 10px;">
                ${content}
            </div>
        </div > `;

        return body;
    };

    useEffect(() => {
        if (!isFetchingActiveLocationDetails) {
            if (activeLocationDetailItems && itemsAwaitingDetails.marker && itemsAwaitingDetails.marker.infoWindow) {
                itemsAwaitingDetails.marker.infoWindow.setContent(getInfoWindowContent(itemsAwaitingDetails.location, activeLocationDetailItems));
            }
        }

    }, [isFetchingActiveLocationDetails]);

    const onMarkerHover = (marker, location) => {
        openWindows.forEach(ow => ow.closeWindow());
        marker.infoWindow.setContent(getInfoWindowContent(location));
        setItemsAwaitingDetails({ marker, location });
        dispatch(getActiveLocationDetailsRequest(location.id));
        marker.infoWindow.openWindow();
        openWindows.push(marker.infoWindow);
        setOpenWindows(openWindows);
    };

    const markers = activeSiteLocations.map(location => ({
        id: location.id,
        position: { lat: location.lat, lng: location.lng },
        onClick: (marker) => onMarkerHover(marker, location),
        infoWindow: {
            onMouseOut: (infoWindow) => { console.log('here'); },
            content: `
                                <div class="marker-info-window" id="info-window-${location.id}">
                                    <h3>${location.name}</h3>
                                    <div class="iw-divider"></div>
                                    <div style="margin-bottom: 10px;">
                                        <p>Live Contracts: <span class="value">${location.liveContracts}</span></p>
                                        <p>Current weekly open hire: <span class="value">£${location.weeklyOpenHire}</span></p>
                                        <p>Total invoiced hire: <span class="value">£${null}</span></p>
                                        <p>Pending off hires: <span class="value">${location.pendingOffHires}</span></p>
                                        <a href="/account/hire-contracts/?site=${location.id}" class="secondary-button">View Full Details</a>
                                    </div>
                                </div > `,
        },
    }));

    const defaultSite = activeSiteLocations[0];

    return (
        <Spin spinning={isFetchingActiveSiteLocations}>
            <Map
                mapConfig={{
                    mapTypeId: 'roadmap',
                }}
                width="100%"
                zoom={6}
                center={defaultSite && { lat: defaultSite.lat, lng: defaultSite.lng }}
                markers={markers ?? []} />
        </Spin>
    );
};

export default MapComponent;

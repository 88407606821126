import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ScorchTable from 'scorch-react-table';
import { getInvoiceQueriesRequest } from '../../core/invoiceQuery/invoiceQueryActions';

import './style.css';
import Main from '../../components/layout/Main';

const InvoiceQueries = () => {
    const columns = [
        {
            title: 'REFERENCE',
            dataIndex: 'reference',
            key: 'reference',
            width: '100px',
        },
        {
            title: 'INVOICE',
            dataIndex: 'syrinx_invoice_id',
            key: 'syrinx_user_id',
            width: '100px',
        },
        {
            title: 'REASON',
            dataIndex: 'reason',
            key: 'reason',
            width: '100px',
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            width: '100px',
        },
        {
            title: 'LAST UPDATED',
            dataIndex: 'updated',
            key: 'updated',
            width: '100px',
        },
        {
            title: 'PLACED BY',
            dataIndex: 'created_by',
            key: 'created_by',
            width: '100px',
        },
        {
            title: 'Actions',
            width: '100px',
            render: (_, record) => (
                <Link to={`/account/invoice-queries/${record.id}`}>
                    <p style={{ marginBottom: 0, marginTop: 0, color: 'red' }}>View</p>
                </Link>
            ),
        },
    ];

    const dispatch = useDispatch();
    const { invoiceQueries, isFetchingInvoiceQueries } = useSelector(state => state.invoiceQuery);

    const handlePageChange = (page, pageSize) => {
        dispatch(getInvoiceQueriesRequest({ page, pageSize }));
    };

    useEffect(() => {
        dispatch(getInvoiceQueriesRequest());
    }, []);

    return (
        <Main title="Invoice Queries">
            <div className="invoice-queries-container">
                <ScorchTable
                    tableSize="sm"
                    columns={columns}
                    dataSource={invoiceQueries && invoiceQueries.data}
                    pagination={{
                        current: invoiceQueries.current_page,
                        pageSize: invoiceQueries.per_page,
                        defaultPageSize: 10,
                        total: invoiceQueries.total,
                        size: 'large',
                        position: ['bottomCenter'],
                        onChange: (pageNo, size) => handlePageChange(pageNo, size),
                    }}
                    loading={isFetchingInvoiceQueries} />
            </div>
        </Main>
    );
};

export default InvoiceQueries;

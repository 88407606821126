import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, message, Modal, Radio, Select, Space, Spin, TimePicker, Upload } from 'antd';
import locale from 'antd/lib/date-picker/locale/en_GB';

import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import ScorchTable from 'scorch-react-table';
import ContactFormModal from '../ContactFormModal';
import './style.css';

import {
    getHireItemsRequest,
    createOffhireRequest,
    getItemsForOffhireRequest,
    orderAgainRequest,
    sendExchangeRequestRequest,
    setOffhireReminderRequest,
    updateOrderRequest,
    setShowOrderActionsModal,
    getExchangableItemsRequest,
} from '../../core/order/orderActions';

import {
    getCustomerContactsRequest,
    resetNewlyCreatedContact,
} from '../../core/account/accountActions';
import { changeOrderRequestStatusRequest } from '../../core/orderRequest/orderRequestActions';

import {
    jQueryEditOrderModalTrigger,
} from '../../core/utils/jQuery';
import { getApiUrl } from '../../core/utils/api';

// set moment locale to en-gb and set first day of week to monday
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

const OrderActions = ({
    selectedAction,
    setSelectedAction,
    selectedRecord,
    setSelectedRecord,
    pageData,
}) => {

    // off hire form states
    const [showPartialOffHireFields, setShowPartialOffHireFields] = useState(false);
    const [showOffHireTimeInput, setShowOffHireTimeInput] = useState(false);
    const [showCustomerContactSelect, setShowCustomerContactSelect] = useState(true);
    const [showCustomerContactForm, setShowCustomerContactForm] = useState(false);

    const { customerContacts, newlyCreatedContact, isFetchingCustomerContacts } = useSelector(state => state.account);
    const {
        hireItems,
        exchangeableItems,
        itemsForOffhire,
        isFetchingItemsForOffhire,
        orderActionPending,
        showOrderActionsModal,
    } = useSelector(state => state.order);

    const [actionForm] = Form.useForm();
    const dispatch = useDispatch();

    const setStartingOffHireItemQuantities = () => {
        const startingOffHireItems = itemsForOffhire.map((item) => (
            { sequence: item.sequence, quantity: 0, isMaximum: false }
        ));
        actionForm.setFieldsValue({ offHireItems: [...startingOffHireItems] });
    };

    const updateOffHireItemQuantity = (row, val, isMaximum) => {
        const existingHireItems = [...actionForm.getFieldValue('offHireItems')];

        // find the index of the item
        const itemIndex = existingHireItems.findIndex(hi => hi.sequence === row.sequence);
        if (itemIndex !== -1) {
            existingHireItems.splice(itemIndex, 1);
        }

        actionForm.setFieldsValue({ offHireItems: [...existingHireItems, {
            sequence: row.sequence,
            quantity: val,
            isMaximum,
        }] });
    };

    const getOffHireItemQuantity = (row) => {
        const offHireItems = actionForm.getFieldValue('offHireItems');
        return offHireItems.find(ohi => ohi.sequence === row.sequence)?.quantity;
    };

    const orderAgainAction = (record) => {
        dispatch(orderAgainRequest(record.id));
    };

    const submitChangePoNumber = () => {
        const { newPoNumber } = actionForm.getFieldValue();
        let data = {
            orderId: selectedRecord.id,
            type: 'hire',
            poNumber: newPoNumber,
        };
        if (pageData) {
            data = { ...data, ...pageData, returnRecords: true };
        }

        dispatch(updateOrderRequest(data));
    };

    const submitSetOffHireReminder = () => {
        const { offHireReminderDate } = actionForm.getFieldValue();
        dispatch(setOffhireReminderRequest({
            orderId: selectedRecord.id,
            type: 'hire',
            endDate: offHireReminderDate.format('Y-MM-DD'),
        }));
    };

    const submitOffHire = () => {
        const {
            offHireItems,
            offHireContact,
            useExistingSiteContact,
            offHireNote,
            offHireDate,
            offHireTime,
            partialOffHire,
        } = actionForm.getFieldValue();

        // if all offhire items are selected at max quantity => full offhire
        const isPartial = Boolean(partialOffHire && offHireItems?.some(ohi => !ohi.isMaximum));

        // remove any zero or null values before submitting
        const filteredOffHireItems = (offHireItems)
            ? offHireItems.filter((item) => item.quantity)
            : [];

        const data = {
            offHireData: {
                orderId: selectedRecord.id,
                items: filteredOffHireItems,
                contact: customerContacts.find(cc => cc.id === offHireContact),
                useExistingSiteContact,
                note: offHireNote || null,
                date: offHireDate.format('YYYY-MM-DD'),
                time: offHireTime ? offHireTime.format('HH:mm') : null,
                isPartial,
            },
            pageData,
        };
        dispatch(createOffhireRequest(data));
    };

    const submitExchangeRequest = () => {
        const { exchangeItem, exchangeContact, exchangeNote } = actionForm.getFieldsValue();
        dispatch(sendExchangeRequestRequest({
            contractNumber: selectedRecord.contractNo,
            exchangeItem: hireItems.find(hi => hi.fleetNo === exchangeItem),
            exchangeContact: customerContacts.find(cc => cc.id === exchangeContact),
            exchangeNote,
        }));
    };

    const submitApprovalRequest = () => {
        const { purchaseOrder, poNumber } = actionForm.getFieldValue();
        const data = {
            orderId: selectedRecord.webId,
            approve: true,
            purchaseOrderMediaId: purchaseOrder?.file?.response?.id,
            poNumber,
        };
        dispatch(changeOrderRequestStatusRequest(data));
    };

    const submitDeclineRequest = () => {
        const { declineReason } = actionForm.getFieldValue();
        const data = {
            approve: false,
            declineReason,
            orderId: selectedRecord.webId,
        };
        dispatch(changeOrderRequestStatusRequest(data));
    };

    const selectAction = (record, action) => {
        switch (action) {
        case 'order-again':
            orderAgainAction(record);
            break;

        case 'change-po-number':
            setSelectedAction(action);
            setSelectedRecord(record);
            dispatch(setShowOrderActionsModal(true));
            break;

        case 'off-hire-reminder':
            setSelectedAction(action);
            setSelectedRecord(record);
            break;
        case 'request-exchange':
            dispatch(getExchangableItemsRequest(record.id));
            dispatch(getHireItemsRequest(record.id));
            dispatch(getCustomerContactsRequest());
            setSelectedAction(action);
            setSelectedRecord(record);
            dispatch(setShowOrderActionsModal(true));
            break;
        case 'off-hire':
            setShowPartialOffHireFields(false);
            setShowOffHireTimeInput(false);
            setShowCustomerContactSelect(true);
            dispatch(getItemsForOffhireRequest(record.id));
            dispatch(getCustomerContactsRequest());
            setSelectedAction(action);
            setSelectedRecord(record);
            dispatch(setShowOrderActionsModal(true));
            break;

        case 'approve-request':
            setSelectedAction(action);
            dispatch(setShowOrderActionsModal(true));
            break;
        case 'decline-request':
            setSelectedAction(action);
            dispatch(setShowOrderActionsModal(true));
            break;
        case 'edit-request':
            // To be eventually removed
            // Jquery function to trigger existing modal for editing order
            // as not currently possible to edit order in this way via react or laravel
            jQueryEditOrderModalTrigger(record.webId);
            setSelectedAction('none');
            setSelectedRecord({});
            break;
        default: break;
        }
    };

    useEffect(() => {
        if (selectedAction !== 'none') {
            selectAction(selectedRecord, selectedAction);
        }
    }, [selectedAction]);

    useEffect(() => {
        if (itemsForOffhire.length > 0) {
            setStartingOffHireItemQuantities();
        }
    }, [itemsForOffhire]);

    useEffect(() => {
        if (newlyCreatedContact?.id) {
            dispatch(resetNewlyCreatedContact());
        }
    }, [showOrderActionsModal]);

    const submitModal = () => {
        switch (selectedAction) {
        case 'change-po-number':
            submitChangePoNumber();
            break;

        case 'off-hire-reminder':
            submitSetOffHireReminder();
            break;

        case 'off-hire':
            submitOffHire();
            break;
        case 'request-exchange':
            submitExchangeRequest();
            break;

        case 'approve-request':
            submitApprovalRequest();
            break;
        case 'decline-request':
            submitDeclineRequest();
            break;
        default: break;
        }
        setSelectedRecord({});
        setSelectedAction('none');
        actionForm.resetFields();
    };

    const closeModal = () => {
        setSelectedAction('none');
        setSelectedRecord({});
        dispatch(setShowOrderActionsModal(false));
        actionForm.resetFields();
        if (showPartialOffHireFields) {
            setShowPartialOffHireFields(false);
        }
    };

    const ActionForm = ({ children, initialValues }) => (
        <Form form={actionForm} initialValues={initialValues}>
            {children}
        </Form>
    );

    ActionForm.propTypes = { children: PropTypes.node.isRequired, initialValues: PropTypes.object };
    ActionForm.defaultProps = { initialValues: undefined };

    // disable dates on off hire date input for the following conditions:
    const disabledDate = (current) => {
        // date is today and wants selected time
        if ((showOffHireTimeInput && current.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))) {
            return true;
        }
        // date is before today
        if (current.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            return true;
        }
        // date is weekend - weekends ([6, 0] = [Sat, Sun])
        if ([6, 0].includes(current.day())) {
            return true;
        }

        return false;
    };

    const getActionModal = () => {
        let modalContent = '';
        let modalTitle = '';

        switch (selectedAction) {
        case 'change-po-number':
            modalTitle = 'Change PO Number';
            modalContent = (
                <ActionForm>
                    <Form.Item label="New PO" name="newPoNumber">
                        <Input />
                    </Form.Item>
                </ActionForm>
            );
            break;

        case 'off-hire-reminder':
            modalTitle = 'Set Off-Hire Reminder';
            modalContent = (
                <ActionForm>
                    <Form.Item label="Date" name="offHireReminderDate">
                        <DatePicker format="DD-MM-Y" />
                    </Form.Item>
                </ActionForm>
            );
            break;

        case 'request-exchange':
            modalTitle = 'Request an Exchange';
            modalContent = (
                <ActionForm>
                    <Form.Item labelCol={{ span: 6 }} labelAlign="left" label="Order Item" name="exchangeItem" rules={[{ required: true }]}>
                        <Radio.Group>
                            <Space direction="vertical">
                                {exchangeableItems.map(item => (
                                    <Radio value={item.fleetNo}>{item.title}</Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item labelCol={{ span: 6 }} labelAlign="left" label="Contact" name="exchangeContact" rules={[{ required: true }]}>
                        <Select defaultValue="Select a contact">
                            {customerContacts.map(contact => (
                                <Select.Option value={contact.id}>{`${contact.firstName} ${contact.lastName}`}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item labelCol={{ span: 6 }} labelAlign="left" label="Notes" name="exchangeNote" rules={[{ required: true }]}>
                        <Input.TextArea />
                    </Form.Item>
                </ActionForm>
            );
            break;

        case 'off-hire':
            modalTitle = 'Off-Hire';
            modalContent = (
                isFetchingCustomerContacts && isFetchingItemsForOffhire ? <Spin /> : (
                    <ActionForm>
                        <Form.Item labelCol={{ span: 6 }} labelAlign="left" label="Off-Hire Date" name="offHireDate" rules={[{ required: true }]}>
                            <DatePicker disabledDate={disabledDate} locale={locale} />
                        </Form.Item>
                        <Form.Item
                            labelCol={{ span: 15 }}
                            labelAlign="left"
                            label="Specified Time Slot for collection (+£35.00)"
                            name="showOffHireTimeInput"
                            valuePropName="checked"
                            rules={[{ required: false }]}
                            onChange={() => {
                                const val = actionForm.getFieldValue('showOffHireTimeInput');
                                const offHireDate = actionForm.getFieldValue('offHireDate');
                                setShowOffHireTimeInput(val);
                                if (val && offHireDate && offHireDate < moment().add(1, 'day')) {
                                    actionForm.setFieldsValue({ offHireDate: null });
                                }
                            }}>
                            <Checkbox />
                        </Form.Item>
                        {showOffHireTimeInput && (
                            <Form.Item
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                                label="Off-Hire Time"
                                name="offHireTime"
                                help="A £35 additional charge will be added for this service."
                                rules={[{ required: showOffHireTimeInput }]}>
                                <TimePicker
                                    disabledTime={() => ({
                                        disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                                        disabledMinutes: (hour) => ((hour === 14) ? [15, 30, 45] : []),
                                    })}
                                    inputReadOnly
                                    showNow={false}
                                    hideDisabledOptions
                                    popupClassName="off-hire-time-picker"
                                    format="HH:mm"
                                    minuteStep={15}
                                    changeOnBlur />
                            </Form.Item>
                        )}
                        <Form.Item
                            labelCol={{ span: 9 }}
                            labelAlign="left"
                            label="Use existing site contact"
                            name="useExistingSiteContact"
                            valuePropName="checked"
                            rules={[{ required: false }]}>
                            <Checkbox onChange={(e) => setShowCustomerContactSelect(!e.target.checked)} />
                        </Form.Item>
                        {showCustomerContactSelect && (
                            <Form.Item
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                                label="Contact"
                                name="offHireContact"
                                rules={[{ required: showCustomerContactSelect }]}
                                extra={
                                    <button type="button" style={{ backgroundColor: 'transparent' }} onClick={() => setShowCustomerContactForm(true)}>+ Add New Contact</button>
                                }>
                                <Select defaultValue={newlyCreatedContact.id ?? 'Select a contact'}>
                                    {customerContacts.map(contact => (
                                        <Select.Option value={contact.id}>{`${contact.firstName} ${contact.lastName ?? ''}`}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        <Form.Item labelCol={{ span: 6 }} labelAlign="left" label="Notes" name="offHireNote" rules={[{ required: false }]}>
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            rules={[{
                                required: false,
                                validator: (_, value) => (!value || actionForm.getFieldValue('offHireItems').some((ohi => ohi.quantity > 0))
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Please enter a quantity for at least one item'))),
                            },
                            ]}
                            onChange={() => setShowPartialOffHireFields(actionForm.getFieldValue('partialOffHire'))}
                            labelCol={{ span: 6 }}
                            labelAlign="left"
                            label="Partial Off-Hire"
                            name="partialOffHire"
                            valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                        {showPartialOffHireFields && !isFetchingItemsForOffhire && itemsForOffhire.length && (
                            <div className="off-hire-items-table-container">
                                <ScorchTable
                                    styleObj={{ tableContainer: { maxHeight: '25vh' } }}
                                    columns={[
                                        {
                                            title: 'Item',
                                            dataIndex: 'title',
                                            key: 'title',
                                        },
                                        {
                                            title: 'Fleet No',
                                            dataIndex: 'fleetNo',
                                            key: 'fleetNo',
                                        },
                                        {
                                            title: 'Quantity',
                                            render: (_, row) => (
                                                <InputNumber
                                                    defaultValue={getOffHireItemQuantity(row)}
                                                    min={0}
                                                    max={row.quantity}
                                                    onChange={(val) => {
                                                        updateOffHireItemQuantity(row, val, +val === +row.quantity);
                                                    }} />
                                            ),
                                        },
                                        {
                                            title: 'Week Rate',
                                            dataIndex: 'weeklyRate',
                                            key: 'weeklyRate',
                                            render: (val) => val && `£${val}`,
                                        },
                                    ]}
                                    dataSource={itemsForOffhire} />
                            </div>
                        )}
                    </ActionForm>
                )
            );
            break;

        // Order request actions
        case 'approve-request':
            modalTitle = 'Approve Order Request';
            modalContent = (
                <ActionForm>
                    <p>You are about to approve this hire contract. You can attach a purchase order document below or modify your reference if needed.</p>
                    <Form.Item name="purchaseOrder" label="Purchase order" labelCol={{ span: 6 }} labelAlign="left">
                        <Upload {...{
                            name: 'files[]',
                            maxCount: 1,
                            action: `${getApiUrl()}/dropzone/update`,
                            // headers: {
                            //     authorization: 'authorization-text',
                            // },
                        }}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item name="poNumber" label="Your reference" labelCol={{ span: 6 }} labelAlign="left">
                        <Input />
                    </Form.Item>
                </ActionForm>
            );
            break;

        case 'decline-request':
            modalTitle = 'Decline Order Request';
            modalContent = (
                <ActionForm>
                    <p>You are about to decline this hire contract. Please give a reason to send back to the person who placed this request.</p>
                    <Form.Item name="declineReason">
                        <Input placeholder="Decline Reason" />
                    </Form.Item>
                </ActionForm>
            );
            break;

        default:
            modalTitle = 'Processing...';
            modalContent = <Spin />;
        }

        return (
            <>
                <Modal
                    bodyStyle={{ maxHeight: '75vh', overflow: 'scroll' }}
                    centered
                    title={modalTitle}
                    open={showOrderActionsModal}
                    okText="Proceed"
                    onOk={() => actionForm.validateFields().then(submitModal).catch(() => { message.error('Please fill out the required fields'); })}
                    onCancel={closeModal}
                    confirmLoading={orderActionPending}
                    maskClosable={!orderActionPending}
                    cancelButtonProps={{ style: orderActionPending ? { display: 'none' } : {} }}
                    closable={!orderActionPending}>
                    {modalContent}
                </Modal>
                <ContactFormModal isVisible={showCustomerContactForm} setIsVisible={setShowCustomerContactForm} />
            </>
        );

    };

    useEffect(() => {
        dispatch(setShowOrderActionsModal(false));
    }, []);

    return getActionModal();
};

OrderActions.propTypes = {
    pageData: PropTypes.any,
    selectedAction: PropTypes.string,
    selectedRecord: PropTypes.object,
    setSelectedAction: PropTypes.func,
    setSelectedRecord: PropTypes.func,
    showActionModal: PropTypes.any,
};

export default OrderActions;

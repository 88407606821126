import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import ScorchTable from 'scorch-react-table';

import './style.scss';

const UnpaidInvoicesTable = ({ unpaidInvoices, onChange, selectedInvoices }) => {
    const handleSelectInvoice = (invoice, isSelected) => {
        const newSelectedInvoices = isSelected ? [...selectedInvoices, invoice] : selectedInvoices.filter((i) => i.id !== invoice.id);
        onChange(newSelectedInvoices);
    };

    const determineDueText = (invoice) => {
        if (invoice.is_overdue) {
            return (
                <>
                    <span style={{ color: 'red' }}>{invoice.payment_status}</span><br />
                    <span style={{ color: 'red' }}>({invoice.due_date_distance} days overdue)</span>
                </>
            );
        }
        return (
            <p>{invoice.payment_status}</p>
        );
    };

    const columns = [
        {
            title: 'PAY?',
            dataIndex: 'pay',
            key: 'pay',
            width: '70px',
            render: (_, record) => <Checkbox checked={selectedInvoices.find((i) => i.id === record.id)} className="unpaid-invoices-checkbox" onChange={(e) => handleSelectInvoice(record, e.target.checked)} />,
        },
        {
            title: 'NUMBER',
            dataIndex: 'user_invoice_number',
            key: 'number',
            width: '70px',
        },
        {
            title: 'AMOUNT',
            dataIndex: 'invoice_amount',
            key: 'amount',
            width: '70px',
            render: (amount) => <p className="amount">{`£${amount}`}</p>,
        },
        {
            title: 'DUE',
            dataIndex: 'payment_status',
            key: 'due',
            width: '70px',
            render: (_, record) => determineDueText(record),
        },
    ];

    if (unpaidInvoices.length === 0) {
        return (
            <div className="no-invoices-container">
                <p className="no-invoices-text">There are no invoices outstanding</p>
            </div>
        );
    }

    return (
        <ScorchTable
            columns={columns}
            dataSource={unpaidInvoices} />
    );
};

UnpaidInvoicesTable.propTypes = {
    unpaidInvoices: PropTypes.array,
    onChange: PropTypes.func,
    selectedInvoices: PropTypes.array,
};

UnpaidInvoicesTable.defaultProps = {
    unpaidInvoices: [],
    onChange: () => {},
    selectedInvoices: [],
};

export default UnpaidInvoicesTable;

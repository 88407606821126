import { all } from 'redux-saga/effects';
import orderRequestSaga from './orderRequest/orderRequestSaga';
import userSaga from './user/userSaga';
import accountSaga from './account/accountSaga';
import orderSaga from './order/orderSaga';
import settingSaga from './setting/settingSaga';
import invoiceSaga from './invoice/invoiceSaga';
import invoiceQuerySaga from './invoiceQuery/invoiceQuerySaga';
import kpiSaga from './kpi/kpiSaga';
import productSaga from './product/productSaga';
import paymentsSaga from './payments/paymentsSaga';
import transportSaga from './transport/transportSaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        settingSaga(),
        userSaga(),
        accountSaga(),
        orderRequestSaga(),
        orderSaga(),
        invoiceSaga(),
        invoiceQuerySaga(),
        kpiSaga(),
        productSaga(),
        paymentsSaga(),
        transportSaga(),
    ]);
}

/* eslint-disable no-undef */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    getInvoicesSuccess,
    getInvoicesFailure,

    getInvoicePdfSuccess,
    getInvoicePdfFailure,
} from './invoiceActions';

const {
    GET_INVOICES_REQUEST,
    GET_INVOICE_PDF_REQUEST,
} = require('./invoiceActions').constants;

function* getInvoices({ payload }) {
    const customerId = getCustomerIdFromCookie();
    const params = {};
    if (payload.page) { params.page = payload.page; }
    if (payload.pageSize) { params.pageSize = payload.pageSize; }
    if (payload.sortBy) { params.sortBy = payload.sortBy; }
    if (payload.sortDirection) { params.sortDirection = payload.sortDirection; }
    if (payload.search) { params.search = payload.search; }
    if (payload.status) { params.status = payload.status; }
    if (customerId !== '') { params.customerId = customerId; }
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: '/invoice/list',
            params,
        });

        yield put(getInvoicesSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getInvoicesFailure(e.response ? e.response.data.message : e));
    }
}

function* getInvoicePdf({ payload }, download = true) {
    const customerId = getCustomerIdFromCookie();

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'invoice/pdf',
            responseType: 'blob',
            params: {
                customerId,
                invoiceNo: payload,
            },
        });
        const anchor = document.createElement('a');
        document.body.appendChild(anchor);

        let objectUrl = '';
        objectUrl = window.URL.createObjectURL(new Blob([response.data]));
        anchor.href = objectUrl;
        anchor.target = '_blank';

        if (download) {
            anchor.download = `THX-INVOICE-${payload}.pdf`;
        }

        anchor.click();

        window.URL.revokeObjectURL(objectUrl);

        yield put(getInvoicePdfSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getInvoicePdfFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* invoiceSaga() {
    yield all([
        takeEvery(GET_INVOICES_REQUEST, getInvoices),
        takeEvery(GET_INVOICE_PDF_REQUEST, getInvoicePdf),
    ]);
}

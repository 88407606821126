import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const AccountBalanceBar = ({ accountBalance, overdueBalance, accountBalanceString, overdueBalanceString }) => {
    const accountBalanceBarRef = useRef(null);
    const [overdueBalanceBarWidth, setOverdueBalanceBarWidth] = useState(0);

    useLayoutEffect(() => {
        const { width } = accountBalanceBarRef.current.getBoundingClientRect();
        if (accountBalance === 0) {
            setOverdueBalanceBarWidth(0);
            return;
        }
        setOverdueBalanceBarWidth(width * (overdueBalance / accountBalance));
    }, [accountBalance, overdueBalance]);

    return (
        <div ref={accountBalanceBarRef} className="account-balance-bar">
            <p>
                <span style={{ fontWeight: 'bold' }}>£{overdueBalanceString}</span> of £{accountBalanceString}
            </p>
            <div style={{ width: overdueBalanceBarWidth }} className="overdue-balance-bar">&nbsp;</div>
        </div>
    );
};

AccountBalanceBar.propTypes = {
    accountBalance: PropTypes.number.isRequired,
    overdueBalance: PropTypes.number.isRequired,
    accountBalanceString: PropTypes.number.isRequired,
    overdueBalanceString: PropTypes.number.isRequired,
};

export default AccountBalanceBar;

import React, { useEffect, useRef } from 'react';
import { Button, Carousel, Modal, Row, Spin } from 'antd';
import PropTypes from 'prop-types';

const TransportPhotosModal = ({
    open,
    setOpen,
    isFetchingPhotos,
    imageUrls,
    setImageUrls,
    position,
}) => {
    const slider = useRef(null);

    useEffect(() => {
        if (open && position) {
            slider.current.goTo(position);
        }
    }, [open]);

    return (
        <Modal
            centered
            width="70%"
            title="Photos"
            open={open}
            okText="Close"
            onOk={() => { setOpen(!open); setImageUrls([]); }}
            onCancel={() => { setOpen(!open); setImageUrls([]); }}>

            <Carousel ref={slider}>
                { !isFetchingPhotos ? imageUrls.map(imageUrl => (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ maxWidth: '100%', maxHeight: '70vh', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} alt={imageUrl} src={imageUrl} />
                    </div>
                )) : <Spin />}
            </Carousel>
            <Row justify="center" style={{ marginTop: '20px' }}>
                <Button onClick={() => slider.current.prev()}>Prev</Button>
                <Button onClick={() => slider.current.next()}>Next</Button>
            </Row>
        </Modal>
    );
};

TransportPhotosModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    isFetchingPhotos: PropTypes.bool.isRequired,
    imageUrls: PropTypes.array.isRequired,
    setImageUrls: PropTypes.func.isRequired,
    position: PropTypes.number,
};

TransportPhotosModal.defaultProps = {
    position: 0,
};

export default TransportPhotosModal;

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import ContactForm from '../ContactForm';

const ContactFormModal = ({ isVisible, setIsVisible }) => {
    const formRef = useRef(null);

    useEffect(() => {
        if (isVisible) {
            formRef.current.clearForm();
        }
    }, [isVisible]);

    return (
        <Modal
            centered
            title="ADD NEW CONTACT"
            visible={isVisible}
            okText="Save"
            onOk={() => {
                formRef.current.validateForm()
                    .then(() => {
                        formRef.current.submitForm();
                        setIsVisible(false);
                    })
                    .catch(() => { });
            }}
            onCancel={() => {
                setIsVisible(false);
            }}>
            <ContactForm hideButtons ref={formRef} />
        </Modal>
    );
};

ContactFormModal.propTypes = {
    isVisible: PropTypes.bool,
    setIsVisible: PropTypes.func.isRequired,
};

ContactFormModal.defaultProps = {
    isVisible: false,
};

export default ContactFormModal;

import { Record } from 'immutable';

const {
    DELETE_TOKEN_REQUEST,
    DELETE_TOKEN_SUCCESS,

    SAVE_TOKEN_REQUEST,
    SAVE_TOKEN_SUCCESS,

    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,

    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,

    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,

    SAVE_USER_DATA_REQUEST,

    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,

    GET_HIRE_DURATION_PREFERENCES_REQUEST,
    GET_HIRE_DURATION_PREFERENCES_SUCCESS,
    GET_HIRE_DURATION_PREFERENCES_FAILURE,

    UPDATE_HIRE_DURATION_PREFERENCES_REQUEST,
    UPDATE_HIRE_DURATION_PREFERENCES_SUCCESS,
    UPDATE_HIRE_DURATION_PREFERENCES_FAILURE,

    GET_PREFERENCES_NOTIFICATIONS_REQUEST,
    GET_PREFERENCES_NOTIFICATIONS_SUCCESS,
    GET_PREFERENCES_NOTIFICATIONS_FAILURE,

    UPDATE_PREFERENCES_NOTIFICATIONS_REQUEST,
    UPDATE_PREFERENCES_NOTIFICATIONS_SUCCESS,
    UPDATE_PREFERENCES_NOTIFICATIONS_FAILURE,

    GET_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST,
    GET_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS,
    GET_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE,

    UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST,
    UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS,
    UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE,

} = require('./userActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isCompanyNotificationFetching: false,
    isLoggedIn: false,
    userData: {},
    hireDurationPreferences: {},
    isFetchingHireDurationPreferences: false,
    notificationPreferences: {
        'order-confirmation': { mail: 'yes', sms: 'yes' },
        'off-hire-reminders': { mail: 'yes', sms: 'yes' },
        'delivery-collection-timeslots': { mail: 'yes', sms: 'yes' },
        'delivery-collection-confirmations': { mail: 'yes', sms: 'yes' },
        'scheduled-hire-report-day': '1',
        'scheduled-hire-report-time': '07:00',
        'scheduled-hire-report': [],
    },
    notificationCompanyContacts: [],
    orderRequestRecipient: null,
    paymentReceiptEmail: null,

});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function userReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case LOGIN_REQUEST:
    case DELETE_TOKEN_REQUEST:
    case SAVE_TOKEN_REQUEST:
    case LOGOUT_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case GET_PREFERENCES_NOTIFICATIONS_REQUEST:
    case UPDATE_PREFERENCES_NOTIFICATIONS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case GET_HIRE_DURATION_PREFERENCES_REQUEST:
    case UPDATE_HIRE_DURATION_PREFERENCES_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingHireDurationPreferences')
            .set('error', null);
    case GET_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST:
    case UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST:
        return state.set('isCompanyNotificationFetching', true);
    case SAVE_TOKEN_SUCCESS:
    case LOGIN_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', (payload && payload.user ? payload.user : {}))
            .set('isLoggedIn', true);

    case DELETE_TOKEN_SUCCESS:
    case LOGOUT_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', {})
            .set('isLoggedIn', false);

    case GET_HIRE_DURATION_PREFERENCES_SUCCESS:
    case UPDATE_HIRE_DURATION_PREFERENCES_SUCCESS:
        return state.set('isFetching', false)
            .set('hireDurationPreferences', payload)
            .set('isFetchingHireDurationPreferences', false)
            .set('notificationPreferences', {})
            .set('notificationCompanyContacts', []);

    case GET_PREFERENCES_NOTIFICATIONS_SUCCESS:
    case UPDATE_PREFERENCES_NOTIFICATIONS_SUCCESS:
        return state.set('isFetching', false)
            .set('notificationPreferences', payload.preferences)
            .set('notificationCompanyContacts', payload.company_contacts);

    case LOGIN_FAILURE:
    case LOGOUT_FAILURE:
        return state.set('isFetching', false)
            .set('isLoggedIn', false)
            .set('error', payload);

    case REGISTER_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case REGISTER_SUCCESS:
        return state.set('isFetching', false)
            .set('userData', (payload && payload.user ? payload.user : {}));

    case GET_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS:
    case UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_SUCCESS:
        return state.set('isCompanyNotificationFetching', false)
            .set('orderRequestRecipient', payload['order-request-recipient'])
            .set('paymentReceiptEmail', payload['payment-receipt-email']);

    case SAVE_USER_DATA_REQUEST: {
        const { userData } = state;

        return state.set('isFetching', false)
            .set('userData', (payload && payload.data && payload.data.user ? payload.data.user : userData));
    }

    case RESET_PASSWORD_SUCCESS:
        return state.set('isFetching', false);

    case REGISTER_FAILURE:
    case RESET_PASSWORD_FAILURE:
    case GET_PREFERENCES_NOTIFICATIONS_FAILURE:
    case UPDATE_PREFERENCES_NOTIFICATIONS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_HIRE_DURATION_PREFERENCES_FAILURE:
    case UPDATE_HIRE_DURATION_PREFERENCES_FAILURE:
    case GET_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE:
    case UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_FAILURE:
        return state.set('isFetching', false)
            .set('isCompanyNotificationFetching', false)
            .set('isFetchingHireDurationPreferences', false)
            .set('error', payload);
    default:
        return state;

    }
}

import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_INVOICES_REQUEST: null,
    GET_INVOICES_SUCCESS: null,
    GET_INVOICES_FAILURE: null,

    GET_INVOICE_PDF_REQUEST: null,
    GET_INVOICE_PDF_SUCCESS: null,
    GET_INVOICE_PDF_FAILURE: null,
});

export const {
    getInvoicesRequest,
    getInvoicesSuccess,
    getInvoicesFailure,
} = createActions(
    constants.GET_INVOICES_REQUEST,
    constants.GET_INVOICES_SUCCESS,
    constants.GET_INVOICES_FAILURE,
);

export const {
    getInvoicePdfRequest,
    getInvoicePdfSuccess,
    getInvoicePdfFailure,
} = createActions(
    constants.GET_INVOICE_PDF_REQUEST,
    constants.GET_INVOICE_PDF_SUCCESS,
    constants.GET_INVOICE_PDF_FAILURE,
);

import { Record } from 'immutable';

const {
    GET_TRACKING_DATA_REQUEST,
    GET_TRACKING_DATA_SUCCESS,
    GET_TRACKING_DATA_FAILURE,
} = require('./transportActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isFetchingTrackingData: false,
    trackingData: null,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function transportReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case GET_TRACKING_DATA_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingTrackingData', true)
            .set('trackingData', null)
            .set('error', null);

    case GET_TRACKING_DATA_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingTrackingData', false)
            .set('trackingData', payload);

    case GET_TRACKING_DATA_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingTrackingData', false)
            .set('error', payload);

    default:
        return state;
    }
}

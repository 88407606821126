import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({

    GET_CUSTOMER_CONTACTS_REQUEST: null,
    GET_CUSTOMER_CONTACTS_SUCCESS: null,
    GET_CUSTOMER_CONTACTS_FAILURE: null,

    CREATE_CUSTOMER_CONTACT_REQUEST: null,
    CREATE_CUSTOMER_CONTACT_SUCCESS: null,
    CREATE_CUSTOMER_CONTACT_FAILURE: null,

    UPDATE_CUSTOMER_CONTACT_REQUEST: null,
    UPDATE_CUSTOMER_CONTACT_SUCCESS: null,
    UPDATE_CUSTOMER_CONTACT_FAILURE: null,

    DELETE_CUSTOMER_CONTACT_REQUEST: null,
    DELETE_CUSTOMER_CONTACT_SUCCESS: null,
    DELETE_CUSTOMER_CONTACT_FAILURE: null,

    RESET_NEWLY_CREATED_CONTACT: null,

    GET_CONTACT_JOB_TITLES_REQUEST: null,
    GET_CONTACT_JOB_TITLES_SUCCESS: null,
    GET_CONTACT_JOB_TITLES_FAILURE: null,

    GET_CUSTOMER_SITES_REQUEST: null,
    GET_CUSTOMER_SITES_SUCCESS: null,
    GET_CUSTOMER_SITES_FAILURE: null,

    GET_CUSTOMER_SITE_REQUEST: null,
    GET_CUSTOMER_SITE_SUCCESS: null,
    GET_CUSTOMER_SITE_FAILURE: null,

    CREATE_CUSTOMER_SITE_REQUEST: null,
    CREATE_CUSTOMER_SITE_SUCCESS: null,
    CREATE_CUSTOMER_SITE_FAILURE: null,

    UPDATE_CUSTOMER_SITE_REQUEST: null,
    UPDATE_CUSTOMER_SITE_SUCCESS: null,
    UPDATE_CUSTOMER_SITE_FAILURE: null,

    DELETE_CUSTOMER_SITE_REQUEST: null,
    DELETE_CUSTOMER_SITE_SUCCESS: null,
    DELETE_CUSTOMER_SITE_FAILURE: null,

    GET_ACTIVE_SITE_LOCATIONS_REQUEST: null,
    GET_ACTIVE_SITE_LOCATIONS_SUCCESS: null,
    GET_ACTIVE_SITE_LOCATIONS_FAILURE: null,

    GET_ACTIVE_LOCATION_DETAILS_REQUEST: null,
    GET_ACTIVE_LOCATION_DETAILS_SUCCESS: null,
    GET_ACTIVE_LOCATION_DETAILS_FAILURE: null,

    GET_ACCOUNT_BALANCE_REQUEST: null,
    GET_ACCOUNT_BALANCE_SUCCESS: null,
    GET_ACCOUNT_BALANCE_FAILURE: null,

    GET_EQUIPMENT_ON_HIRE_REQUEST: null,
    GET_EQUIPMENT_ON_HIRE_SUCCESS: null,
    GET_EQUIPMENT_ON_HIRE_FAILURE: null,

    DOWNLOAD_INSTRUCTIONS_REQUEST: null,
    DOWNLOAD_INSTRUCTIONS_SUCCESS: null,
    DOWNLOAD_INSTRUCTIONS_FAILURE: null,

    DOWNLOAD_BATCH_INSTRUCTIONS_REQUEST: null,
    DOWNLOAD_BATCH_INSTRUCTIONS_SUCCESS: null,
    DOWNLOAD_BATCH_INSTRUCTIONS_FAILURE: null,

    DOWNLOAD_SERVICE_CERTIFICATE_REQUEST: null,
    DOWNLOAD_SERVICE_CERTIFICATE_SUCCESS: null,
    DOWNLOAD_SERVICE_CERTIFICATE_FAILURE: null,

    DOWNLOAD_LOLER_CERTIFICATE_REQUEST: null,
    DOWNLOAD_LOLER_CERTIFICATE_SUCCESS: null,
    DOWNLOAD_LOLER_CERTIFICATE_FAILURE: null,

    DOWNLOAD_BATCH_SERVICE_CERTIFICATES_REQUEST: null,
    DOWNLOAD_BATCH_SERVICE_CERTIFICATES_SUCCESS: null,
    DOWNLOAD_BATCH_SERVICE_CERTIFICATES_FAILURE: null,

    DOWNLOAD_BATCH_LOLER_REQUEST: null,
    DOWNLOAD_BATCH_LOLER_SUCCESS: null,
    DOWNLOAD_BATCH_LOLER_FAILURE: null,

    CROSSHIRE_DOCUMENTS_REQUEST: null,
    CROSSHIRE_DOCUMENTS_SUCCESS: null,
    CROSSHIRE_DOCUMENTS_FAILURE: null,

    TEMP_ACTION: null,
});

export const {
    getCustomerContactsRequest,
    getCustomerContactsSuccess,
    getCustomerContactsFailure,
} = createActions(
    constants.GET_CUSTOMER_CONTACTS_REQUEST,
    constants.GET_CUSTOMER_CONTACTS_SUCCESS,
    constants.GET_CUSTOMER_CONTACTS_FAILURE,
);

export const {
    createCustomerContactRequest,
    createCustomerContactSuccess,
    createCustomerContactFailure,
} = createActions(
    constants.CREATE_CUSTOMER_CONTACT_REQUEST,
    constants.CREATE_CUSTOMER_CONTACT_SUCCESS,
    constants.CREATE_CUSTOMER_CONTACT_FAILURE,
);

export const {
    updateCustomerContactRequest,
    updateCustomerContactSuccess,
    updateCustomerContactFailure,
} = createActions(
    constants.UPDATE_CUSTOMER_CONTACT_REQUEST,
    constants.UPDATE_CUSTOMER_CONTACT_SUCCESS,
    constants.UPDATE_CUSTOMER_CONTACT_FAILURE,
);

export const {
    deleteCustomerContactRequest,
    deleteCustomerContactSuccess,
    deleteCustomerContactFailure,
} = createActions(
    constants.DELETE_CUSTOMER_CONTACT_REQUEST,
    constants.DELETE_CUSTOMER_CONTACT_SUCCESS,
    constants.DELETE_CUSTOMER_CONTACT_FAILURE,
);

export const {
    resetNewlyCreatedContact,
} = createActions(
    constants.RESET_NEWLY_CREATED_CONTACT,
);

export const {
    getContactJobTitlesRequest,
    getContactJobTitlesSuccess,
    getContactJobTitlesFailure,
} = createActions(
    constants.GET_CONTACT_JOB_TITLES_REQUEST,
    constants.GET_CONTACT_JOB_TITLES_SUCCESS,
    constants.GET_CONTACT_JOB_TITLES_FAILURE,
);

export const {
    getCustomerSitesRequest,
    getCustomerSitesSuccess,
    getCustomerSitesFailure,
} = createActions(
    constants.GET_CUSTOMER_SITES_REQUEST,
    constants.GET_CUSTOMER_SITES_SUCCESS,
    constants.GET_CUSTOMER_SITES_FAILURE,
);

export const {
    getCustomerSiteRequest,
    getCustomerSiteSuccess,
    getCustomerSiteFailure,
} = createActions(
    constants.GET_CUSTOMER_SITE_REQUEST,
    constants.GET_CUSTOMER_SITE_SUCCESS,
    constants.GET_CUSTOMER_SITE_FAILURE,
);

export const {
    createCustomerSiteRequest,
    createCustomerSiteSuccess,
    createCustomerSiteFailure,
} = createActions(
    constants.CREATE_CUSTOMER_SITE_REQUEST,
    constants.CREATE_CUSTOMER_SITE_SUCCESS,
    constants.CREATE_CUSTOMER_SITE_FAILURE,
);

export const {
    updateCustomerSiteRequest,
    updateCustomerSiteSuccess,
    updateCustomerSiteFailure,
} = createActions(
    constants.UPDATE_CUSTOMER_SITE_REQUEST,
    constants.UPDATE_CUSTOMER_SITE_SUCCESS,
    constants.UPDATE_CUSTOMER_SITE_FAILURE,
);

export const {
    deleteCustomerSiteRequest,
    deleteCustomerSiteSuccess,
    deleteCustomerSiteFailure,
} = createActions(
    constants.DELETE_CUSTOMER_SITE_REQUEST,
    constants.DELETE_CUSTOMER_SITE_SUCCESS,
    constants.DELETE_CUSTOMER_SITE_FAILURE,
);

export const {
    getActiveSiteLocationsRequest,
    getActiveSiteLocationsSuccess,
    getActiveSiteLocationsFailure,
} = createActions(
    constants.GET_ACTIVE_SITE_LOCATIONS_REQUEST,
    constants.GET_ACTIVE_SITE_LOCATIONS_SUCCESS,
    constants.GET_ACTIVE_SITE_LOCATIONS_FAILURE,
);

export const {
    getActiveLocationDetailsRequest,
    getActiveLocationDetailsSuccess,
    getActiveLocationDetailsFailure,
} = createActions(
    constants.GET_ACTIVE_LOCATION_DETAILS_REQUEST,
    constants.GET_ACTIVE_LOCATION_DETAILS_SUCCESS,
    constants.GET_ACTIVE_LOCATION_DETAILS_FAILURE,
);

export const {
    getAccountBalanceRequest,
    getAccountBalanceSuccess,
    getAccountBalanceFailure,
} = createActions(
    constants.GET_ACCOUNT_BALANCE_REQUEST,
    constants.GET_ACCOUNT_BALANCE_SUCCESS,
    constants.GET_ACCOUNT_BALANCE_FAILURE,
);

export const {
    getEquipmentOnHireRequest,
    getEquipmentOnHireSuccess,
    getEquipmentOnHireFailure,
} = createActions(
    constants.GET_EQUIPMENT_ON_HIRE_REQUEST,
    constants.GET_EQUIPMENT_ON_HIRE_SUCCESS,
    constants.GET_EQUIPMENT_ON_HIRE_FAILURE,
);

export const {
    downloadInstructionsRequest,
    downloadInstructionsSuccess,
    downloadInstructionsFailure,

    downloadServiceCertificateRequest,
    downloadServiceCertificateSuccess,
    downloadServiceCertificateFailure,
} = createActions(
    constants.DOWNLOAD_INSTRUCTIONS_REQUEST,
    constants.DOWNLOAD_INSTRUCTIONS_SUCCESS,
    constants.DOWNLOAD_INSTRUCTIONS_FAILURE,

    constants.DOWNLOAD_SERVICE_CERTIFICATE_REQUEST,
    constants.DOWNLOAD_SERVICE_CERTIFICATE_SUCCESS,
    constants.DOWNLOAD_SERVICE_CERTIFICATE_FAILURE,
);

export const {
    downloadLolerCertificateRequest,
    downloadLolerCertificateSuccess,
    downloadLolerCertificateFailure,
} = createActions(
    constants.DOWNLOAD_LOLER_CERTIFICATE_REQUEST,
    constants.DOWNLOAD_LOLER_CERTIFICATE_SUCCESS,
    constants.DOWNLOAD_LOLER_CERTIFICATE_FAILURE,
);

export const {
    downloadBatchInstructionsRequest,
    downloadBatchInstructionsSuccess,
    downloadBatchInstructionsFailure,

    downloadBatchServiceCertificatesRequest,
    downloadBatchServiceCertificatesSuccess,
    downloadBatchServiceCertificatesFailure,
} = createActions(
    constants.DOWNLOAD_BATCH_INSTRUCTIONS_REQUEST,
    constants.DOWNLOAD_BATCH_INSTRUCTIONS_SUCCESS,
    constants.DOWNLOAD_BATCH_INSTRUCTIONS_FAILURE,

    constants.DOWNLOAD_BATCH_SERVICE_CERTIFICATES_REQUEST,
    constants.DOWNLOAD_BATCH_SERVICE_CERTIFICATES_SUCCESS,
    constants.DOWNLOAD_BATCH_SERVICE_CERTIFICATES_FAILURE,
);

export const {
    downloadBatchLolerRequest,
    downloadBatchLolerSuccess,
    downloadBatchLolerFailure,
} = createActions(
    constants.DOWNLOAD_BATCH_LOLER_REQUEST,
    constants.DOWNLOAD_BATCH_LOLER_SUCCESS,
    constants.DOWNLOAD_BATCH_LOLER_FAILURE,
);

export const {
    crosshireDocumentsRequest,
    crosshireDocumentsSuccess,
    crosshireDocumentsFailure,
} = createActions(
    constants.CROSSHIRE_DOCUMENTS_REQUEST,
    constants.CROSSHIRE_DOCUMENTS_SUCCESS,
    constants.CROSSHIRE_DOCUMENTS_FAILURE,
);

export const {
    tempAction,
} = createActions(
    constants.TEMP_ACTION,
);

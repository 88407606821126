import React from 'react';
import PropTypes from 'prop-types';

import { CoreRouter } from './utils/coreRouter';
import OrderRequests from '../pages/OrdersRequests';
import OrderRequest from '../pages/OrderRequest';
import HireContracts from '../pages/HireContracts';
import HireContract from '../pages/HireContract';
import Invoices from '../pages/Invoices';
import InvoiceQueries from '../pages/InvoiceQueries';
import InvoiceQuery from '../pages/InvoiceQuery';
import HomePageWidgetBlock from '../pages/HomePageWidgetBlock';
import Account from '../pages/Account';
import TestPage from '../pages/TestPage';
import CertificatesAndInstructions from '../pages/CertificatesAndInstructions';
import Contacts from '../pages/Contacts';
import SiteAddresses from '../pages/SiteAddresses';
import SiteAddress from '../pages/SiteAddress';
import DeliveryDetails from '../components/Checkout/DeliveryDetails';
import Payments from '../pages/Payments';
import Notifications from '../pages/Notifications';
import OrderTracking from '../pages/OrderTracking';

const Routes = () => (
    <CoreRouter routes={[
        { path: '/', component: HomePageWidgetBlock, type: 'default' },
        { path: '/test', component: TestPage, type: 'default' },
        { path: '/account', component: Account, type: 'auth' },
        { path: '/account/order-requests/', component: OrderRequests, type: 'auth' },
        { path: '/account/order-requests/:webId', component: OrderRequest, type: 'auth' },
        { path: '/account/hire-contracts/', component: HireContracts, type: 'auth' },
        { path: '/account/hire-contracts/map/', component: HireContracts, type: 'auth', props: { defaultView: 'map' } },
        { path: '/account/hire-contracts/:contractNo/', component: HireContract, type: 'auth' },
        { path: '/account/contacts/', component: Contacts, type: 'auth' },
        { path: '/account/certificates-instructions/', component: CertificatesAndInstructions, type: 'auth' },
        { path: '/account/invoices/', component: Invoices, type: 'auth' },
        { path: '/account/invoice-queries/', component: InvoiceQueries, type: 'auth' },
        { path: '/account/invoice-queries/:invoiceQueryNo/', component: InvoiceQuery, type: 'auth' },
        { path: '/account/addresses/', component: SiteAddresses, type: 'auth' },
        { path: '/account/addresses/create', component: SiteAddress, type: 'auth' },
        { path: '/account/addresses/:id/update', component: SiteAddress, type: 'auth' },
        { path: '/account/payments/', component: Payments, type: 'auth' },
        { path: '/account/notifications', component: Notifications, type: 'auth' },
        { path: '/checkout/', component: DeliveryDetails, type: 'auth' },
        { path: '/account/order-tracking/:workItemId', component: OrderTracking, type: 'default' },
    ]} />
);

Routes.defaultProps = {
    persistor: null,
};

Routes.propTypes = {
    persistor: PropTypes.object,
};

export default Routes;

import React, { useEffect } from 'react';
import TinySlider from 'tiny-slider-react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import {
    faInfo,
    faPallet,
    faTruckPickup,
    faScrewdriverWrench,
    faSterlingSign,
} from '@fortawesome/free-solid-svg-icons';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAccountPageWidgetRequest } from '../../core/kpi/kpiActions';
import DonutWithIcon from '../../components/Widgets/DonutWithIcon';
import 'tiny-slider/dist/tiny-slider.css';
import './accountPageWidgetBlock.scss';

const settings = {
    controls: false,
    lazyload: true,
    nav: false,
    mouseDrag: true,
    loop: false,
    responsive: {
        640: {
            edgePadding: 20,
            gutter: 20,
            items: 2,
        },
        700: {
            gutter: 30,
            items: 4,
        },
        900: {
            items: 4,
        },
    },
};

const AccountPageWidgetBlock = () => {
    const dispatch = useDispatch();
    const { accountPageWidget, isFetching } = useSelector(state => state.kpi);
    useEffect(() => {
        dispatch(getAccountPageWidgetRequest());
    }, []);

    const widgets = accountPageWidget?.widget_block_widgets_active?.map((widget) => {
        let icon = faInfo;
        switch (widget?.icon) {
        case 'pallet':
            icon = faPallet;
            break;
        case 'truck':
            icon = faTruckPickup;
            break;
        case 'sterling':
            icon = faSterlingSign;
            break;
        case 'screwdriver':
            icon = faScrewdriverWrench;
            break;
        default:
            icon = widget?.icon;
        }
        const FaIcon = <FontAwesomeIcon icon={icon} />;
        return (
            <div className="widget-box">
                <DonutWithIcon
                    icon={FaIcon}
                    before={widget?.data_prefix}
                    value={widget?.widget_data_point?.widget_companies_data_point_values?.cached_value}
                    after={widget?.data_suffix}
                    label={widget?.title} />
            </div>
        );
    });

    return (
        <div className="account-page-widget-block">
            <div className="ui icon message">
                <div className="content" style={{ width: '100%' }}>
                    <Spin spinning={isFetching}>
                        <div className="account-page-widget-block">
                            <div className="inside-container">
                                <TinySlider settings={settings}>
                                    {widgets ?? []}
                                </TinySlider>
                            </div>
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default AccountPageWidgetBlock;

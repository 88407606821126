import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import ScorchTable from 'scorch-react-table';

import './style.css';

const OrderRequestItems = () => {

    const { isFetchingOrderRequestItems, orderRequestItems } = useSelector(state => state.orderRequest);

    const columns = [
        {
            title: 'PRODUCT',
            dataIndex: 'title',
            key: 'title',
            render: (val, record) => (
                record.permalink ? <a href={record.permalink} style={{ color: '#da0812' }}>{val}</a> : val
            ),
            width: '60%',
        },
        {
            title: 'WEEK RATE',
            dataIndex: 'weeklyRate',
            key: 'weeklyRate',
            render: (val) => `£${val}`,
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'SUBTOTAL',
            dataIndex: 'subtotal',
            key: 'subtotal',
            render: (val) => `£${val}`,
        },
    ];

    return (
        <div className="order-main">
            {!isFetchingOrderRequestItems ? (
                <ScorchTable dataSource={orderRequestItems} columns={columns} />
            ) : (
                <div style={{ textAlign: 'center' }}>
                    <Spin />
                </div>
            )}
        </div>
    );
};

export default OrderRequestItems;

/* eslint-disable */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import lockr from 'lockr';
import { notification } from 'antd';
import { getAuthKey, getTokenExpires } from "./api";
import { isUndefined } from 'underscore';
import NotFound from '../../pages/NotFound';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
    LOCALSTORAGE_EXPIRES,
} = require('../constants').default;

// const onlyAuthorisedAllowed = () => {
//     const token = getAuthKey();
//     const tokenExpiry = getTokenExpires();
//     if (token !== '') {
//         const authDate = tokenExpiry;
//         if (authDate !== '') {
//             const parsedAuthDate = new Date(authDate * 1000);
//             const now = new Date();
//             if (now >= parsedAuthDate) {
//                 notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
//                 return false;
//             }
//         } else {
//             notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
//             return false;
//         }
//         return true;
//     }
//     return false;
// };

const unauthorisedError = 'Authentication to the external data source has failed, please log out and log back in. If this message reappears please contact an administrator.'
const onlyAuthorisedAllowed = () => {

    const token = getAuthKey();
    const tokenExpiry = getTokenExpires();

    const response = lockr.get(AUTH_KEY);
    if (token && tokenExpiry) {
        const authDate = tokenExpiry
        if (!isUndefined(authDate)) {
            const parsedAuthDate = new Date(authDate);
            const now = new Date();
            const differenceMilliseconds = now - parsedAuthDate;
            const differenceMinutes = Math.floor(differenceMilliseconds / 1000 / 60);
            if (differenceMinutes >= tokenExpiry) {
                notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
                return false;
            }
        } else {
            notification.error({ message: 'Error', description: 'Session expired. Please login again.' });
            return false;
        }
        return true;
    }
    return false;
};
const LogoutRoute = ({ exact, path, persistor }) => {
    lockr.rm(AUTH_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
    lockr.rm(LOCALSTORAGE_EXPIRES);

    localStorage.clear();
    lockr.flush();
    persistor.flush();
    persistor.purge();
    window.location.href = '/';
}

const CoreRouter = ({ routes }) => (
    <Routes>
        {routes.map((route, index) => (
            route.type === 'auth' ?
                <Route key={index} exact path={route.path} element={
                    onlyAuthorisedAllowed() ? <route.component {...route.props || {}} /> : <Navigate to="/" />
                } role={route.role} allowedOnlyRoles={route.allowedOnlyRoles} />
                : <Route key={index} exact path={route.path} element={<route.component />} />
        ))}
        <Route element={NotFound} />
    </Routes>
);

export {
    CoreRouter,
    onlyAuthorisedAllowed,
    LogoutRoute,
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row, Spin, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { PlusCircleOutlined } from '@ant-design/icons';

import { getOrderRequestDetailsRequest } from '../../core/orderRequest/orderRequestActions';

import OrderDetails from '../../components/OrderDetails';
import OrderRequestItems from '../../components/OrderRequestItems';
import OrderActions from '../../components/OrderActions';
import './style.css';
import Main from '../../components/layout/Main';

const OrderRequest = () => {
    const { webId } = useParams();
    const dispatch = useDispatch();
    const { orderRequest, isFetchingOrderRequestDetails } = useSelector(state => state.orderRequest);

    const [selectedAction, setSelectedAction] = useState('none');
    const [showActionModal, setShowActionModal] = useState(false);

    useEffect(() => {
        dispatch(getOrderRequestDetailsRequest(webId));
    }, []);

    return !isFetchingOrderRequestDetails ? (
        <Main>
            <OrderDetails
                info={{
                    contractNo: `WEB-${orderRequest.webId}`,
                    poNumber: orderRequest.poNumber,
                    onHireDate: orderRequest.onHireDate,
                    status: orderRequest.status,
                }}
                customerContact={orderRequest.customerContact}
                siteAddress={orderRequest.siteAddress}
                orderItemsComponent={<OrderRequestItems />} />
            <Row justify="end" style={{ marginTop: '20px' }}>
                <Col span={6}>
                    <Row justify="space-between" align="middle">
                        <Tooltip title="Approve">
                            <FontAwesomeIcon icon={faCheckCircle} onClick={() => setSelectedAction('approve-request')} style={{ fontSize: '30px', color: '#da0812', cursor: 'pointer' }} />
                        </Tooltip>
                        <Tooltip title="Decline">
                            <FontAwesomeIcon icon={faTimesCircle} onClick={() => setSelectedAction('decline-request')} style={{ fontSize: '30px', color: '#da0812', cursor: 'pointer' }} />
                        </Tooltip>
                        <Tooltip title="Edit">
                            <PlusCircleOutlined onClick={() => setSelectedAction('edit-request')} style={{ fontSize: '30px', color: '#da0812' }} />
                        </Tooltip>
                    </Row>
                </Col>
            </Row>
            <OrderActions
                setSelectedAction={setSelectedAction}
                selectedAction={selectedAction}
                setSelectedRecord={() => {}}
                selectedRecord={orderRequest}
                showActionModal={showActionModal}
                setShowActionModal={setShowActionModal} />
        </Main>
    ) : (
        <Row>
            <Col>
                <Spin />
            </Col>
        </Row>
    );
};

export default OrderRequest;

import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_HOMEPAGE_WIDGET_REQUEST: null,
    GET_HOMEPAGE_WIDGET_SUCCESS: null,
    GET_HOMEPAGE_WIDGET_FAILURE: null,

    GET_ACCOUNT_PAGE_WIDGET_REQUEST: null,
    GET_ACCOUNT_PAGE_WIDGET_SUCCESS: null,
    GET_ACCOUNT_PAGE_WIDGET_FAILURE: null,

});

export const {
    getHomepageWidgetRequest,
    getHomepageWidgetSuccess,
    getHomepageWidgetFailure,
} = createActions(
    constants.GET_HOMEPAGE_WIDGET_REQUEST,
    constants.GET_HOMEPAGE_WIDGET_SUCCESS,
    constants.GET_HOMEPAGE_WIDGET_FAILURE,
);

export const {
    getAccountPageWidgetRequest,
    getAccountPageWidgetSuccess,
    getAccountPageWidgetFailure,
} = createActions(
    constants.GET_ACCOUNT_PAGE_WIDGET_REQUEST,
    constants.GET_ACCOUNT_PAGE_WIDGET_SUCCESS,
    constants.GET_ACCOUNT_PAGE_WIDGET_FAILURE,
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Collapse, Spin, message, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ScorchTable from 'scorch-react-table';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import {
    downloadServiceCertificateRequest,
    downloadBatchServiceCertificatesRequest,
    downloadBatchInstructionsRequest, downloadBatchLolerRequest,
    downloadInstructionsRequest,
    downloadLolerCertificateRequest,
    getEquipmentOnHireRequest,
    crosshireDocumentsRequest,
    tempAction,
} from '../../core/account/accountActions';
import Main from '../../components/layout/Main';

import './style.scss';

const DownloadDropdown = ({ location }) => {
    const { equipment } = location;
    const dispatch = useDispatch();

    const certificates = equipment.reduce((acc, curr) => {
        if (curr.certificates?.service) {
            acc.push(curr.certificates.service);
        }
        return acc;
    }, []);

    const lolers = equipment.reduce((acc, curr) => {
        if (curr.certificates?.loler) {
            acc.push(curr.certificates.loler);
        }
        return acc;
    }, []);

    const onChange = (value) => {
        switch (value) {
        case 'instructions': {
            const instructions = equipment.reduce((acc, curr) => {
                if (curr.instructions) {
                    acc.push(curr.instructions);
                }
                return acc;
            }, []);
            if (instructions.length > 0) {
                dispatch(downloadBatchInstructionsRequest(instructions));
            } else {
                message.info('No instructions available');
            }
            break;
        }
        case 'inspection-certificates': {
            if (certificates.length > 0) {
                dispatch(downloadBatchServiceCertificatesRequest(certificates));
            } else {
                message.info('No inspection certificates available');
            }
            break;
        }
        case 'loler-certificates': {
            dispatch(downloadBatchLolerRequest(equipment[0].SiteId));
            break;
        }
        default: break;
        }
    };

    return (
        <Select
            onChange={onChange}
            className="download-dropdown"
            dropdownMatchSelectWidth={false}
            placement="bottomRight"
            value="Download Documents">
            <Select.Option value="instructions">Instructions</Select.Option>
            <Select.Option value="inspection-certificates">Inspection Certificates</Select.Option>
            {(lolers.length > 0)
                ? <Select.Option value="loler-certificates">LOLER Certificates</Select.Option>
                : null}
        </Select>
    );
};

const CertificatesAndInstructions = () => {
    const { equipmentOnHire, isFetching, isFetchingEquipmentOnHire } = useSelector(state => state.account);
    const dispatch = useDispatch();

    // eslint-disable-next-line no-undef
    const activeSiteId = new URLSearchParams(window.location.search).get('activeSiteId');

    useEffect(() => {
        dispatch(tempAction());
        dispatch(getEquipmentOnHireRequest());
    }, []);

    const columns = [
        {
            title: 'CONTRACT NO.',
            dataIndex: 'ContractNo',
            key: 'ContractNo',
            width: '70px',
        },
        {
            title: 'PO NO.',
            dataIndex: 'StockNo',
            key: 'StockNo',
            width: '50px',
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'Description',
            key: 'Description',
            width: '140px',
        },
        {
            title: 'FLEET NO.',
            dataIndex: 'AccountNo',
            key: 'AccountNo',
            width: '70px',
        },
        {
            title: 'INSTRUCTIONS',
            dataIndex: 'instructions',
            key: 'instructions',
            width: '70px',
            render: (instructions, row) => {
                if (instructions === 'request') {
                    return (
                        <button
                            className="certificates-button"
                            type="button"
                            onClick={() => dispatch(crosshireDocumentsRequest({
                                description: row.Description,
                                contractNo: row.ContractNo,
                                stockNo: row.StockNo,
                                type: 'instructions' }))}>
                            Request
                        </button>
                    );
                }
                if (instructions) {
                    return (
                        <button
                            className="instructions-button"
                            type="button"
                            onClick={() => dispatch(downloadInstructionsRequest(instructions))}>
                            Download
                        </button>
                    );
                }
                return null;
            },
        },
        {
            title: 'CERTIFICATES',
            dataIndex: 'certificates',
            key: 'certificates',
            width: '70px',
            render: (certificates, row) => {
                if (certificates?.service && certificates?.loler) {
                    return (
                        <Dropdown
                            trigger={['click']}
                            placement="bottomRight"
                            menu={{
                                items: [
                                    {
                                        key: 'inspection',
                                        label: <span className="certificate-dropdown-item">Inspection Certificate</span>,
                                        onClick: () => dispatch(downloadServiceCertificateRequest(certificates.service)),
                                    },
                                    {
                                        key: 'loler',
                                        label: <span className="certificate-dropdown-item">LOLER Certificate</span>,
                                        onClick: () => dispatch(downloadLolerCertificateRequest(row.StockNo)),
                                    },
                                ] }}>
                            <button
                                className="certificates-button"
                                type="button">
                                Download
                                &nbsp;
                                <FontAwesomeIcon icon={faAngleDown} style={{ fontSize: '10px', color: '#da0812', textAlign: 'center' }} />
                            </button>
                        </Dropdown>
                    );
                }
                if (certificates?.service) {
                    return (
                        <button
                            className="certificates-button"
                            type="button"
                            onClick={() => dispatch(downloadServiceCertificateRequest(certificates.service))}>
                            Download
                        </button>
                    );
                }
                if (certificates === 'request') {
                    return (
                        <button
                            className="certificates-button"
                            type="button"
                            onClick={() => dispatch(crosshireDocumentsRequest({
                                description: row.Description,
                                contractNo: row.ContractNo,
                                stockNo: row.StockNo,
                                type: 'certificates' }))}>
                            Request
                        </button>
                    );
                }
                return null;
            },
        },
    ];

    return (
        <Main title="Certificates & Instructions">
            <div className="certificates-instructions-container">
                <Spin spinning={isFetchingEquipmentOnHire}
                    style={{ marginTop: '40%' }}>
                    <Collapse
                        defaultActiveKey={activeSiteId}
                        style={{ backgroundColor: 'transparent' }}
                        bordered={false}>
                        {Object.entries(equipmentOnHire).map(([id, location]) => (
                            <Collapse.Panel
                                key={id}
                                style={{
                                    backgroundColor: 'transparent',
                                    borderBottomColor: '#da0812',
                                    borderBottomWidth: '2px',
                                    padding: '10px 0px',
                                }}
                                showArrow={false}
                                header={(
                                    <div className="site-header-container">
                                        <h3 className="site-header">{location.name}</h3>
                                        <DownOutlined
                                            color="#da0812"
                                            fontSize='20px"' />
                                    </div>
                                )}>
                                <div className="download-dropdown-container">
                                    <DownloadDropdown location={location} />
                                </div>
                                <ScorchTable
                                    columns={columns}
                                    dataSource={location.equipment}
                                    loading={isFetching}
                                    key={id} />
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Spin>
            </div>
        </Main>
    );
};

DownloadDropdown.propTypes = {
    location: PropTypes.shape({
        equipment: PropTypes.arrayOf(PropTypes.shape({
            ContractNo: PropTypes.string,
            StockNo: PropTypes.string,
            Description: PropTypes.string,
            AccountNo: PropTypes.string,
            instructions: PropTypes.string,
            SiteId: PropTypes.string,
        })).isRequired,
    }).isRequired,
};

export default CertificatesAndInstructions;

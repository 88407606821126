import { Record } from 'immutable';

const {
    GET_CUSTOMER_CONTACTS_REQUEST,
    GET_CUSTOMER_CONTACTS_SUCCESS,
    GET_CUSTOMER_CONTACTS_FAILURE,

    CREATE_CUSTOMER_CONTACT_REQUEST,
    CREATE_CUSTOMER_CONTACT_SUCCESS,
    CREATE_CUSTOMER_CONTACT_FAILURE,

    UPDATE_CUSTOMER_CONTACT_REQUEST,
    UPDATE_CUSTOMER_CONTACT_SUCCESS,
    UPDATE_CUSTOMER_CONTACT_FAILURE,

    DELETE_CUSTOMER_CONTACT_REQUEST,
    DELETE_CUSTOMER_CONTACT_SUCCESS,
    DELETE_CUSTOMER_CONTACT_FAILURE,

    RESET_NEWLY_CREATED_CONTACT,

    GET_CONTACT_JOB_TITLES_REQUEST,
    GET_CONTACT_JOB_TITLES_SUCCESS,
    GET_CONTACT_JOB_TITLES_FAILURE,

    GET_CUSTOMER_SITES_REQUEST,
    GET_CUSTOMER_SITES_SUCCESS,
    GET_CUSTOMER_SITES_FAILURE,

    GET_CUSTOMER_SITE_REQUEST,
    GET_CUSTOMER_SITE_SUCCESS,
    GET_CUSTOMER_SITE_FAILURE,

    CREATE_CUSTOMER_SITE_REQUEST,
    CREATE_CUSTOMER_SITE_SUCCESS,
    CREATE_CUSTOMER_SITE_FAILURE,

    UPDATE_CUSTOMER_SITE_REQUEST,
    UPDATE_CUSTOMER_SITE_SUCCESS,
    UPDATE_CUSTOMER_SITE_FAILURE,

    DELETE_CUSTOMER_SITE_REQUEST,
    DELETE_CUSTOMER_SITE_SUCCESS,
    DELETE_CUSTOMER_SITE_FAILURE,

    GET_ACTIVE_SITE_LOCATIONS_REQUEST,
    GET_ACTIVE_SITE_LOCATIONS_SUCCESS,
    GET_ACTIVE_SITE_LOCATIONS_FAILURE,

    GET_ACTIVE_LOCATION_DETAILS_REQUEST,
    GET_ACTIVE_LOCATION_DETAILS_SUCCESS,
    GET_ACTIVE_LOCATION_DETAILS_FAILURE,

    GET_ACCOUNT_BALANCE_REQUEST,
    GET_ACCOUNT_BALANCE_SUCCESS,
    GET_ACCOUNT_BALANCE_FAILURE,

    GET_EQUIPMENT_ON_HIRE_REQUEST,
    GET_EQUIPMENT_ON_HIRE_SUCCESS,
    GET_EQUIPMENT_ON_HIRE_FAILURE,

    DOWNLOAD_INSTRUCTIONS_REQUEST,
    DOWNLOAD_INSTRUCTIONS_SUCCESS,
    DOWNLOAD_INSTRUCTIONS_FAILURE,

    DOWNLOAD_SERVICE_CERTIFICATE_REQUEST,
    DOWNLOAD_SERVICE_CERTIFICATE_SUCCESS,
    DOWNLOAD_SERVICE_CERTIFICATE_FAILURE,

    DOWNLOAD_LOLER_CERTIFICATE_REQUEST,
    DOWNLOAD_LOLER_CERTIFICATE_SUCCESS,
    DOWNLOAD_LOLER_CERTIFICATE_FAILURE,

    DOWNLOAD_BATCH_INSTRUCTIONS_REQUEST,
    DOWNLOAD_BATCH_INSTRUCTIONS_SUCCESS,
    DOWNLOAD_BATCH_INSTRUCTIONS_FAILURE,

    DOWNLOAD_BATCH_SERVICE_CERTIFICATES_REQUEST,
    DOWNLOAD_BATCH_SERVICE_CERTIFICATES_SUCCESS,
    DOWNLOAD_BATCH_SERVICE_CERTIFICATES_FAILURE,

    DOWNLOAD_BATCH_LOLER_REQUEST,
    DOWNLOAD_BATCH_LOLER_SUCCESS,
    DOWNLOAD_BATCH_LOLER_FAILURE,

} = require('./accountActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    customerContacts: [],
    newlyCreatedContact: {},
    customerContactJobTitles: [],
    isFetchingCustomerContacts: false,
    isFetchingCustomerContactJobTitles: false,
    customerSites: [],
    isFetchingCustomerSites: false,
    customerSite: null,
    isFetchingCustomerSite: false,
    activeSiteLocations: [],
    isFetchingActiveSiteLocations: false,
    activeLocationDetailItems: {},
    isFetchingActiveLocationDetails: false,
    accountBalance: 'N/A',
    equipmentOnHire: {},
    isFetchingEquipmentOnHire: false,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function accountReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case GET_CUSTOMER_CONTACTS_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingCustomerContacts', true)
            .set('error', null);

    case RESET_NEWLY_CREATED_CONTACT:
        return state.set('newlyCreatedContact', {});

    case CREATE_CUSTOMER_CONTACT_REQUEST:
        return state.set('error', null);

    case UPDATE_CUSTOMER_CONTACT_REQUEST:
        return state.set('error', null);

    case DELETE_CUSTOMER_CONTACT_REQUEST:
        return state.set('error', null);

    case CREATE_CUSTOMER_CONTACT_SUCCESS:
        return state.set('newlyCreatedContact', payload)
            .set('error', null);

    case UPDATE_CUSTOMER_CONTACT_SUCCESS:
        return state.set('error', null);

    case DELETE_CUSTOMER_CONTACT_SUCCESS:
        return state.set('error', null);

    case CREATE_CUSTOMER_CONTACT_FAILURE:
        return state.set('error', payload);

    case UPDATE_CUSTOMER_CONTACT_FAILURE:
        return state.set('error', payload);

    case DELETE_CUSTOMER_CONTACT_FAILURE:
        return state.set('error', payload);

    case GET_CONTACT_JOB_TITLES_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingCustomerContactJobTitles', true)
            .set('error', null);

    case GET_CONTACT_JOB_TITLES_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingCustomerContactJobTitles', false)
            .set('customerContactJobTitles', payload);

    case GET_CONTACT_JOB_TITLES_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingCustomerContactJobTitles', false)
            .set('error', payload);

    case GET_ACCOUNT_BALANCE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_CUSTOMER_SITES_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingCustomerSites', true)
            .set('error', null);

    case GET_CUSTOMER_SITE_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingCustomerSite', true)
            .set('customerSite', null)
            .set('error', null);

    case CREATE_CUSTOMER_SITE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case UPDATE_CUSTOMER_SITE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case DELETE_CUSTOMER_SITE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_ACTIVE_SITE_LOCATIONS_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingActiveSiteLocations', true)
            .set('activeSiteLocations', [])
            .set('error', null);

    case GET_ACTIVE_LOCATION_DETAILS_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingActiveLocationDetails', true)
            .set('activeLocationDetailItems', {})
            .set('error', null);
    case GET_ACCOUNT_BALANCE_SUCCESS:
        return state.set('isFetching', false)
            .set('accountBalance', payload);

    case GET_CUSTOMER_CONTACTS_SUCCESS:
        return state.set('isFetching', false)
            .set('customerContacts', payload)
            .set('isFetchingCustomerContacts', false);

    case GET_CUSTOMER_SITES_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingCustomerSites', false)
            .set('customerSites', payload);

    case GET_CUSTOMER_SITE_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingCustomerSite', false)
            .set('customerSite', payload);

    case CREATE_CUSTOMER_SITE_SUCCESS:
        return state.set('isFetching', false)
            .set('customerSite', payload);

    case UPDATE_CUSTOMER_SITE_SUCCESS:
        return state.set('isFetching', false);

    case DELETE_CUSTOMER_SITE_SUCCESS:
        return state.set('isFetching', false);

    case GET_ACTIVE_SITE_LOCATIONS_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingActiveSiteLocations', false)
            .set('activeSiteLocations', payload);

    case GET_ACTIVE_LOCATION_DETAILS_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingActiveLocationDetails', false)
            .set('activeLocationDetailItems', payload);

    case GET_CUSTOMER_CONTACTS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload)
            .set('isFetchingCustomerContacts', false);

    case GET_CUSTOMER_SITES_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingCustomerSites', false)
            .set('error', payload);

    case GET_CUSTOMER_SITE_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingCustomerSite', false)
            .set('error', payload);

    case CREATE_CUSTOMER_SITE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case UPDATE_CUSTOMER_SITE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case DELETE_CUSTOMER_SITE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_ACTIVE_SITE_LOCATIONS_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingActiveSiteLocations', false)
            .set('error', payload);

    case GET_ACTIVE_LOCATION_DETAILS_FAILURE:
    case GET_ACCOUNT_BALANCE_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingActiveLocationDetails', false)
            .set('error', payload);

    case GET_EQUIPMENT_ON_HIRE_REQUEST:
        return state.set('equipmentOnHire', {})
            .set('isFetching', true)
            .set('isFetchingEquipmentOnHire', true)
            .set('error', null);

    case GET_EQUIPMENT_ON_HIRE_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingEquipmentOnHire', false)
            .set('equipmentOnHire', payload);

    case GET_EQUIPMENT_ON_HIRE_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingEquipmentOnHire', false)
            .set('error', payload);

    case DOWNLOAD_INSTRUCTIONS_REQUEST:
    case DOWNLOAD_SERVICE_CERTIFICATE_REQUEST:
    case DOWNLOAD_LOLER_CERTIFICATE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case DOWNLOAD_INSTRUCTIONS_SUCCESS:
    case DOWNLOAD_SERVICE_CERTIFICATE_SUCCESS:
    case DOWNLOAD_LOLER_CERTIFICATE_SUCCESS:
        return state.set('isFetching', false);

    case DOWNLOAD_INSTRUCTIONS_FAILURE:
    case DOWNLOAD_SERVICE_CERTIFICATE_FAILURE:
    case DOWNLOAD_LOLER_CERTIFICATE_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case DOWNLOAD_BATCH_INSTRUCTIONS_REQUEST:
    case DOWNLOAD_BATCH_SERVICE_CERTIFICATES_REQUEST:
    case DOWNLOAD_BATCH_LOLER_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case DOWNLOAD_BATCH_INSTRUCTIONS_SUCCESS:
    case DOWNLOAD_BATCH_SERVICE_CERTIFICATES_SUCCESS:
    case DOWNLOAD_BATCH_LOLER_SUCCESS:
        return state.set('isFetching', false);

    case DOWNLOAD_BATCH_INSTRUCTIONS_FAILURE:
    case DOWNLOAD_BATCH_SERVICE_CERTIFICATES_FAILURE:
    case DOWNLOAD_BATCH_LOLER_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}

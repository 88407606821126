import React, { useEffect, useState } from 'react';
import { Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ScorchTable from 'scorch-react-table';
import history from '../../core/utils/history';

import Main from '../../components/layout/Main';
import { getCustomerSitesRequest, deleteCustomerSiteRequest } from '../../core/account/accountActions';

const SiteAddresses = () => {
    const dispatch = useDispatch();
    const { customerSites, isFetchingCustomerSites } = useSelector(state => state.account);

    const initialPaginationData = {
        current: 1,
        pageSize: 20,
        total: customerSites.length,
        position: ['bottomCenter'],
        size: 'large',
    };

    const [paginationMetaData, setPaginationMetaData] = useState(initialPaginationData);

    const sortedCustomerSites = customerSites.sort((a, b) => {
        const lowerCaseA = a.ADR_NAME.toLowerCase();
        const lowerCaseB = b.ADR_NAME.toLowerCase();

        if (lowerCaseA < lowerCaseB) {
            return -1;
        }
        if (lowerCaseA > lowerCaseB) {
            return 1;
        }
        return 0;
    });

    const handleActionChange = (value, row) => {
        switch (value) {
        case 'delete-address':
            dispatch(deleteCustomerSiteRequest({ siteId: row.ADR_ID }));
            break;
        case 'edit-address':
            history.push(`/account/addresses/${row.ADR_ID}/update`);
            break;
        default:
            break;
        }
    };

    const columns = [
        {
            title: 'NAME',
            dataIndex: 'ADR_NAME',
            key: 'ADR_NAME',
            width: '20%',
        },
        {
            title: 'ADDRESS',
            dataIndex: 'ADDRESS',
            key: 'ADDRESS',
            width: '60%',
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            key: 'actions',
            width: '20%',
            render: (_, row) => (
                <Select
                    size="small"
                    className="action-select"
                    dropdownMatchSelectWidth={false}
                    placement="bottomRight"
                    onChange={(value) => handleActionChange(value, row)}
                    value="Action"
                    defaultValue="Action">
                    <Select.Option value="edit-address">Edit</Select.Option>
                    <Select.Option value="delete-address">Delete</Select.Option>
                </Select>
            ),

        },
    ];

    useEffect(() => {
        setPaginationMetaData({
            ...paginationMetaData,
            current: 1,
            pageSize: 20,
            total: customerSites.length,
        });
    }, [customerSites]);

    useEffect(() => {
        dispatch(getCustomerSitesRequest());
    }, []);

    return (
        <Main title="Site Addresses">
            <Button
                type="primary"
                style={{ marginBottom: '30px' }}
                onClick={() => history.push('/account/addresses/create')}>
                Add New
            </Button>
            <ScorchTable
                columns={columns}
                pagination={paginationMetaData}
                onSortChange={(pagination) => setPaginationMetaData(pagination)}
                dataSource={sortedCustomerSites}
                loading={isFetchingCustomerSites} />
        </Main>
    );
};

export default SiteAddresses;

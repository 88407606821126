import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_UNPAID_INVOICES_REQUEST: null,
    GET_UNPAID_INVOICES_SUCCESS: null,
    GET_UNPAID_INVOICES_FAILURE: null,

    GET_OVERDUE_BALANCE_REQUEST: null,
    GET_OVERDUE_BALANCE_SUCCESS: null,
    GET_OVERDUE_BALANCE_FAILURE: null,

    SEND_PAYMENT_EMAIL_REQUEST: null,
    SEND_PAYMENT_EMAIL_SUCCESS: null,
    SEND_PAYMENT_EMAIL_FAILURE: null,
});

export const {
    getUnpaidInvoicesRequest,
    getUnpaidInvoicesSuccess,
    getUnpaidInvoicesFailure,
} = createActions(
    constants.GET_UNPAID_INVOICES_REQUEST,
    constants.GET_UNPAID_INVOICES_SUCCESS,
    constants.GET_UNPAID_INVOICES_FAILURE,
);

export const {
    getOverdueBalanceRequest,
    getOverdueBalanceSuccess,
    getOverdueBalanceFailure,
} = createActions(
    constants.GET_OVERDUE_BALANCE_REQUEST,
    constants.GET_OVERDUE_BALANCE_SUCCESS,
    constants.GET_OVERDUE_BALANCE_FAILURE,
);

export const {
    sendPaymentEmailRequest,
    sendPaymentEmailSuccess,
    sendPaymentEmailFailure,
} = createActions(
    constants.SEND_PAYMENT_EMAIL_REQUEST,
    constants.SEND_PAYMENT_EMAIL_SUCCESS,
    constants.SEND_PAYMENT_EMAIL_FAILURE,
);

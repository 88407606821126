import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_ORDER_REQUESTS_REQUEST: null,
    GET_ORDER_REQUESTS_SUCCESS: null,
    GET_ORDER_REQUESTS_FAILURE: null,

    GET_ORDER_REQUEST_ITEMS_REQUEST: null,
    GET_ORDER_REQUEST_ITEMS_SUCCESS: null,
    GET_ORDER_REQUEST_ITEMS_FAILURE: null,

    GET_ORDER_REQUEST_DETAILS_REQUEST: null,
    GET_ORDER_REQUEST_DETAILS_SUCCESS: null,
    GET_ORDER_REQUEST_DETAILS_FAILURE: null,

    CHANGE_ORDER_REQUEST_STATUS_REQUEST: null,
    CHANGE_ORDER_REQUEST_STATUS_SUCCESS: null,
    CHANGE_ORDER_REQUEST_STATUS_FAILURE: null,

    GET_ORDER_REQUEST_COUNT_REQUEST: null,
    GET_ORDER_REQUEST_COUNT_SUCCESS: null,
    GET_ORDER_REQUEST_COUNT_FAILURE: null,
});

export const {
    getOrderRequestsRequest,
    getOrderRequestsSuccess,
    getOrderRequestsFailure,
} = createActions(
    constants.GET_ORDER_REQUESTS_REQUEST,
    constants.GET_ORDER_REQUESTS_SUCCESS,
    constants.GET_ORDER_REQUESTS_FAILURE,
);

export const {
    getOrderRequestItemsRequest,
    getOrderRequestItemsSuccess,
    getOrderRequestItemsFailure,
} = createActions(
    constants.GET_ORDER_REQUEST_ITEMS_REQUEST,
    constants.GET_ORDER_REQUEST_ITEMS_SUCCESS,
    constants.GET_ORDER_REQUEST_ITEMS_FAILURE,
);

export const {
    getOrderRequestDetailsRequest,
    getOrderRequestDetailsSuccess,
    getOrderRequestDetailsFailure,
} = createActions(
    constants.GET_ORDER_REQUEST_DETAILS_REQUEST,
    constants.GET_ORDER_REQUEST_DETAILS_SUCCESS,
    constants.GET_ORDER_REQUEST_DETAILS_FAILURE,
);

export const {
    changeOrderRequestStatusRequest,
    changeOrderRequestStatusSuccess,
    changeOrderRequestStatusFailure,
} = createActions(
    constants.CHANGE_ORDER_REQUEST_STATUS_REQUEST,
    constants.CHANGE_ORDER_REQUEST_STATUS_SUCCESS,
    constants.CHANGE_ORDER_REQUEST_STATUS_FAILURE,
);

export const {
    getOrderRequestCountRequest,
    getOrderRequestCountSuccess,
    getOrderRequestCountFailure,
} = createActions(
    constants.GET_ORDER_REQUEST_COUNT_REQUEST,
    constants.GET_ORDER_REQUEST_COUNT_SUCCESS,
    constants.GET_ORDER_REQUEST_COUNT_FAILURE,
);

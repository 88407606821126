import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ScorchTable from 'scorch-react-table';
import { Link } from 'react-router-dom';
import './style.css';

const HireItems = ({ siteId }) => {

    const { hireItems, isFetchingHireItems } = useSelector(state => state.order);
    const movePage = (record) => {
        // eslint-disable-next-line no-undef
        window.location.href = `/product/${record.slug}/`;
        return true;
    };

    const columns = [
        {
            title: 'PRODUCT.',
            dataIndex: 'title',
            key: 'title',
            render: (val, record) => {
                if (record.slug === null) {
                    return <span>{val}</span>;
                }
                return <Link to={`/product/${record.slug}/`} onClick={() => movePage(record)}>{val}</Link>;
            },
        },
        {
            title: 'FLEET NO',
            dataIndex: 'fleetNo',
            key: 'fleetNo',
        },
        {
            title: 'CERTS & INSTRUCTIONS',
            dataIndex: 'certsAnldInstructions',
            key: 'certsAndInstructions',
            render: () => (
                <Link to={`/account/certificates-instructions?activeSiteId=${siteId}`}>
                    <button
                        type="button"
                        className="certs-link">
                        View now
                    </button>
                </Link>
            ),
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'OFF HIRED',
            dataIndex: 'offHired',
            key: 'offHired',
        },
    ];

    return (
        <div className="hire-items-table-container">
            <ScorchTable
                columns={columns}
                dataSource={hireItems}
                loading={isFetchingHireItems} />
        </div>
    );
};

HireItems.propTypes = {
    siteId: PropTypes.string.isRequired,
};

export default HireItems;

/* eslint-disable no-undef */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    getCustomerContactsSuccess,
    getCustomerContactsFailure,

    createCustomerContactSuccess,
    createCustomerContactFailure,

    updateCustomerContactSuccess,
    updateCustomerContactFailure,

    deleteCustomerContactSuccess,
    deleteCustomerContactFailure,

    getContactJobTitlesSuccess,
    getContactJobTitlesFailure,

    getCustomerSitesSuccess,
    getCustomerSitesFailure,

    getCustomerSiteSuccess,
    getCustomerSiteFailure,

    createCustomerSiteSuccess,
    createCustomerSiteFailure,

    updateCustomerSiteSuccess,
    updateCustomerSiteFailure,

    deleteCustomerSiteSuccess,
    deleteCustomerSiteFailure,

    getActiveSiteLocationsSuccess,
    getActiveSiteLocationsFailure,

    getActiveLocationDetailsSuccess,
    getActiveLocationDetailsFailure,

    getAccountBalanceSuccess,
    getAccountBalanceFailure,

    getEquipmentOnHireSuccess,
    getEquipmentOnHireFailure,

    downloadInstructionsSuccess,
    downloadInstructionsFailure,

    downloadServiceCertificateSuccess,
    downloadServiceCertificateFailure,

    downloadLolerCertificateSuccess,
    downloadLolerCertificateFailure,

    downloadBatchInstructionsSuccess,
    downloadBatchInstructionsFailure,

    downloadBatchServiceCertificatesSuccess,
    downloadBatchServiceCertificatesFailure,

    downloadBatchLolerSuccess,
    downloadBatchLolerFailure,

    crosshireDocumentsSuccess,
    crosshireDocumentsFailure,

    getCustomerContactsRequest,
    getCustomerSitesRequest,
    getCustomerSiteRequest,
} from './accountActions';

const {
    GET_CUSTOMER_CONTACTS_REQUEST,
    CREATE_CUSTOMER_CONTACT_REQUEST,
    UPDATE_CUSTOMER_CONTACT_REQUEST,
    DELETE_CUSTOMER_CONTACT_REQUEST,
    GET_CONTACT_JOB_TITLES_REQUEST,
    GET_CUSTOMER_SITES_REQUEST,
    GET_CUSTOMER_SITE_REQUEST,
    CREATE_CUSTOMER_SITE_REQUEST,
    UPDATE_CUSTOMER_SITE_REQUEST,
    DELETE_CUSTOMER_SITE_REQUEST,
    GET_ACTIVE_SITE_LOCATIONS_REQUEST,
    GET_ACTIVE_LOCATION_DETAILS_REQUEST,
    GET_ACCOUNT_BALANCE_REQUEST,
    GET_EQUIPMENT_ON_HIRE_REQUEST,
    DOWNLOAD_INSTRUCTIONS_REQUEST,
    DOWNLOAD_SERVICE_CERTIFICATE_REQUEST,
    DOWNLOAD_LOLER_CERTIFICATE_REQUEST,
    DOWNLOAD_BATCH_INSTRUCTIONS_REQUEST,
    DOWNLOAD_BATCH_SERVICE_CERTIFICATES_REQUEST,
    DOWNLOAD_BATCH_LOLER_REQUEST,
    CROSSHIRE_DOCUMENTS_REQUEST,
} = require('./accountActions').constants;

function* getAccountBalance() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/account-balance`,
        });

        yield put(getAccountBalanceSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getAccountBalanceFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerContacts() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/contacts`,
        });

        yield put(getCustomerContactsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getCustomerContactsFailure(e.response ? e.response.data.message : e));
    }
}

function* createCustomerContact({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/create-contact`,
            body: payload.data,
        });

        yield put(createCustomerContactSuccess(response.data));
        yield put(getCustomerContactsRequest());

        // 20241015 YUANKUI TRY TO FIX TH-37
        //          Pass created contact to onActionComplete() for display
        if (typeof payload.onActionComplete === 'function') {
            payload.onActionComplete(response.data);
        }

        message.success('Contact created successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(createCustomerContactFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCustomerContact({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/update-contact`,
            body: payload,
        });

        yield put(updateCustomerContactSuccess(response.data));
        yield put(getCustomerContactsRequest());
        message.success('Contact updated successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateCustomerContactFailure(e.response ? e.response.data.message : e));
    }
}

function* deleteCustomerContact({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/delete-contact`,
            body: payload,
        });

        yield put(deleteCustomerContactSuccess());
        yield put(getCustomerContactsRequest());
        message.success('Contact deleted successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(deleteCustomerContactFailure(e.response ? e.response.data.message : e));
    }
}

function* getContactJobTitles() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/contact-job-titles`,
        });

        yield put(getContactJobTitlesSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getContactJobTitlesFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerSites() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/sites`,
        });

        yield put(getCustomerSitesSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getCustomerSitesFailure(e.response ? e.response.data.message : e));
    }
}

function* getCustomerSite({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/site?siteId=${payload}`,
        });

        yield put(getCustomerSiteSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getCustomerSiteFailure(e.response ? e.response.data.message : e));
    }
}

function* createCustomerSite({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/create-site`,
            body: payload.data,
        });

        yield put(createCustomerSiteSuccess(response.data));
        yield put(getCustomerSitesRequest());

        if (typeof payload.onActionComplete === 'function') {
            // 20241013 YUANKUI TRY TO FIX TH-37
            //          Pass created site to onActionComplete() for display
            payload.onActionComplete(response.data);
        }

        message.success('Site created successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(createCustomerSiteFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCustomerSite({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/update-site`,
            body: payload,
        });

        yield put(getCustomerSiteRequest(payload.id));
        yield put(updateCustomerSiteSuccess());
        message.success('Site updated successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateCustomerSiteFailure(e.response ? e.response.data.message : e));
    }
}

function* deleteCustomerSite({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/delete-site`,
            body: payload,
        });

        yield put(deleteCustomerSiteSuccess());
        yield put(getCustomerSitesRequest());
        message.success('Site deleted successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(deleteCustomerSiteFailure(e.response ? e.response.data.message : e));
    }
}

function* getActiveSiteLocations() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/active-site-locations`,
        });

        yield put(getActiveSiteLocationsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getActiveSiteLocationsFailure(e.response ? e.response.data.message : e));
    }
}

function* getActiveLocationDetails({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/active-location-details?locationId=${payload}`,
        });

        yield put(getActiveLocationDetailsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getActiveLocationDetailsFailure(e.response ? e.response.data.message : e));
    }
}

function* getEquipmentOnHire() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/equipment-on-hire`,
        });

        yield put(getEquipmentOnHireSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getEquipmentOnHireFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadInstructions({ payload }) {
    const customerId = getCustomerIdFromCookie();
    const encodedPayload = encodeURIComponent(payload);
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/download-instructions?item=${encodedPayload}`,
            responseType: 'blob',
        });

        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        yield put(downloadInstructionsSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(downloadInstructionsFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadServiceCertificate({ payload }) {
    const customerId = getCustomerIdFromCookie();
    const encodedPayload = encodeURIComponent(payload);
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/download-service-certificate?item=${encodedPayload}`,
            responseType: 'blob',
        });

        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        yield put(downloadServiceCertificateSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(downloadServiceCertificateFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadLolerCertificate({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/download-loler-certificate?stockNo=${payload}`,
            responseType: 'blob',
        });

        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        window.open(fileURL);

        yield put(downloadLolerCertificateSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(downloadLolerCertificateFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadBatchInstructions({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/download-batch-instructions`,
            body: { items: payload },
            responseType: 'blob',
        });

        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', 'instructions.zip');
        document.body.appendChild(link);
        link.click();

        yield put(downloadBatchInstructionsSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(downloadBatchInstructionsFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadBatchServiceCertificates({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/download-batch-service-certificates`,
            body: { items: payload },
            responseType: 'blob',
        });

        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);

        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', 'service-certificates.zip');
        document.body.appendChild(link);
        link.click();

        yield put(downloadBatchServiceCertificatesSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(downloadBatchServiceCertificatesFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadBatchLoler({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `company/${customerId}/download-loler-batch?siteId=${payload}`,
            responseType: 'blob',
        });

        const anchor = document.createElement('a');
        document.body.appendChild(anchor);

        const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
        anchor.href = objectUrl;
        anchor.target = '_self';

        anchor.download = `${payload}-LOLERS.zip`;

        anchor.click();

        window.URL.revokeObjectURL(objectUrl);

        yield put(downloadBatchLolerSuccess());
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(downloadBatchLolerFailure(e.response ? e.response.data.message : e));
    }
}

function* requestCrossHireDocument({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `company/${customerId}/crosshire-document`,
            body: payload,
        });

        yield put(crosshireDocumentsSuccess(response.data));
        message.success(`Successfully requested ${payload.type}`);
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(crosshireDocumentsFailure(e.response ? e.response.data.message : e));
    }
}

export default function* accountSaga() {
    yield all([
        takeEvery(GET_ACCOUNT_BALANCE_REQUEST, getAccountBalance),
        takeEvery(GET_CUSTOMER_CONTACTS_REQUEST, getCustomerContacts),
        takeEvery(CREATE_CUSTOMER_CONTACT_REQUEST, createCustomerContact),
        takeEvery(UPDATE_CUSTOMER_CONTACT_REQUEST, updateCustomerContact),
        takeEvery(DELETE_CUSTOMER_CONTACT_REQUEST, deleteCustomerContact),
        takeEvery(GET_CONTACT_JOB_TITLES_REQUEST, getContactJobTitles),
        takeEvery(GET_CUSTOMER_SITES_REQUEST, getCustomerSites),
        takeEvery(GET_CUSTOMER_SITE_REQUEST, getCustomerSite),
        takeEvery(CREATE_CUSTOMER_SITE_REQUEST, createCustomerSite),
        takeEvery(UPDATE_CUSTOMER_SITE_REQUEST, updateCustomerSite),
        takeEvery(DELETE_CUSTOMER_SITE_REQUEST, deleteCustomerSite),
        takeEvery(GET_ACTIVE_SITE_LOCATIONS_REQUEST, getActiveSiteLocations),
        takeEvery(GET_ACTIVE_LOCATION_DETAILS_REQUEST, getActiveLocationDetails),
        takeEvery(GET_EQUIPMENT_ON_HIRE_REQUEST, getEquipmentOnHire),
        takeEvery(DOWNLOAD_INSTRUCTIONS_REQUEST, downloadInstructions),
        takeEvery(DOWNLOAD_SERVICE_CERTIFICATE_REQUEST, downloadServiceCertificate),
        takeEvery(DOWNLOAD_LOLER_CERTIFICATE_REQUEST, downloadLolerCertificate),
        takeEvery(DOWNLOAD_BATCH_INSTRUCTIONS_REQUEST, downloadBatchInstructions),
        takeEvery(DOWNLOAD_BATCH_SERVICE_CERTIFICATES_REQUEST, downloadBatchServiceCertificates),
        takeEvery(DOWNLOAD_BATCH_LOLER_REQUEST, downloadBatchLoler),
        takeEvery(CROSSHIRE_DOCUMENTS_REQUEST, requestCrossHireDocument),
    ]);
}

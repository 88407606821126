import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Donut = ({ before, value, after, label, labelKey }) => (
    <div className="item my-1">
        <div className="inner">
            <div className="circle">
                <svg viewBox="0 0 38 38" className="img-fluid">
                    <circle className="circle-bg" cx="19" cy="19" r="16" fill="none" strokeWidth="2" />
                    <circle transform="rotate(-90 19 19)"
                        className="circle-highlight"
                        fill="none"
                        stroke="#C40E0E"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeDasharray={`${value}, 100`}
                        cx="19"
                        cy="19"
                        r="16" />
                </svg>
            </div>

            <div className="text">
                <span className="display-2">
                    <sub>{before}</sub>
                    {value}
                    <sup style={{ fontSize: '30%' }}>{after}</sup>
                    {/* <sup style="{{ $stat['after'] == 'SECS' ? 'font-size: 30%' : '' }}"></sup> */}
                </span>
                <label className="px--1">{label}<sup>{labelKey}</sup></label>
            </div>
        </div>
    </div>
);

Donut.propTypes = {
    before: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    after: PropTypes.string,
    label: PropTypes.string,
    labelKey: PropTypes.number,
};

Donut.defaultProps = {
    before: '',
    value: '',
    after: '',
    label: '',
    labelKey: '',
};

export default Donut;

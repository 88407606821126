import { combineReducers } from 'redux';
import { message } from 'antd';
import global from './global/globalReducer';
import user from './user/userReducer';
import account from './account/accountReducer';
import orderRequest from './orderRequest/orderRequestReducer';
import order from './order/orderReducer';
import setting from './setting/settingReducer';
import invoice from './invoice/invoiceReducer';
import invoiceQuery from './invoiceQuery/invoiceQueryReducer';
import kpi from './kpi/kpiReducer';
import product from './product/productReducer';
import payments from './payments/paymentsReducer';
import transport from './transport/transportReducer';

/**
 * ## CombineReducers
 *
 * the rootReducer will call each and every reducer with the state and action
 * EVERY TIME there is a basic action
 */
const appReducers = combineReducers({
    // ... your other reducers here ...
    global,
    setting,
    user,
    account,
    orderRequest,
    order,
    invoice,
    invoiceQuery,
    kpi,
    product,
    payments,
    transport,
});

const rootReducer = (state, action) => {
    // ... your other reducers here ...
    if ((action.type).indexOf('_FAILURE') !== -1 && action.type !== 'DOWNLOAD_ON_HIRE_REPORT_FAILURE') {
        console.log('##### ACTIOn', action);
        message.error(action.payload);
    }

    return appReducers(state, action);
};

export default rootReducer;

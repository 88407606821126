import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Select, Tooltip } from 'antd';
import { MinusOutlined, QuestionOutlined, SearchOutlined } from '@ant-design/icons';
import ScorchTable from 'scorch-react-table';
import Main from '../../components/layout/Main';
import {
    getOrderRequestsRequest,
    getOrderRequestItemsRequest,
} from '../../core/orderRequest/orderRequestActions';
import OrderActions from '../../components/OrderActions';
import ExpandedRow from '../OrderRequest/ExpandedRow';
import './style.scss';

const OrderRequests = () => {

    const dispatch = useDispatch();

    const { orderRequests, isFetchingOrderRequests } = useSelector(state => state.orderRequest);

    // Action modal states
    const [selectedRecord, setSelectedRecord] = useState(undefined);
    const [selectedAction, setSelectedAction] = useState('Action');

    const fetchOrderItems = (data) => {
        dispatch(getOrderRequestItemsRequest(data.webId));
    };

    const selectAction = (action, record) => {
        setSelectedAction(action);
        setSelectedRecord(record);
    };

    const columns = [
        {
            title: 'WEB ID',
            dataIndex: 'webId',
            key: 'webId',
            align: 'center',
            width: '10%',
        },
        {
            title: 'SITE ADDRESS',
            dataIndex: 'siteAddress',
            key: 'siteAddress',
            width: '20%',
            render: (val, record) => (
                <Tooltip title={val}>
                    {record.siteLabel}
                </Tooltip>
            ),
        },
        {
            title: 'PO NUMBER',
            dataIndex: 'poNumber',
            key: 'poNumber',
            align: 'center',
            width: '15%',
        },
        {
            title: 'ON-HIRE',
            dataIndex: 'onHireDate',
            key: 'onHireDate',
            align: 'center',
            width: '15%',
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '15%',
            render: (val) => (
                <Tooltip title={val}>
                    <QuestionOutlined style={{ color: 'red' }} />
                </Tooltip>
            ),
        },
        {
            title: 'ACTIONS',
            key: 'actions',
            width: '20%',
            render: (_, record) => (
                <div className="action-select-container">
                    <Select value="Action" onChange={((val) => selectAction(val, record))} defaultValue="Action">
                        <Select.Option value="approve-request">Approve</Select.Option>
                        <Select.Option value="decline-request">Decline</Select.Option>
                        <Select.Option value="edit-request">Edit</Select.Option>
                    </Select>
                </div>
            ),
        },
    ];

    const renderExpandedRow = useCallback((record) => (
        <ExpandedRow record={record} selectAction={selectAction} />
    ), []);

    useEffect(() => {
        dispatch(getOrderRequestsRequest());
    }, []);

    return (
        <Main title="Order Requests">
            <Row>
                <Col span={24}>
                    <ScorchTable
                        columns={columns}
                        dataSource={orderRequests && orderRequests.data}
                        loading={isFetchingOrderRequests}
                        expandableRows
                        onExpandRow={(record) => fetchOrderItems(record)}
                        expandedRowRender={renderExpandedRow}
                        expandedRowIdentifier="webId"
                        rowExpandIcon={<SearchOutlined style={{ color: 'red' }} />}
                        rowCloseIcon={<MinusOutlined style={{ color: 'red' }} />} />

                </Col>
            </Row>
            <OrderActions
                setSelectedAction={setSelectedAction}
                selectedAction={selectedAction}
                setSelectedRecord={setSelectedRecord}
                selectedRecord={selectedRecord} />
        </Main>
    );
};

export default OrderRequests;

import React from 'react';
import ScorchMap from 'scorch-react-map-lite';
import PropTypes from 'prop-types';

const {
    GOOGLE_MAPS_KEY,
} = require('../../core/constants').default;

const Map = ({ center, markers, width, height, zoom }) => {
    const mapConfig = {
        defaultCenter: center || {
            lat: 52.48818704527863,
            lng: -1.9118514539629998,
        },
        defaultZoom: zoom || 12,
        key: GOOGLE_MAPS_KEY,
        options: {
            style: null,
        },
        width: width || '700px',
        height: height || '400px',
    };

    return <ScorchMap mapConfig={mapConfig} markers={markers} />;
};

Map.propTypes = {
    center: PropTypes.object.isRequired,
    markers: PropTypes.array,
    width: PropTypes.string,
    height: PropTypes.string,
    zoom: PropTypes.number,
};

Map.defaultProps = {
    markers: [],
    width: '700px',
    height: '400px',
    zoom: 12,
};

export default Map;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Radio, Row } from 'antd';
import HireItems from '../../../components/HireItems';
import OffHireReminder from '../../../components/OffHireReminder';

const ExpandedRow = ({ selectedAction, record, settings, setSelectedAction, setSelectedRecord }) => (
    <Row>
        <Col>
            <Row>
                <HireItems siteId={record?.siteId} />
            </Row>
            <Row>
                <div className="action-button-group">
                    <Radio.Group value={selectedAction} onChange={(e) => { setSelectedAction(e.target.value); setSelectedRecord(record); }}>
                        <Link to={`/account/hire-contracts/${record.contractNo}`}>
                            <Radio.Button>View full details</Radio.Button>
                        </Link>
                        {/* <Radio.Button size="small" value="order-again">Order Again</Radio.Button> */}
                        {record.status === 'On Hire' && (
                            <>
                                {settings.change_po_number_enabled && <Radio.Button value="change-po-number">Change PO Number</Radio.Button>}
                                <Radio.Button
                                    data-order-id={record.id}
                                    data-web-id={record.contractNo}
                                    className={`off-hire-reminder-action-${record.id}`}
                                    value="off-hire-reminder">
                                    Set Off-Hire Reminder
                                </Radio.Button>
                                <Radio.Button value="request-exchange">Request Exchange</Radio.Button>
                                {record.webId && <Radio.Button value="off-hire">Off-Hire</Radio.Button>}
                            </>
                        )}

                    </Radio.Group>
                </div>
                <OffHireReminder actionId={`.off-hire-reminder-action-${record.id}`} />
            </Row>
        </Col>
    </Row>
);

ExpandedRow.propTypes = {
    selectedAction: PropTypes.string.isRequired,
    record: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    setSelectedAction: PropTypes.func.isRequired,
    setSelectedRecord: PropTypes.func.isRequired,
};

export default ExpandedRow;

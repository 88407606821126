import { Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getInvoiceQueryRequest, postCommentRequest } from '../../core/invoiceQuery/invoiceQueryActions';

import './style.scss';

const InvoiceQuery = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { invoiceQueryNo } = useParams();
    const { invoiceQuery, invoiceQueryComments, isFetchingInvoiceQuery } = useSelector(state => state.invoiceQuery);

    const submitComment = () => {
        const comment = form.getFieldValue('comment');
        const data = {
            invoiceQueryId: invoiceQueryNo,
            comment,
        };

        dispatch(postCommentRequest(data));
        form.resetFields();
    };

    useEffect(() => {
        dispatch(getInvoiceQueryRequest(invoiceQueryNo));
    }, []);
    return !isFetchingInvoiceQuery ? (
        <div className="invoice-query-container">
            <h5>Status: {invoiceQuery?.status}</h5>
            <Row>
                <Col span={12}>
                    <p>Invoice: <strong>{invoiceQuery?.syrinx_invoice_id}</strong></p>
                    <p>Reason: <strong>{invoiceQuery?.reason}</strong></p>
                    <p>Last Updated: <strong>{invoiceQuery?.updated}</strong></p>
                </Col>
                <Col span={12}>
                    <p>Reference: <strong>{invoiceQuery?.reference}</strong></p>
                    <p>Opened by: <strong>{invoiceQuery?.created_by}</strong></p>
                    <p>Date opened: <strong>{invoiceQuery?.created}</strong></p>
                </Col>
            </Row>
            <p>Notes:</p>
            <div className="note-container">
                <p>{invoiceQuery?.notes}</p>
            </div>
            <h4>Comments</h4>
            <div className="comments-container">
                {invoiceQueryComments.map(comment => (
                    <div className="comment-container">
                        <div className="comment-content-container">
                            {comment.content}
                        </div>
                        <abbr>added on {comment.created} by {comment.author}</abbr>
                    </div>
                ))}
            </div>

            <div className="add-comment-container">
                <Row style={{ padding: '4%' }}>
                    <Col span={24}>
                        <Form form={form} layout="vertical" onFinish={submitComment}>
                            <Form.Item name="comment" label={<div style={{ marginBottom: '10px' }}>Add a comment</div>}>
                                <Input.TextArea placeholder="Add a comment.." style={{ backgroundColor: '#eeeef0', minHeight: '100px' }} />
                            </Form.Item>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    ) : <Spin />;
};

export default InvoiceQuery;

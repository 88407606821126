import { Record } from 'immutable';

const {
    RAISE_INVOICE_QUERY_REQUEST,
    RAISE_INVOICE_QUERY_SUCCESS,
    RAISE_INVOICE_QUERY_FAILURE,

    GET_INVOICE_QUERIES_REQUEST,
    GET_INVOICE_QUERIES_SUCCESS,
    GET_INVOICE_QUERIES_FAILURE,

    GET_INVOICE_QUERY_REQUEST,
    GET_INVOICE_QUERY_SUCCESS,
    GET_INVOICE_QUERY_FAILURE,

    POST_COMMENT_REQUEST,
    POST_COMMENT_SUCCESS,
    POST_COMMENT_FAILURE,
} = require('./invoiceQueryActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    invoiceQueries: {},
    isFetchingInvoiceQueries: false,
    invoiceQuery: {},
    isFetchingInvoiceQuery: false,
    invoiceQueryComments: [],
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function orderReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case RAISE_INVOICE_QUERY_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_INVOICE_QUERIES_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingInvoiceQueries', true)
            .set('error', null);

    case GET_INVOICE_QUERY_REQUEST:
        return state.set('isFetching', true)
            .set('isFetchingInvoiceQuery', true)
            .set('error', null);

    case POST_COMMENT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case RAISE_INVOICE_QUERY_SUCCESS:
        return state.set('isFetching', false);

    case GET_INVOICE_QUERIES_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingInvoiceQueries', false)
            .set('invoiceQueries', payload);

    case GET_INVOICE_QUERY_SUCCESS:
        return state.set('isFetching', false)
            .set('isFetchingInvoiceQuery', false)
            .set('invoiceQuery', payload)
            .set('invoiceQueryComments', payload.comments);

    case POST_COMMENT_SUCCESS:
        return state.set('isFetching', false)
            .set('invoiceQueryComments', payload);

    case RAISE_INVOICE_QUERY_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_INVOICE_QUERIES_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingInvoiceQueries', false)
            .set('error', payload);

    case GET_INVOICE_QUERY_FAILURE:
        return state.set('isFetching', false)
            .set('isFetchingInvoiceQueries', false)
            .set('error', payload);

    case POST_COMMENT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);
    default:
        return state;

    }
}

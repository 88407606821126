import React from 'react';
import 'tiny-slider/dist/tiny-slider.css';
import './testPage.scss';
import OffHireReminder from '../../components/OffHireReminder';

const TestPage = () => (
    <div>
        <div className="off-hire-reminder-action" data-orderId="1234">Off Hire Reminder</div>
        <OffHireReminder />
    </div>
);

export default TestPage;

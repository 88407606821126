import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHelmetSafety, faPhoneFlip } from '@fortawesome/free-solid-svg-icons';

import './style.css';
import TransportSection from './TransportSection';

const OrderDetails = ({
    info,
    customerContact,
    siteAddress,
    orderItemsComponent,
    transport,
}) => {
    const renderInfoItems = (items) => items.map((i) => (
        i.val && <Col><p><span className="info-key">{i.key}:</span> <span className="info-value">{i.val}</span></p></Col>
    ));

    return (
        <div className="hire-contract-container">
            <Row justify="space-between">
                {renderInfoItems([
                    { key: 'CONTRACT NO', val: info.contractNo },
                    { key: 'PO NO', val: info.poNumber },
                    { key: 'ON-HIRE-DATE', val: info.onHireDate },
                    { key: 'OFF-HIRE-DATE', val: info.offHireDate },
                    { key: 'STATUS', val: info.status },
                ])}
            </Row>
            <Row justify="space-around">
                <Col lg={11}>
                    <Card
                        className="card"
                        title={(
                            <Row justify="space-between" align="middle">
                                <Col className="card-heading">CONTACT INFORMATION</Col>
                                <Col><FontAwesomeIcon icon={faPhoneFlip} style={{ fontSize: '25px', color: '#da0812' }} /></Col>
                            </Row>
                        )}
                        bordered={false}>
                        <p><strong>Name:</strong> {customerContact?.name}</p>
                        <p><strong>Email:</strong> {customerContact?.email}</p>
                        <p><strong>Phone:</strong> {customerContact?.phone}</p>
                        <p><strong>Company:</strong> {customerContact?.company}</p>
                    </Card>
                </Col>
                <Col lg={11}>
                    <Card
                        className="card"
                        title={(
                            <Row justify="space-between" align="middle">
                                <Col className="card-heading">SITE ADDRESS</Col>
                                <Col><FontAwesomeIcon icon={faHelmetSafety} style={{ fontSize: '25px', color: '#da0812' }} /></Col>
                            </Row>
                        )}
                        bordered={false}>
                        <p>{siteAddress?.line1}</p>
                        <p>{siteAddress?.line2}</p>
                        <p>{siteAddress?.line3}</p>
                        <p>{siteAddress?.town}</p>
                        <p>{siteAddress?.county}</p>
                        <p>{siteAddress?.postcode}</p>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="items-header">
                        <h5>Hire Items</h5>
                    </div>
                    {orderItemsComponent}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="items-header">
                        <Row justify="space-between">
                            <Col><h5>Transport</h5></Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <TransportSection
                title="Deliveries"
                items={transport ? transport.deliveries : []}
                headers={[
                    { text: 'Driver', colSpan: 4 },
                    { text: 'Driver Name', colSpan: 3 },
                    { text: 'Delivery Time', colSpan: 4 },
                    { text: 'Signature', colSpan: 5 },
                    { text: 'Proof of Delivery', colSpan: 5 },
                    { text: 'Status', colSpan: 3 },
                ]} />
            <TransportSection
                title="Collections"
                items={transport ? transport.collections : []}
                headers={[
                    { text: 'Driver', colSpan: 4 },
                    { text: 'Driver Name', colSpan: 3 },
                    { text: 'Delivery Time', colSpan: 4 },
                    { text: 'Signature', colSpan: 5 },
                    { text: 'Proof of Collection', colSpan: 5 },
                    { text: 'Status', colSpan: 3 },
                ]} />
        </div>
    );
};

OrderDetails.propTypes = {
    info: PropTypes.object.isRequired,
    customerContact: PropTypes.object.isRequired,
    siteAddress: PropTypes.object.isRequired,
    orderItemsComponent: PropTypes.node.isRequired,
    transport: PropTypes.object.isRequired,
};

export default OrderDetails;

import React from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ContactForm from '../../../ContactForm';

const AddContactModal = ({ showModal, setShowModal, onActionComplete }) => (
    <Modal
        title="Add a new contact"
        okText="Add contact"
        footer={null}
        onCancel={() => setShowModal(false)}
        open={showModal}>
        <ContactForm record={null}
            onSubmit={() => {
                setShowModal(false);
            }}
            goBack={() => {
                setShowModal(false);
            }}
            // 20241015 YUANKUI TRY TO FIX TH-37
            //          Expose onActionComplete to select the latest created contact
            onActionComplete={(val) => {
                onActionComplete(val);
            }} />
    </Modal>
);

AddContactModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    // 20241015 YUANKUI TRY TO FIX TH-37
    //          Expose onActionComplete to select the latest created contact
    onActionComplete: PropTypes.func.isRequired,
};

export default AddContactModal;

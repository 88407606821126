import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    RAISE_INVOICE_QUERY_REQUEST: null,
    RAISE_INVOICE_QUERY_SUCCESS: null,
    RAISE_INVOICE_QUERY_FAILURE: null,

    GET_INVOICE_QUERIES_REQUEST: null,
    GET_INVOICE_QUERIES_SUCCESS: null,
    GET_INVOICE_QUERIES_FAILURE: null,

    GET_INVOICE_QUERY_REQUEST: null,
    GET_INVOICE_QUERY_SUCCESS: null,
    GET_INVOICE_QUERY_FAILURE: null,

    POST_COMMENT_REQUEST: null,
    POST_COMMENT_SUCCESS: null,
    POST_COMMENT_FAILURE: null,
});

export const {
    raiseInvoiceQueryRequest,
    raiseInvoiceQuerySuccess,
    raiseInvoiceQueryFailure,
} = createActions(
    constants.RAISE_INVOICE_QUERY_REQUEST,
    constants.RAISE_INVOICE_QUERY_SUCCESS,
    constants.RAISE_INVOICE_QUERY_FAILURE,
);

export const {
    getInvoiceQueriesRequest,
    getInvoiceQueriesSuccess,
    getInvoiceQueriesFailure,
} = createActions(
    constants.GET_INVOICE_QUERIES_REQUEST,
    constants.GET_INVOICE_QUERIES_SUCCESS,
    constants.GET_INVOICE_QUERIES_FAILURE,
);

export const {
    getInvoiceQueryRequest,
    getInvoiceQuerySuccess,
    getInvoiceQueryFailure,
} = createActions(
    constants.GET_INVOICE_QUERY_REQUEST,
    constants.GET_INVOICE_QUERY_SUCCESS,
    constants.GET_INVOICE_QUERY_FAILURE,
);

export const {
    postCommentRequest,
    postCommentSuccess,
    postCommentFailure,
} = createActions(
    constants.POST_COMMENT_REQUEST,
    constants.POST_COMMENT_SUCCESS,
    constants.POST_COMMENT_FAILURE,
);

/* eslint-disable no-undef */

export const jQueryEditOrderModalTrigger = (orderId) => {
    jQuery('body').append(`<button id="temp-link" data-toggle="modal" data-id="${orderId}" data-target="#editOrderRequestModal">L</button>`);
    const element = jQuery('#temp-link');
    element.trigger('click');
    element.remove();
};

export const jQueryOffhireModalTrigger = (orderId, contractNo) => {
    jQuery('body').append(`<button id="temp-link" data-toggle="modal" data-id="${orderId}" data-contract_number=${contractNo} data-target="#offHireModal">L</button>`);
    const element = jQuery('#temp-link');
    element.trigger('click');
    element.remove();
};

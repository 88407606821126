import { Record } from 'immutable';

const {
    GET_ORDER_REQUESTS_REQUEST,
    GET_ORDER_REQUESTS_SUCCESS,
    GET_ORDER_REQUESTS_FAILURE,

    GET_ORDER_REQUEST_ITEMS_REQUEST,
    GET_ORDER_REQUEST_ITEMS_SUCCESS,
    GET_ORDER_REQUEST_ITEMS_FAILURE,

    GET_ORDER_REQUEST_DETAILS_REQUEST,
    GET_ORDER_REQUEST_DETAILS_SUCCESS,
    GET_ORDER_REQUEST_DETAILS_FAILURE,

    CHANGE_ORDER_REQUEST_STATUS_REQUEST,
    CHANGE_ORDER_REQUEST_STATUS_SUCCESS,
    CHANGE_ORDER_REQUEST_STATUS_FAILURE,

    GET_ORDER_REQUEST_COUNT_REQUEST,
    GET_ORDER_REQUEST_COUNT_SUCCESS,
    GET_ORDER_REQUEST_COUNT_FAILURE,
} = require('./orderRequestActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    orderRequests: {},
    isFetchingOrderRequests: false,
    orderRequestItems: [],
    isFetchingOrderRequestItems: false,
    orderRequest: [],
    isFetchingOrderRequestDetails: false,
    orderRequestCount: 0,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function orderRequestReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case GET_ORDER_REQUESTS_REQUEST:
        return state.set('isFetching', true).set('isFetchingOrderRequests', true)
            .set('error', null);

    case GET_ORDER_REQUEST_ITEMS_REQUEST:
        return state.set('isFetching', true).set('isFetchingOrderRequestItems', true)
            .set('error', null);

    case GET_ORDER_REQUEST_DETAILS_REQUEST:
        return state.set('isFetching', true).set('isFetchingOrderRequestDetails', true)
            .set('error', null);

    case CHANGE_ORDER_REQUEST_STATUS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_ORDER_REQUEST_COUNT_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_ORDER_REQUESTS_SUCCESS:
        return state.set('isFetching', false).set('isFetchingOrderRequests', false)
            .set('orderRequests', payload);

    case GET_ORDER_REQUEST_ITEMS_SUCCESS:
        return state.set('isFetching', false).set('isFetchingOrderRequestItems', false)
            .set('orderRequestItems', payload);

    case GET_ORDER_REQUEST_DETAILS_SUCCESS:
        return state.set('isFetching', false).set('isFetchingOrderRequestDetails', false)
            .set('orderRequest', payload)
            .set('orderRequestItems', payload.items);

    case CHANGE_ORDER_REQUEST_STATUS_SUCCESS:
        return state.set('isFetching', false);

    case GET_ORDER_REQUEST_COUNT_SUCCESS:
        return state.set('isFetching', false)
            .set('orderRequestCount', payload);

    case GET_ORDER_REQUESTS_FAILURE:
        return state.set('isFetching', false).set('isFetchingOrderRequests', false)
            .set('error', payload);

    case GET_ORDER_REQUEST_ITEMS_FAILURE:
        return state.set('isFetching', false).set('isFetchingOrderRequestItems', false)
            .set('error', payload);

    case GET_ORDER_REQUEST_DETAILS_FAILURE:
        return state.set('isFetching', false).set('isFetchingOrderRequestDetails', false)
            .set('error', payload);

    case CHANGE_ORDER_REQUEST_STATUS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    case GET_ORDER_REQUEST_COUNT_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}

import { Record } from 'immutable';

const {
    GET_UNPAID_INVOICES_REQUEST,
    GET_UNPAID_INVOICES_SUCCESS,
    GET_UNPAID_INVOICES_FAILURE,

    GET_OVERDUE_BALANCE_REQUEST,
    GET_OVERDUE_BALANCE_SUCCESS,
    GET_OVERDUE_BALANCE_FAILURE,

    SEND_PAYMENT_EMAIL_REQUEST,
    SEND_PAYMENT_EMAIL_SUCCESS,
    SEND_PAYMENT_EMAIL_FAILURE,
} = require('./paymentsActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    unpaidInvoices: [],
    overdueBalance: 0,
    paymentRequestRunning: false,
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function paymentsReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case GET_UNPAID_INVOICES_REQUEST:
    case GET_OVERDUE_BALANCE_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);
    case SEND_PAYMENT_EMAIL_REQUEST:
        return state.set('paymentRequestRunning', true)
            .set('error', null);

    case GET_UNPAID_INVOICES_SUCCESS:
        return state.set('isFetching', false)
            .set('unpaidInvoices', payload);

    case GET_OVERDUE_BALANCE_SUCCESS:
        return state.set('isFetching', false)
            .set('overdueBalance', payload);

    case SEND_PAYMENT_EMAIL_SUCCESS:
        return state.set('paymentRequestRunning', false);
    case GET_UNPAID_INVOICES_FAILURE:
    case GET_OVERDUE_BALANCE_FAILURE:
    case SEND_PAYMENT_EMAIL_FAILURE:
        return state.set('isFetching', false)
            .set('paymentRequestRunning', false)
            .set('error', payload);

    default:
        return state;

    }
}

/* eslint-disable no-undef */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    raiseInvoiceQuerySuccess,
    raiseInvoiceQueryFailure,

    getInvoiceQueriesSuccess,
    getInvoiceQueriesFailure,

    getInvoiceQuerySuccess,
    getInvoiceQueryFailure,

    postCommentSuccess,
    postCommentFailure,
} from './invoiceQueryActions';

const {
    RAISE_INVOICE_QUERY_REQUEST,
    GET_INVOICE_QUERIES_REQUEST,
    GET_INVOICE_QUERY_REQUEST,
    POST_COMMENT_REQUEST,
} = require('./invoiceQueryActions').constants;

function* raiseInvoiceQuery({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'invoice-query',
            body: { ...payload, customerId },
        });

        yield put(raiseInvoiceQuerySuccess(response));
        message.success('Invoice query raised successfully');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(raiseInvoiceQueryFailure(e.response ? e.response.data.message : e));
    }
}

function* getInvoiceQueries({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'invoice-query',
            params: { ...payload, customerId },
        });

        yield put(getInvoiceQueriesSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getInvoiceQueriesFailure(e.response ? e.response.data.message : e));
    }
}

function* getInvoiceQuery({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `invoice-query/${payload}`,
            params: { customerId },
        });

        yield put(getInvoiceQuerySuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getInvoiceQueryFailure(e.response ? e.response.data.message : e));
    }
}

function* postComment({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `invoice-query/${payload.invoiceQueryId}/comment`,
            body: {
                comment: payload.comment,
                customerId,
            },
        });

        yield put(postCommentSuccess(response.data));
        message.success('Successfully posted comment');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(postCommentFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* invoiceSaga() {
    yield all([
        takeEvery(RAISE_INVOICE_QUERY_REQUEST, raiseInvoiceQuery),
        takeEvery(GET_INVOICE_QUERIES_REQUEST, getInvoiceQueries),
        takeEvery(GET_INVOICE_QUERY_REQUEST, getInvoiceQuery),
        takeEvery(POST_COMMENT_REQUEST, postComment),
    ]);
}

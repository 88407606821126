/* eslint-disable no-undef */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    getUnpaidInvoicesSuccess,
    getUnpaidInvoicesFailure,

    getOverdueBalanceSuccess,
    getOverdueBalanceFailure,

    sendPaymentEmailSuccess,
    sendPaymentEmailFailure,
} from './paymentsActions';

const {
    GET_UNPAID_INVOICES_REQUEST,
    GET_OVERDUE_BALANCE_REQUEST,
    SEND_PAYMENT_EMAIL_REQUEST,
} = require('./paymentsActions').constants;

function* getUnpaidInvoices() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/payments/${customerId}/unpaid-invoices`,
        });

        yield put(getUnpaidInvoicesSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getUnpaidInvoicesFailure(e.response ? e.response.data.message : e));
    }
}

function* getOverdueBalance() {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/payments/${customerId}/overdue-balance`,
        });

        yield put(getOverdueBalanceSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getOverdueBalanceFailure(e.response ? e.response.data.message : e));
    }
}

function* sendPaymentEmails({ payload }) {
    const customerId = getCustomerIdFromCookie();
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/payments/send-emails',
            body: {
                customerId,
                paymentOption: payload.paymentOption,
                specificAmount: payload.specificAmount,
                selectedInvoices: payload.selectedInvoices,
            },
        });
        yield put(sendPaymentEmailSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(sendPaymentEmailFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* paymentsSaga() {
    yield all([
        takeEvery(GET_UNPAID_INVOICES_REQUEST, getUnpaidInvoices),
        takeEvery(GET_OVERDUE_BALANCE_REQUEST, getOverdueBalance),
        takeEvery(SEND_PAYMENT_EMAIL_REQUEST, sendPaymentEmails),
    ]);
}

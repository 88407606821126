import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import lockr from 'lockr';
import { fetchApi, fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';
import history from '../utils/history';

import {

    registerSuccess,
    registerFailure,

    loginSuccess,
    loginFailure,

    logoutSuccess,
    logoutFailure,

    forgotPasswordSuccess,
    forgotPasswordFailure,

    resetPasswordSuccess,
    resetPasswordFailure,

    getHireDurationPreferencesSuccess,
    getHireDurationPreferencesFailure,

    updateHireDurationPreferencesSuccess,
    updateHireDurationPreferencesFailure,

    getPreferencesNotificationsSuccess,
    getPreferencesNotificationsFailure,

    updatePreferencesNotificationsSuccess,
    updatePreferencesNotificationsFailure,

    getCompanyPreferencesNotificationsSuccess,
    getCompanyPreferencesNotificationsFailure,

    updateCompanyPreferencesNotificationsSuccess,
    updateCompanyPreferencesNotificationsFailure,
} from './userActions';

const {
    AUTH_KEY,
    LOCALSTORAGE_EXPIRES_KEY,
} = require('../constants').default;

const {
    REGISTER_REQUEST,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    GET_HIRE_DURATION_PREFERENCES_REQUEST,
    UPDATE_HIRE_DURATION_PREFERENCES_REQUEST,
    GET_PREFERENCES_NOTIFICATIONS_REQUEST,
    UPDATE_PREFERENCES_NOTIFICATIONS_REQUEST,
    GET_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST,
    UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST,
} = require('./userActions').constants;

function saveSessionToken(action) {
    const authDate = new Date();
    lockr.set(LOCALSTORAGE_EXPIRES_KEY, authDate);
    lockr.set(AUTH_KEY, action.data.token);
}

function* register(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/register',
            body: { email: action.payload.data.email,
                password: action.payload.data.password,
                first_name: action.payload.data.name,
                last_name: 'Singh',
            },
        });
        history.push('/');
        yield put(registerSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(registerFailure(e.response ? e.response.data.message : e));
    }
}

function* makeLoginRequest(action) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'login',
            body: { email: action.payload.email, password: action.payload.password },
        });

        if (response.data.token) {
            yield call(saveSessionToken, response);
            yield put(loginSuccess(response.data));
            history.push('/dashboard');
        } else {
            yield put(loginFailure('login request failed'));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(loginFailure(e.response ? e.response.data.errors.email : e));
    }
}

function* logoutRequest() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/logout',
            body: {},
        });
        lockr.rm(AUTH_KEY);
        lockr.rm(LOCALSTORAGE_EXPIRES_KEY);
        localStorage.clear();
        yield put(logoutSuccess(response));
        history.push('/');
    } catch (e) {
        yield put(logoutFailure(e.response ? e.response.data.message : e));
    }
}

function* forgotPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: '/forgot-password',
            body: { email: payload.email },
        });

        yield put(forgotPasswordSuccess(response));
        message.success('A password reset email has been requested, if an account exists you will receive an email shortly.');
    } catch (e) {
        yield put(forgotPasswordFailure(e.response ? e.response.data.errors.email[0] : e));
    }
}

function* resetPassword({ payload }) {
    try {
        const response = yield call(fetchApi, {
            method: 'POST',
            url: 'reset-password',
            body: {
                email: payload.email,
                newPassword: payload.newPassword,
                confirmPassword: payload.confirmPassword,
                token: payload.token,
            },
        });

        yield put(resetPasswordSuccess(response));
        message.success('Password updated successfully');
        history.push('/');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(resetPasswordFailure(e.response ? e.response.data.message : e));
    }
}

function* getHireDurationPreferences() {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'user/hire-duration-preferences',
        });

        yield put(getHireDurationPreferencesSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getHireDurationPreferencesFailure(e.response ? e.response.data.message : e));
    }
}

function* updateHireDurationPreferences({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user/hire-duration-preferences',
            body: payload,
        });

        yield put(updateHireDurationPreferencesSuccess(response.data));
        message.success('Successfully updated hire duration preferences for account');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateHireDurationPreferencesFailure(e.response ? e.response.data.message : e));
    }
}

function* getNotificationsPreferences({ payload }) {
    try {
        const customerId = getCustomerIdFromCookie();
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'user/notifications-preferences',
            params: { customerId, email: payload?.email },
        });

        yield put(getPreferencesNotificationsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getPreferencesNotificationsFailure(e.response ? e.response.data.message : e));
    }
}

function* updatetNotificationsPreferences({ payload }) {
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'user/update-notifications-preferences',
            body: payload,
        });

        yield put(updatePreferencesNotificationsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updatePreferencesNotificationsFailure(e.response ? e.response.data.message : e));
    }
}

function* getCompanyNotificationsPreferences({ payload }) {
    try {
        const customerId = getCustomerIdFromCookie();
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'company/notifications-preferences',
            params: { customerId, email: payload?.email },
        });

        yield put(getCompanyPreferencesNotificationsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getCompanyPreferencesNotificationsFailure(e.response ? e.response.data.message : e));
    }
}

function* updateCompanyNotificationsPreferences({ payload }) {
    try {
        const customerId = getCustomerIdFromCookie();
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'company/notifications-preferences',
            body: { customerId, ...payload },
        });

        yield put(updateCompanyPreferencesNotificationsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateCompanyPreferencesNotificationsFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* userSaga() {
    yield all([
        takeEvery(REGISTER_REQUEST, register),
        takeEvery(LOGIN_REQUEST, makeLoginRequest),
        takeEvery(LOGOUT_REQUEST, logoutRequest),
        takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassword),
        takeEvery(RESET_PASSWORD_REQUEST, resetPassword),
        takeEvery(GET_HIRE_DURATION_PREFERENCES_REQUEST, getHireDurationPreferences),
        takeEvery(UPDATE_HIRE_DURATION_PREFERENCES_REQUEST, updateHireDurationPreferences),
        takeEvery(GET_PREFERENCES_NOTIFICATIONS_REQUEST, getNotificationsPreferences),
        takeEvery(UPDATE_PREFERENCES_NOTIFICATIONS_REQUEST, updatetNotificationsPreferences),
        takeEvery(GET_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST, getCompanyNotificationsPreferences),
        takeEvery(UPDATE_COMPANY_PREFERENCES_NOTIFICATIONS_REQUEST, updateCompanyNotificationsPreferences),
    ]);
}

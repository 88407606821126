import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_HIRE_RATES_REQUEST: null,
    GET_HIRE_RATES_SUCCESS: null,
    GET_HIRE_RATES_FAILURE: null,

});

export const {
    getHireRatesRequest,
    getHireRatesSuccess,
    getHireRatesFailure,
} = createActions(
    constants.GET_HIRE_RATES_REQUEST,
    constants.GET_HIRE_RATES_SUCCESS,
    constants.GET_HIRE_RATES_FAILURE,
);

/* eslint-disable no-undef */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { saveAs } from 'file-saver';

import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    getHireRatesSuccess,
    getHireRatesFailure,
} from './productActions';

const {
    GET_HIRE_RATES_REQUEST,
} = require('./productActions').constants;

function* getHireRates() {
    try {
        const customerId = getCustomerIdFromCookie();
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `product/${customerId}/hire-rates`,
            responseType: 'blob',
        });

        const ext = 'csv';
        const fileSaverOptions = { type: 'text/csv' };
        const filename = `hire-rates.${ext}`;
        const blob = new Blob([response.data], fileSaverOptions);

        saveAs(blob, filename);

        yield put(getHireRatesSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getHireRatesFailure(e.response ? e.response.data.message : e));
    }
}

export default function* productSaga() {
    yield all([
        takeEvery(GET_HIRE_RATES_REQUEST, getHireRates),
    ]);
}

import React, { useState } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { getAuthHeaders } from '../../core/utils/api';
import noDriverImage from '../../assets/images/driver-blank.png';

const TransportImage = ({ url, className, type = null }) => {
    const imageRef = React.useRef();
    const [isLoading, setIsLoading] = useState(true);
    React.useEffect(() => {
        if (url) {
            fetch(url, { headers: getAuthHeaders() })
                .then(res => {
                    if (res.ok) {
                        return res.blob();
                    }
                    throw new Error('Something went wrong');
                })
                .then(blob => {
                    setIsLoading(false);
                    const objectURL = URL.createObjectURL(blob);
                    imageRef.current.src = objectURL;
                }).catch(() => {
                    setIsLoading(false);
                    if (type === 'driver') {
                        imageRef.current.src = noDriverImage;
                    } else {
                        imageRef.current.src = null;
                    }
                });
        } else {
            setIsLoading(false);
        }

    }, [url]);

    if (url) {
        return (
            <>
                <img style={{ display: isLoading ? 'none' : 'inline' }} ref={imageRef} className={className && className} alt="img" />
                {isLoading && <Spin />}
            </>
        );
    }
    return '';
};

TransportImage.propTypes = {
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
};

TransportImage.defaultProps = {
    type: undefined,
    className: undefined,
};

export default TransportImage;

import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_HIRE_CONTRACTS_REQUEST: null,
    GET_HIRE_CONTRACTS_SUCCESS: null,
    GET_HIRE_CONTRACTS_FAILURE: null,

    GET_HIRE_ITEMS_REQUEST: null,
    GET_HIRE_ITEMS_SUCCESS: null,
    GET_HIRE_ITEMS_FAILURE: null,

    GET_HIRE_CONTRACT_REQUEST: null,
    GET_HIRE_CONTRACT_SUCCESS: null,
    GET_HIRE_CONTRACT_FAILURE: null,

    ORDER_AGAIN_REQUEST: null,
    ORDER_AGAIN_SUCCESS: null,
    ORDER_AGAIN_FAILURE: null,

    UPDATE_ORDER_REQUEST: null,
    UPDATE_ORDER_SUCCESS: null,
    UPDATE_ORDER_FAILURE: null,

    SET_OFFHIRE_REMINDER_REQUEST: null,
    SET_OFFHIRE_REMINDER_SUCCESS: null,
    SET_OFFHIRE_REMINDER_FAILURE: null,

    SEND_EXCHANGE_REQUEST_REQUEST: null,
    SEND_EXCHANGE_REQUEST_SUCCESS: null,
    SEND_EXCHANGE_REQUEST_FAILURE: null,

    GET_ITEMS_FOR_OFFHIRE_REQUEST: null,
    GET_ITEMS_FOR_OFFHIRE_SUCCESS: null,
    GET_ITEMS_FOR_OFFHIRE_FAILURE: null,

    GET_EXCHANGABLE_ITEMS_REQUEST: null,
    GET_EXCHANGABLE_ITEMS_SUCCESS: null,
    GET_EXCHANGABLE_ITEMS_FAILURE: null,

    CREATE_OFFHIRE_REQUEST: null,
    CREATE_OFFHIRE_SUCCESS: null,
    CREATE_OFFHIRE_FAILURE: null,

    DOWNLOAD_ON_HIRE_REPORT_REQUEST: null,
    DOWNLOAD_ON_HIRE_REPORT_SUCCESS: null,
    DOWNLOAD_ON_HIRE_REPORT_FAILURE: null,

    SET_SHOW_ORDER_ACTIONS_MODAL: null,
    SET_ORDER_ACTION_PENDING: null,

});

export const {
    getHireContractsRequest,
    getHireContractsSuccess,
    getHireContractsFailure,
} = createActions(
    constants.GET_HIRE_CONTRACTS_REQUEST,
    constants.GET_HIRE_CONTRACTS_SUCCESS,
    constants.GET_HIRE_CONTRACTS_FAILURE,
);

export const {
    getHireItemsRequest,
    getHireItemsSuccess,
    getHireItemsFailure,
} = createActions(
    constants.GET_HIRE_ITEMS_REQUEST,
    constants.GET_HIRE_ITEMS_SUCCESS,
    constants.GET_HIRE_ITEMS_FAILURE,
);

export const {
    getHireContractRequest,
    getHireContractSuccess,
    getHireContractFailure,
} = createActions(
    constants.GET_HIRE_CONTRACT_REQUEST,
    constants.GET_HIRE_CONTRACT_SUCCESS,
    constants.GET_HIRE_CONTRACT_FAILURE,
);

export const {
    orderAgainRequest,
    orderAgainSuccess,
    orderAgainFailure,
} = createActions(
    constants.ORDER_AGAIN_REQUEST,
    constants.ORDER_AGAIN_SUCCESS,
    constants.ORDER_AGAIN_FAILURE,
);

export const {
    updateOrderRequest,
    updateOrderSuccess,
    updateOrderFailure,
} = createActions(
    constants.UPDATE_ORDER_REQUEST,
    constants.UPDATE_ORDER_SUCCESS,
    constants.UPDATE_ORDER_FAILURE,
);

export const {
    setOffhireReminderRequest,
    setOffhireReminderSuccess,
    setOffhireReminderFailure,
} = createActions(
    constants.SET_OFFHIRE_REMINDER_REQUEST,
    constants.SET_OFFHIRE_REMINDER_SUCCESS,
    constants.SET_OFFHIRE_REMINDER_FAILURE,
);

export const {
    sendExchangeRequestRequest,
    sendExchangeRequestSuccess,
    sendExchangeRequestFailure,
} = createActions(
    constants.SEND_EXCHANGE_REQUEST_REQUEST,
    constants.SEND_EXCHANGE_REQUEST_SUCCESS,
    constants.SEND_EXCHANGE_REQUEST_FAILURE,
);

export const {
    getItemsForOffhireRequest,
    getItemsForOffhireSuccess,
    getItemsForOffhireFailure,
} = createActions(
    constants.GET_ITEMS_FOR_OFFHIRE_REQUEST,
    constants.GET_ITEMS_FOR_OFFHIRE_SUCCESS,
    constants.GET_ITEMS_FOR_OFFHIRE_FAILURE,
);

export const {
    getExchangableItemsRequest,
    getExchangableItemsSuccess,
    getExchangableItemsFailure,
} = createActions(
    constants.GET_EXCHANGABLE_ITEMS_REQUEST,
    constants.GET_EXCHANGABLE_ITEMS_SUCCESS,
    constants.GET_EXCHANGABLE_ITEMS_FAILURE,
);

export const {
    createOffhireRequest,
    createOffhireSuccess,
    createOffhireFailure,
} = createActions(
    constants.CREATE_OFFHIRE_REQUEST,
    constants.CREATE_OFFHIRE_SUCCESS,
    constants.CREATE_OFFHIRE_FAILURE,
);

export const {
    downloadOnHireReportRequest,
    downloadOnHireReportSuccess,
    downloadOnHireReportFailure,
} = createActions(
    constants.DOWNLOAD_ON_HIRE_REPORT_REQUEST,
    constants.DOWNLOAD_ON_HIRE_REPORT_SUCCESS,
    constants.DOWNLOAD_ON_HIRE_REPORT_FAILURE,
);

export const {
    setShowOrderActionsModal,
} = createActions(
    constants.SET_SHOW_ORDER_ACTIONS_MODAL,
);

export const {
    setOrderActionPending,
} = createActions(
    constants.SET_ORDER_ACTION_PENDING,
);

import { PlayCircleFilled } from '@ant-design/icons';
import { Button, Col, InputNumber, Modal, Row, Slider, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import { updateHireDurationPreferencesRequest, getHireDurationPreferencesRequest } from '../../core/user/userActions';
import './style.scss';

const HireDurationPreferences = () => {

    const [showModal, setShowModal] = useState(false);

    const [goodPref, setGoodPref] = useState(undefined);
    const [okPref, setOkPref] = useState(undefined);
    const [badPref, setBadPref] = useState(undefined);

    const dispatch = useDispatch();

    const { hireDurationPreferences, isFetchingHireDurationPreferences } = useSelector(state => state.user);

    const submitHireDurations = () => {
        dispatch(updateHireDurationPreferencesRequest({ good: goodPref, okay: okPref }));
        setShowModal(false);
    };

    const changeGood = (val) => {
        setGoodPref(val);
        if (!okPref || okPref <= val) {
            setOkPref(val + 1);
            setBadPref(val + 2);
        }
    };

    const changeOk = (val) => {
        if (val <= goodPref) {
            setOkPref(goodPref + 1);
            setBadPref(goodPref + 2);
        } else {
            setOkPref(val);
            setBadPref(val + 1);
        }
    };

    useEffect(() => {
        dispatch(getHireDurationPreferencesRequest());
    }, []);

    useEffect(() => {
        if (!isEmpty(hireDurationPreferences)) {
            const hdp = hireDurationPreferences;
            setGoodPref(hdp?.good && +hdp.good || undefined);
            setOkPref(hdp?.okay && +hdp.okay || undefined);
            if (hdp?.okay) {
                setBadPref(+hdp.okay + 1);
            }
        }
    }, [hireDurationPreferences]);

    return !isFetchingHireDurationPreferences ? (
        <>
            <Col>Hire Duration Status</Col>
            <Col span={9}>
                <div className="slider-container">
                    <Slider
                        range
                        min={1}
                        max={3}
                        value={[1, 2, 3]}
                        tooltip={{
                            zIndex: 500,
                            open: true,
                            formatter: (e) => {
                                const content = '';
                                switch (e) {
                                case 1: return goodPref ? `0 - ${goodPref} months` : 'Not set';
                                case 2: return okPref ? `${goodPref} - ${okPref} months` : 'Not set';
                                case 3: return okPref ? `over ${+okPref + 1} months` : 'Not set';
                                default: break;
                                }
                                return content;
                            },
                        }}
                        trackStyle={{ display: 'none' }} />
                </div>
            </Col>
            <Col><Button onClick={() => setShowModal(true)}>Change</Button></Col>
            <Modal
                centered
                title="Set Hire Durations"
                visible={showModal}
                okText="Proceed"
                onOk={submitHireDurations}
                onCancel={() => setShowModal(false)}>
                <>
                    <p>Set the month limits for the durations of your open hires. Each contract will be colour coded with the status colour.</p>
                    <Row justify="center" align="middle" style={{ marginTop: '15px' }}>
                        <Col span={6}>
                            <PlayCircleFilled style={{ color: '#63eb84', marginRight: '7px' }} /><strong>Good</strong>
                        </Col>
                        <Col span={6}>
                            <InputNumber value={goodPref} onChange={changeGood} min={0} controls />
                        </Col>
                    </Row>
                    <Row justify="center" align="middle" style={{ marginTop: '15px' }}>
                        <Col span={6}>
                            <PlayCircleFilled style={{ color: '#ffc917', marginRight: '7px' }} /><strong>Okay</strong>
                        </Col>
                        <Col span={6}>
                            <InputNumber value={okPref} onChange={changeOk} min={0} controls />
                        </Col>
                    </Row>
                    <Row justify="center" align="middle" style={{ marginTop: '15px' }}>
                        <Col span={6}>
                            <PlayCircleFilled style={{ color: '#da0812', marginRight: '7px' }} /><strong>Bad</strong>
                        </Col>
                        <Col span={6}>
                            <InputNumber value={badPref && `${badPref}+`} disabled min={0} />
                        </Col>
                    </Row>
                </>
            </Modal>
        </>
    ) : <Spin />;

};

export default HireDurationPreferences;

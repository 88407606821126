import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faChevronUp, faDotCircle, faExclamation, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getApiUrl, getAuthHeaders } from '../../../core/utils/api';
import noDriverImage from '../../../assets/images/driver-blank.png';
import TransportImage from '../../TransportImage';
import TransportPhotosModal from '../../TransportPhotosModal';
import TransportMap from '../../TransportMap';

const TransportSection = ({ title, headers, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [imageUrls, setImageUrls] = useState([]);
    const [isFetchingPhotos, setIsFetchingPhotos] = useState(false);

    const getStatusIcon = (record) => {
        let icon = faDotCircle;
        let backgroundColor = '#63eb84';
        let tooltipText = '';
        switch (record.status) {
        case 'C':
            // Completed
            icon = faCheck;
            tooltipText = 'Completed';
            break;
        case 'F':
            // Failed
            if (record.jobStatusId && record.jobStatusId === '412') {
                icon = faExclamation;
                tooltipText = 'Incomplete';
            } else {
                icon = faTimes;
                tooltipText = 'Failed';
            }
            // red
            backgroundColor = '#da0812';
            break;
        case 'O':
            // Pending/Out for delivery
            icon = faDotCircle;
            // yellow
            backgroundColor = '#ffc917';
            tooltipText = 'Pending/Out for Delivery';
            break;
        default:
            break;
        }

        return (
            <Tooltip title={tooltipText}>
                <div className="transport-icon-wrapper" style={{ backgroundColor, marginLeft: 'auto', marginRight: 'auto' }}>
                    <FontAwesomeIcon icon={icon} style={{ fontSize: '25px', color: 'white', textAlign: 'center' }} />
                </div>
            </Tooltip>
        );
    };

    const getPhotos = (photos) => {
        setIsFetchingPhotos(true);
        let newUrls = [...imageUrls];
        let photosLoaded = 0;
        photos.forEach((photo) => {
            let apiUrl;
            if (photo.fileName) {
                apiUrl = `${getApiUrl()}/transport/image?imageName=${photo.fileName}`;
            } else {
                apiUrl = `${getApiUrl()}/transport/raw-image?imageId=${photo.imageId}`;
            }

            fetch(apiUrl, { headers: getAuthHeaders() })
                .then(res => res.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    newUrls = [...newUrls, url];
                    setImageUrls(newUrls);
                    photosLoaded++;
                    if (photosLoaded === photos.length) {
                        setIsFetchingPhotos(false);
                    }
                });

        });
    };

    const getImgSrc = (imageData) => {
        if (imageData && (imageData.fileName || imageData.imageId)) {
            if (imageData.fileName) {
                return `${getApiUrl()}/order/transport-image?imageName=${imageData.fileName}`;
            }

            return `${getApiUrl()}/order/raw-transport-image?imageId=${imageData.imageId}`;
        }
        return null;
    };

    return (
        <div className="transport-section">
            <Row>
                <Col span={24}>
                    <div className="items-header">
                        <Row onClick={() => setIsOpen(!isOpen)} justify="space-between">
                            <Col>
                                <h4>{title} ({items.length}):</h4>
                            </Col>
                            <Col>
                                {!isOpen
                                    ? <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '25px', color: '#da0812' }} />
                                    : <FontAwesomeIcon icon={faChevronUp} style={{ fontSize: '25px', color: '#da0812' }} /> }
                            </Col>
                        </Row>
                    </div>
                    {isOpen && (
                        <>

                            <Row>
                                {headers.map((header) => <Col span={header.colSpan}><h5>{header.text}</h5></Col>)}
                            </Row>
                            {items.map(item => (
                                <div className="transport-data-row">
                                    <Row justify="space-between" style={{ alignItems: 'center' }}>
                                        <Col span={4}><img src={item.driverImgSrc || noDriverImage} className="primary-transport-image" alt="img" /></Col>
                                        <Col span={3}>{item.driverName}</Col>
                                        <Col span={4}>{item.completionDate}</Col>
                                        <Col span={5}>{item.signature && <TransportImage url={getImgSrc(item.signature)} className="primary-transport-image" />} {item.signedBy}</Col>
                                        <Col
                                            onClick={() => {
                                                getPhotos(item.photos);
                                                setShowModal(true);
                                            }}
                                            span={5}>
                                            <TransportImage
                                                url={getImgSrc(item.photos && item.photos.length > 0 && item.photos[0])}
                                                className="primary-transport-image" />
                                        </Col>
                                        <Col span={3}>{getStatusIcon(item)}</Col>
                                    </Row>
                                    {item.notes && (
                                        <Row>
                                            <Col span={24}>
                                                <div className="driver-notes">
                                                    <Row>
                                                        <Col><h5>Driver Notes</h5></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{item.notes}</Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}

                                    {item.lastCarLocation && (
                                        <Row>
                                            <TransportMap driverCoordinates={item.lastCarLocation} />
                                        </Row>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </Col>
                <TransportPhotosModal
                    open={showModal}
                    setOpen={setShowModal}
                    setImageUrls={setImageUrls}
                    imageUrls={imageUrls}
                    isFetchingPhotos={isFetchingPhotos} />
            </Row>
        </div>
    );
};

TransportSection.propTypes = {
    title: PropTypes.string.isRequired,
    headers: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
};

export default TransportSection;

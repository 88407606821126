import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_SETTINGS_REQUEST: null,
    GET_SETTINGS_SUCCESS: null,
    GET_SETTINGS_FAILURE: null,
});

export const {
    getSettingsRequest,
    getSettingsSuccess,
    getSettingsFailure,
} = createActions(
    constants.GET_SETTINGS_REQUEST,
    constants.GET_SETTINGS_SUCCESS,
    constants.GET_SETTINGS_FAILURE,
);

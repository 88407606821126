import { Record } from 'immutable';

const {
    GET_HOMEPAGE_WIDGET_REQUEST,
    GET_HOMEPAGE_WIDGET_SUCCESS,
    GET_HOMEPAGE_WIDGET_FAILURE,

    GET_ACCOUNT_PAGE_WIDGET_REQUEST,
    GET_ACCOUNT_PAGE_WIDGET_SUCCESS,
    GET_ACCOUNT_PAGE_WIDGET_FAILURE,

} = require('./kpiActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    homePageWidget: {},
    accountPageWidget: {},
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function kpiReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case GET_HOMEPAGE_WIDGET_REQUEST:
    case GET_ACCOUNT_PAGE_WIDGET_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_HOMEPAGE_WIDGET_SUCCESS:
        return state.set('isFetching', false)
            .set('homePageWidget', payload);

    case GET_ACCOUNT_PAGE_WIDGET_SUCCESS:
        return state.set('isFetching', false)
            .set('accountPageWidget', payload);

    case GET_HOMEPAGE_WIDGET_FAILURE:
    case GET_ACCOUNT_PAGE_WIDGET_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}

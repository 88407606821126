import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    getHomepageWidgetSuccess,
    getHomepageWidgetFailure,

    getAccountPageWidgetSuccess,
    getAccountPageWidgetFailure,
} from './kpiActions';

const {
    GET_HOMEPAGE_WIDGET_REQUEST,
    GET_ACCOUNT_PAGE_WIDGET_REQUEST,
} = require('./kpiActions').constants;

/**
 *
 * @param payload
 * @returns {Generator<*, void, *>}
 */
function* getHomePageWidgetBlock() {
    const customerId = getCustomerIdFromCookie();
    const params = {};
    if (customerId !== '') { params.customerId = customerId; }

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'widget-block/home-page-block',
            params,
        });
        yield put(getHomepageWidgetSuccess(response?.data));
    } catch (e) {
        message.error(e.response.data.message ?? e);
        yield put(getHomepageWidgetFailure(e.response ? e.response?.data?.message : e));
    }
}

/**
 *
 * @param payload
 * @returns {Generator<*, void, *>}
 */
function* getAccountPageWidgetBlock() {
    const customerId = getCustomerIdFromCookie();
    const params = {};
    if (customerId !== '') { params.customerId = customerId; }

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'widget-block/account-page-block',
            params,
        });

        yield put(getAccountPageWidgetSuccess(response?.data));
    } catch (e) {
        message.error(e.response.data.message ?? e);
        yield put(getAccountPageWidgetFailure(e.response ? e.response?.data?.message : e));
    }
}

/**
 * Watch actions
 */
export default function* kpiSaga() {
    yield all([
        takeEvery(GET_HOMEPAGE_WIDGET_REQUEST, getHomePageWidgetBlock),
        takeEvery(GET_ACCOUNT_PAGE_WIDGET_REQUEST, getAccountPageWidgetBlock),
    ]);
}

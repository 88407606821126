import React from 'react';
import { useParams } from 'react-router-dom';
import Main from '../../components/layout/Main';
import UpdateSite from '../../components/UpdateSite';
import history from '../../core/utils/history';

const SiteAddress = () => {
    const { id } = useParams();
    return (
        <Main title={`${id ? 'Update' : 'Add'} Site`}>
            <UpdateSite id={id} onActionComplete={(createdId) => history.push(`/account/addresses/${createdId}/update`)} />
        </Main>
    );
};

export default SiteAddress;

import React, { useEffect } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    getAccountBalanceRequest,
} from '../../core/account/accountActions';
import { getUserRoleAndPermissionsFromCookie } from '../../core/utils/api';
import AccountPageWidgetBlock from '../AccountPageWidgetBlock';
import MapComponent from '../../components/MapComponent';
import Main from '../../components/layout/Main';
import './style.scss';

const Account = () => {
    const dispatch = useDispatch();
    const {
        accountBalance,
    } = useSelector(state => state.account);

    const getUserContext = getUserRoleAndPermissionsFromCookie();

    useEffect(() => {
        if (getUserContext?.role.toString() === '0') {
            dispatch(getAccountBalanceRequest());
        }
    }, []);

    return (
        <Main
            title="HIRE PERFORMANCE KPIS"
            headerRightColumn={(
                <div style={{ textAlign: 'right', color: '#000000' }}>
                    {getUserContext?.role.toString() === '0'
                        ? <h3 className="black-text">Your account balance is £{accountBalance}</h3>
                        : null}
                </div>
            )}>
            <div className="account-container">
                <AccountPageWidgetBlock />
                <h3 className="block-header">Hire Contracts Map</h3>
                <MapComponent />
            </div>
        </Main>
    );
};

export default Account;

import React, { useEffect, useState } from 'react';
import { Row, Col, Switch, Select, TimePicker, Input, Spin, Alert } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPreferencesNotificationsRequest,
    updatePreferencesNotificationsRequest,
    getCompanyPreferencesNotificationsRequest,
    updateCompanyPreferencesNotificationsRequest,
} from '../../core/user/userActions';
import { getCustomerIdFromCookie } from '../../core/utils/api';
import Main from '../../components/layout/Main';
import './style.scss';

const Notifications = () => {
    const dispatch = useDispatch();
    const {
        isFetching,
        notificationPreferences,
        notificationCompanyContacts,
        orderRequestRecipient,
        paymentReceiptEmail,
        isCompanyNotificationFetching,
    } = useSelector(state => state.user);

    const [localPaymentReceiptEmail, setLocalPaymentReceiptEmail] = useState(paymentReceiptEmail ?? null);
    const [localOrderRequestRecipient, setLocalOrderRequestRecipient] = useState(orderRequestRecipient ?? null);
    const [selectedContact, setSelectedContact] = useState(null);
    const [emailError, setEmailError] = useState(false);

    const canModify = notificationCompanyContacts.length === 0 || selectedContact !== null;

    useEffect(() => {
        dispatch(getPreferencesNotificationsRequest());
        dispatch(getCompanyPreferencesNotificationsRequest());
    }, []);

    useEffect(() => {
        setLocalOrderRequestRecipient(orderRequestRecipient);
    }, [orderRequestRecipient]);

    useEffect(() => {
        setLocalPaymentReceiptEmail(paymentReceiptEmail);
    }, [paymentReceiptEmail]);

    const updateNotificationStatus = (name, type, value) => {
        const customerId = getCustomerIdFromCookie();
        console.log({ name, type, value, selectedContact });
        dispatch(updatePreferencesNotificationsRequest({ name, type, value, customerId, email: selectedContact }));
    };

    const contactOnChange = (value) => {
        setSelectedContact(value);
        dispatch(getPreferencesNotificationsRequest({ email: value }));
    };

    const accountWideNotificationChange = (value) => {
        let error = false;
        const values = {};
        values.order_recipient = orderRequestRecipient;
        values.payment_recipient = paymentReceiptEmail;
        if (typeof value === 'object') {
            setEmailError(false);
            const emailValue = value.target.value;
            if (emailValue !== '' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
                error = true;
                setEmailError(true);
            }
            setLocalPaymentReceiptEmail(emailValue);
            values.payment_recipient = emailValue;
        } else {
            setLocalOrderRequestRecipient(value);
            values.order_recipient = value;
        }
        if (!error) {
            dispatch(updateCompanyPreferencesNotificationsRequest(values));
        }
    };

    const orderConfirmation = notificationPreferences['order-confirmation']?.mail === 'yes';
    const offHireReminders = notificationPreferences['off-hire-reminders']?.mail === 'yes';
    const deliveryCollectionTimeSlots = notificationPreferences['delivery-collection-timeslots']?.mail === 'yes';
    const deliveryCollectionConfirmations = notificationPreferences['delivery-collection-confirmations']?.mail === 'yes';
    const hireReportSchedule = notificationPreferences['scheduled-hire-report']?.mail === 'yes';
    const offHireRemindersSms = notificationPreferences['off-hire-reminders']?.sms === 'yes';
    const deliveryCollectionTimeSlotsSms = notificationPreferences['delivery-collection-timeslots']?.sms === 'yes';
    const deliveryCollectionConfirmationsSms = notificationPreferences['delivery-collection-confirmations']?.sms === 'yes';
    const reportScheduleDay = notificationPreferences['scheduled-hire-report-day'];
    const reportScheduleTime = notificationPreferences['scheduled-hire-report-time'];
    console.log('******* reportScheduleTime', reportScheduleTime);
    return (
        <Main title="Notifications">
            <Spin spinning={isFetching}>
                {notificationCompanyContacts.length > 0 ? (
                    <Row style={{ paddingBottom: 20, marginBottom: 30, borderBottom: '1.5px solid #da0812' }} className="notifications-area">
                        <Col span={10}>Select a user account</Col>
                        <Col span={14}>
                            <Select
                                style={{
                                    width: 300,
                                }}
                                defaultValue={selectedContact}
                                value={selectedContact}
                                onChange={contactOnChange}
                                options={notificationCompanyContacts.map(item => ({ value: item.email, label: item.email }))} />
                        </Col>
                        {selectedContact === null
                            ? (
                                <Col span={24} style={{ marginTop: 10 }}>
                                    <Alert message="Please select an account to set notification preferences" type="info" />
                                </Col>
                            )
                            : null}
                    </Row>
                ) : null}
                <Row gutter={32} className="notifications-area">
                    <Col xs={24} sm={24} md={12}>
                        <Row>
                            <Col span={24}>
                                <div className="black-heading">EMAIL NOTIFICATIONS</div>
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Order & Off-Hire Confirmations</Col>
                            <Col span={4}><Switch
                                disabled={!canModify}
                                checked={orderConfirmation}
                                onChange={checked => updateNotificationStatus('order-confirmation', 'mail', checked)} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Off-Hire Reminders</Col>
                            <Col span={4}>
                                <Switch
                                    disabled={!canModify}
                                    checked={offHireReminders}
                                    onChange={checked => updateNotificationStatus('off-hire-reminders', 'mail', checked)} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Delivery/Collection Timeslots</Col>
                            <Col span={4}>
                                <Switch
                                    disabled={!canModify}
                                    checked={deliveryCollectionTimeSlots}
                                    onChange={checked => updateNotificationStatus('delivery-collection-timeslots', 'mail', checked)} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Delivery/Collection Confirmations</Col>
                            <Col span={4}>
                                <Switch
                                    disabled={!canModify}
                                    checked={deliveryCollectionConfirmations}
                                    onChange={checked => updateNotificationStatus('delivery-collection-confirmations', 'mail', checked)} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Scheduled Hire Reports</Col>
                            <Col span={4}>
                                <Switch
                                    disabled={!canModify}
                                    checked={hireReportSchedule}
                                    onChange={checked => updateNotificationStatus('scheduled-hire-report', 'mail', checked)} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={16} className="sub-col">Scheduled Hire Reports Day</Col>
                            <Col span={8}>
                                <Select
                                    disabled={!canModify || hireReportSchedule === false}
                                    style={{
                                        width: 120,
                                    }}
                                    defaultValue="0"
                                    value={reportScheduleDay}
                                    onChange={(value) => updateNotificationStatus('scheduled-hire-report-day', 'mail', value)}
                                    options={[
                                        { value: '1', label: 'Monday' },
                                        { value: '2', label: 'Tuesday' },
                                        { value: '3', label: 'Wednesday' },
                                        { value: '4', label: 'Thursday' },
                                        { value: '5', label: 'Friday' },
                                        { value: '6', label: 'Saturday' },
                                        { value: '0', label: 'Sunday' },
                                    ]} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={16} className="sub-col">Scheduled Hire Reports Time</Col>
                            <Col span={8}>
                                <TimePicker
                                    disabled={!canModify || hireReportSchedule === false}
                                    value={reportScheduleTime ? dayjs(reportScheduleTime, 'HH:mm') : dayjs()}
                                    onChange={(date, dateString) => {
                                        updateNotificationStatus('scheduled-hire-report-time', 'mail', dateString);
                                    }}
                                    format="HH:mm" />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Row>
                            <Col span={24}>
                                <div className="black-heading">TEXT NOTIFICATIONS</div>
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Delivery/Collection Timeslots</Col>
                            <Col span={4}>
                                <Switch
                                    disabled={!canModify}
                                    checked={deliveryCollectionTimeSlotsSms}
                                    onChange={checked => updateNotificationStatus('delivery-collection-timeslots', 'sms', checked)} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Delivery/Collection Confirmations</Col>
                            <Col span={4}>
                                <Switch
                                    disabled={!canModify}
                                    checked={deliveryCollectionConfirmationsSms}
                                    onChange={checked => updateNotificationStatus('delivery-collection-confirmations', 'sms', checked)} />
                            </Col>
                        </Row>
                        <Row className="content-row">
                            <Col span={20}>Off-Hire Reminders</Col>
                            <Col span={4}>
                                <Switch
                                    disabled={!canModify}
                                    checked={offHireRemindersSms}
                                    onChange={checked => updateNotificationStatus('off-hire-reminders', 'sms', checked)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="header">
                    <Row justify="space-between" align="middle">
                        <Col span={24}>
                            <h3 style={{ display: 'inline-block' }}>ACCOUNT WIDE SETTINGS</h3>
                        </Col>
                    </Row>
                </div>
                <Spin spinning={isCompanyNotificationFetching}>
                    <Row gutter={32} className="notifications-area">
                        <Col xs={24} sm={24} md={12}>
                            <Row className="content-row">
                                <Col span={24} style={{ marginBottom: 10 }}>
                                    Order Request Recipient
                                </Col>
                                <Col span={24}>
                                    <Select
                                        style={{
                                            width: '100%',
                                        }}
                                        defaultValue={localOrderRequestRecipient}
                                        value={localOrderRequestRecipient}
                                        onChange={debounce(accountWideNotificationChange, 300)}
                                        options={notificationCompanyContacts.map(item => ({ value: item.email, label: item.email }))} />
                                </Col>
                            </Row>

                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Row className="content-row">
                                <Col span={24} style={{ marginBottom: 10 }}>
                                    Payment Receipt Emails {console.log(emailError)}
                                </Col>
                                <Col span={24}>
                                    <Input
                                        status={emailError ? 'error' : null}
                                        defaultValue={localPaymentReceiptEmail}
                                        onChange={debounce(accountWideNotificationChange, 1000)}
                                        placeholder="Enter email address..." />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Spin>

            </Spin>
        </Main>
    );
};

export default Notifications;

import { Record } from 'immutable';

const {
    GET_SETTINGS_REQUEST,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,

} = require('./settingActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    settings: {},
});

const initialState = new InitialState();

/**
 * ## galleryReducer function
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function settingReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case GET_SETTINGS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_SETTINGS_SUCCESS:
        return state.set('isFetching', false).set('settings', payload);

    case GET_SETTINGS_FAILURE:
        return state.set('isFetching', false)
            .set('error', payload);

    default:
        return state;

    }
}
